import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { HostClassController } from '../internal/host-class.js';
import { LocalizeController } from '../internal/localize.js';
import { getScope } from '../utilities/scope.js';

export type ReactEvents<TEvents extends Record<string, Event>> = {
  [P in keyof TEvents]?: (e: TEvents[P]) => void;
};

export default class HarmonyElement extends LitElement {
  static baseName: string;
  static dependencies?: (typeof HarmonyElement)[];
  static reactEvents: Record<string, Event>;

  private _dir?: 'ltr' | 'rtl' | 'auto';
  private _lang?: string;
  protected usesArrowKeys = false;
  protected readonly localize = new LocalizeController(this);
  protected scope = getScope(this);

  constructor() {
    super();
    new HostClassController(this);
    // @ts-ignore
    this.constructor.dependencies?.forEach(component => this.scope.registerComponent(component));
  }

  /** The dir global attribute is an enumerated attribute that indicates the directionality of the element's text. */
  @property()
  get dir(): 'ltr' | 'rtl' | 'auto' {
    // getComputedStyle will always return ltr or rtl - if dir is "auto" it will return the user agent defined direction
    return this._dir && this._dir !== 'auto' ? this._dir : (getComputedStyle(this).direction as 'ltr' | 'rtl');
  }

  set dir(val: 'ltr' | 'rtl' | 'auto') {
    this._dir = val;
    this.requestUpdate('dir');
  }

  /** The lang global attribute helps define the language of an element. */
  @property()
  get lang(): string {
    return this._lang ?? (document.documentElement.lang || navigator.language || (navigator.languages || ['en'])[0]);
  }

  set lang(val: string) {
    this._lang = val;
    this.requestUpdate('lang');
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.usesArrowKeys) this.classList.add('he-uses-arrow-keys');
    if (this.scope) {
      this.setAttribute(this.scope.getBaseName(this), '');
    }
  }

  protected firstUpdated(): void {
    this.emit('he-ready', { bubbles: false, composed: false });
  }

  /**
   * Emits a custom event with more convenient defaults.
   *
   * @internal
   */
  emit(name: string, options?: CustomEventInit) {
    const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {},
      ...options,
    });

    this.dispatchEvent(event);

    return event;
  }

  /**
   * Used to identify the root node of an element (either the parent `ShadowRoot` or the `document`).
   * This also includes a fallback to the `document` if the `getRootNode` API is not defined (as it is in some test frameworks).
   */
  protected findRootNode(element: HTMLElement): Document | ShadowRoot {
    return (element?.getRootNode() || document) as Document | ShadowRoot;
  }
}
