import { property, query, queryAll, state } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { TemplateResult } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { when } from 'lit/directives/when.js';
import { repeat } from 'lit/directives/repeat.js';
import { live } from 'lit/directives/live.js';
import { keys } from '../../utilities/key-map.js';
import icon from '../icon/icon.js';
import checkbox from '../checkbox/checkbox.js';
import skeleton from '../skeleton/skeleton.js';
import dropdown from '../dropdown/dropdown.js';
import menu from '../menu/menu.js';
import menuItem from '../menu-item/menu-item.js';
import progressRing from '../progress-ring/progress-ring.js';
import radio from '../radio/radio.js';
import tooltip from '../tooltip/tooltip.js';
import HarmonyElement from '../../base-components/base.js';
import { watch } from '../../internal/watch.js';
import { animateTo, shimKeyframesHeightAuto, stopAnimations } from '../../internal/animations.js';
import componentStyles from '../../internal/styles/component.styles.js';
import table from '../../utilities/css/table.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './data-grid.styles.js';
import type { Button } from '../button/button.js';
import type { Checkbox } from '../checkbox/checkbox.js';
import type { Dropdown } from '../dropdown/dropdown.js';

export interface Column {
  /** Unique field name for this column. */
  field: string;
  /** Column heading text. */
  content: string;
  /** Controls whether or not the column is sortable. */
  sortable?: boolean;
  /** Column display options. */
  display?: {
    /** Hides column at a specified table width (in pixels). */
    hideAt?: number;
    /** Default width of the column. */
    width?: string;
    /** Minimum width of the column. */
    minWidth?: string;
    /** Maximum width of the column. */
    maxWidth?: string;
    /** Controls the maximum number of lines displayed in a cell. Additional lines will be hidden and will display an ellipsis (...) to indicate there is more content. */
    lineClamp?: number;
    /** Controls whether or not the content wraps to a new line when the columns condense. */
    noWrap?: boolean;
  };
}

export interface Row<CustomData = unknown> {
  /** Unique id for the row. */
  id: string | number;
  /** ID of the parent of this row. */
  parentId?: string | number;
  /** Cell content, keyed with the `field` prop of each Column. */
  cells: Record<string, string>;
  selected?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  /** When using lazy loading, use this to indicate this row has child rows that will be loaded later. */
  hasLazyChildren?: boolean;
  /** Custom data object attached to a row. */
  customData?: CustomData;
}

export interface BulkSelectOption {
  name: string;
  content: string;
}

export interface SortBy {
  sortBy: string;
  isAscending: boolean;
}

export interface CellData {
  field: Column['field'];
  rowId: Row['id'];
}

// used if no id is provided for a row
let uniqueRowId = 0;

/**
 *
 * The data grid is a robust way to display an information-rich collection of items. It provides powerful ways to aid a user in finding content with sorting, grouping and filtering. The data grid is a great way to handle large amounts of content.
 *
 * @tag he-data-grid
 * @since 5.0.0
 * @status stable
 * @design approved
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19261&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-checkbox
 * @dependency he-dropdown
 * @dependency he-icon
 * @dependency he-menu
 * @dependency he-menu-item
 * @dependency he-progress-ring
 * @dependency he-radio
 * @dependency he-skeleton
 *
 * @slot data-grid-controls - This will position the command bar and search controls above the data-grid.
 * @slot pagination - This will pagination control below the data-grid.
 * @slot no-records - This is a placeholder for the content displayed when no items are displayed in the data-grid.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event {SortBy} he-sort - Emitted when a sortable column header is clicked.
 * @event {Row} he-row-select-change - Emitted when selectable row is toggled.
 * @event {boolean} he-select-all-change - Emitted when "select all" checkbox is toggled.
 * @event {string} he-bulk-select - Emitted when a bulk-select option is clicked.
 * @event {Row} he-row-expand - Emitted when a row expands.
 * @event {Row} he-after-row-expand - Emitted after a row expands and all animations are complete.
 * @event {Row} he-row-collapse - Emitted when a row collapses.
 * @event {Row} he-after-row-collapse - Emitted after a row collapses and all animations are complete.
 * @event {boolean} he-expand-all-change - Emitted when a expand all toggle is clicked.
 * @event {Row} he-row-invoke - Emitted when a row is clicked or enter key is pressed on it.
 *
 * @csspart table-head - Allows for custom styles in data grid table heading.
 * @csspart table-body - Allows for custom styles in data grid table body.
 * @csspart no-records - Controls styles for empty records message container.
 */
@Component('data-grid', [icon, checkbox, skeleton, dropdown, menu, menuItem, progressRing, radio, tooltip])
export class DataGrid extends HarmonyElement {
  static styles = [table, componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent<true>('he-ready'),
    onHeSort: new CustomEvent<SortBy>('he-sort'),
    onHeRowSelectChange: new CustomEvent<Row>('he-row-select-change'),
    onHeSelectAllChange: new CustomEvent<true>('he-select-all-change'),
    onHeBulkSelect: new CustomEvent<string>('he-bulk-select'),
    onHeRowExpand: new CustomEvent<Row>('he-row-expand'),
    onHeAfterRowExpand: new CustomEvent<Row>('he-after-row-expand'),
    onHeRowCollapse: new CustomEvent<Row>('he-row-collapse'),
    onHeAfterRowCollapse: new CustomEvent<Row>('he-after-row-collapse'),
    onHeExpandAllChange: new CustomEvent<true>('he-expand-all-change'),
    onHeRowInvoke: new CustomEvent<Row>('he-row-invoke'),
  };

  private resizeObserver: ResizeObserver;

  /** Fixes the table headings to the top of the screen when table is scrolled. */
  @property({ attribute: 'fixed-heading', type: Boolean })
  public fixedHeading: boolean = false;

  /** Fixes the first column to the left of the screen when table is scrolled. */
  @property({ attribute: 'fixed-column', type: Boolean })
  public fixedColumn: boolean = false;

  /** Puts table in a loading state. */
  @property({ type: Boolean })
  public loading: boolean = false;

  /** Shows checkbox or radio control on each row and a "select all" checkbox in header if 'multiple' is selected. */
  @property()
  public select?: 'single' | 'multiple';

  /** Toggles the "select all" checkbox when `select` is set to "multiple". */
  @property({ attribute: 'select-all', type: Boolean, reflect: true })
  public selectAll: boolean = false;

  /** Set the "select all" checkbox to indeterminate state. */
  @property({ attribute: 'select-all-indeterminate', type: Boolean, reflect: true })
  public selectAllIndeterminate: boolean = false;

  // internal indeterminate state
  @state() private _selectAllIndeterminate = false;

  /** Enables toggling selection when clicking the row. */
  @property({ attribute: 'select-on-click', type: Boolean })
  public selectOnClick: boolean = false;

  /** Enables expanding/collapsing a row when clicking it. */
  @property({ attribute: 'expand-on-click', type: Boolean })
  public expandOnClick: boolean = false;

  /** Field that data is sorted by. */
  @property({ attribute: 'sort-by' })
  public sortBy?: string;

  /** Indicates if field is sorted ascending. */
  @property({ attribute: 'sort-ascending', type: Boolean })
  public sortAscending: boolean = true;

  /** Used to provide screen readers with a label for the data grid. */
  @property({ reflect: true })
  public label?: string;

  /** Turns off internal scrollbars. Use this if you want to handle overflow scrolling outside of the table. Make sure to handle both vertical and horizontal scrolling. */
  @property({ attribute: 'no-scroll', type: Boolean, reflect: true })
  public noScroll: boolean = false;

  /** Hide the "select all" checkbox. */
  @property({ attribute: 'hide-select-all', type: Boolean })
  public hideSelectAll: boolean = false;

  /** Enable this option to prevent the select options dropdown from being clipped when the component is placed inside a container with `overflow: auto|hidden|scroll`. */
  @property({ type: Boolean, attribute: 'fixed-placement', reflect: true }) fixedPlacement = false;

  /** The table columns and their configuration. */
  @property({ type: Object, attribute: false })
  public columns: Column[] = [];

  /** The table rows and their configuration. */
  @property({ type: Object, attribute: false })
  public rows: Row[] = [];

  /** Enables lazy loading behavior. */
  @property({ type: Boolean })
  public lazy: boolean = false;

  /**
   * When lazy loading is on, set this function to return a promise that resolves to an array of child rows for the
   * provided parent row.
   *
   * @example
   * dataGrid.loadChildren = (parentRow, rows) => {
   *  return fetch(`https://api.example.com/rows/${parentRow.id}/children`)
   *   .then(response => response.json())
   *   .then(data => data.rows);
   * }
   */
  @property({ type: Object, attribute: false })
  public loadChildren: (parentRow: Row, currentChildren: Row[]) => Promise<Row[]> = () => Promise.resolve([]);

  /** Used to generate custom selections in a multiselect table */
  @property({ type: Array, attribute: false })
  public bulkSelectOptions: BulkSelectOption[] = [];

  @state()
  private componentWidth?: number;

  @state()
  private columnCount: number = 0;

  @state()
  private rowCount: number = 0;

  @state()
  private isTree: boolean = false;

  @state()
  private expandAllExpanded: boolean = false;

  @state()
  private currentFocusId?: string;

  @state()
  private navigationLocked = false;

  @state()
  private tableTabIndex: 0 | -1 = 0;

  @query('table')
  private table: HTMLTableElement;

  @query('thead tr')
  private tableHeadRow: HTMLTableRowElement;

  @queryAll('tr:not(.data-grid__row--hidden)')
  private visibleRows: NodeListOf<HTMLTableRowElement>;

  @query('.data-grid__bulk-select')
  private bulkSelectDropdown: Dropdown;

  private currentFocusItem?: HTMLElement;
  private currentLockedCell?: HTMLElement;
  private currentlyFocusingExpanders = false;
  private cellSelector = 'th,td';
  private expanderSelector = '.data-grid__expander__button'; // expand chevron button
  private selectionSelector = '.data-grid__selector'; // selection checkbox or radio
  private headerRowId = 'column-header';

  private get isMultiSelect() {
    return this.select === 'multiple';
  }

  private get isSingleSelect() {
    return this.select === 'single';
  }

  private get allWidgets() {
    return [...this.shadowWidgets, ...this.slottedWidgets];
  }

  private get shadowWidgets() {
    if (!this.shadowRoot) return [];
    return [...this.shadowRoot.querySelectorAll(this.tabbableSelector)] as HTMLElement[];
  }

  private get slottedWidgets() {
    return [...this.querySelectorAll(this.tabbableSelector)] as HTMLElement[];
  }

  /** Gets all rows that are currently selected. */
  get selectedRows(): Row[] {
    return this.rows.filter(x => x.selected);
  }

  private get rootRows() {
    return this.rows.filter((x: Row) => !x?.parentId);
  }

  /** Selector for tabbable items. */
  private get tabbableSelector() {
    const select = [
      'button',
      'input',
      'select',
      'textarea',
      'a[href]',
      'audio[controls]',
      'video[controls]',
      'summary',
      '[contenteditable]:not([contenteditable="false"])',
      '[tabindex]:not(table):not(tr):not(td):not(th)',
      '.he-focusable',
    ];

    const externalSlots = this.externalSlotNames.map(s => `[slot="${s}"]`);
    const externalSlotChildren = this.externalSlotNames.map(s => `[slot="${s}"] *`);
    const skip = [...externalSlots, ...externalSlotChildren, '[disabled]', '[aria-disabled="true"]', '[hidden]'];

    return select.map(item => item + skip.map(s => `:not(${s})`).join('')).join(',');
  }

  /** Selector for items that use arrow keys. */
  private get usesArrowKeysSelector() {
    return [
      'input',
      'select',
      'textarea',
      'audio[controls]',
      'video[controls]',
      '[contenteditable]:not([contenteditable="false"])',
      '.he-uses-arrow-keys',
      '.he-uses-arrow-keys *',
    ].join(',');
  }

  /** Slots outside of the `<table>`. */
  private get externalSlotNames() {
    if (!this.shadowRoot) return [];

    const slots = [...this.shadowRoot.querySelectorAll('slot:not(table *)')] as HTMLSlotElement[];
    const slotNames = slots.map(slot => slot.name);

    return slotNames;
  }

  private getChildRows(row: Row): Row[] {
    return this.rows.filter(x => x?.parentId === row?.id);
  }

  // html ids can't start with numbers, so we need to prefix them
  private getHTMLRowId(id: Row['id']) {
    return `row-${id}`;
  }

  private getHTMLCellId(field: Column['field'], rowId: Row['id']) {
    return `cell-${this.getUniqueCellId(field, rowId)}`;
  }

  private getUniqueCellId(field: Column['field'], rowId: Row['id']) {
    return `${field}-${rowId}`;
  }

  private getCellSlotName(cellElement: HTMLTableCellElement) {
    return cellElement?.querySelector('slot[name]')?.getAttribute('name');
  }

  /** Get the Row object from the HTML row element. */
  private getRowData(el: HTMLTableRowElement) {
    if (!this.isRow(el)) return;

    if (this.isHeaderRow(el)) return { id: this.headerRowId } as Row;

    return this.rows.find(x => this.getHTMLRowId(x.id) === el.id);
  }

  /** Get the field name of the cell from the HTML cell element */
  private getCellData(el: HTMLTableCellElement): CellData | undefined {
    if (!this.isCell(el)) return;

    const rowId = this.isHeaderCell(el)
      ? this.headerRowId
      : // : this.rows.find(x => this.getHTMLRowId(x.id) === el.parentElement?.id)?.id;
        this.getRowData(this.getParentRow(el))?.id;
    if (!rowId) return;

    const column = this.columns.find(x => this.getHTMLCellId(x.field, rowId) === el.id);
    if (!column) return;

    return {
      field: column.field,
      rowId: rowId,
    };
  }

  /** Gets the parent cell if element is a child element or a slotted element. */
  private getParentCell(el: HTMLElement) {
    return this.getParentElement(el, this.cellSelector) as HTMLTableCellElement;
  }

  /** Gets the parent row of a child element or a slotted element. */
  private getParentRow(el: HTMLElement) {
    return this.getParentElement(el, 'tr') as HTMLTableRowElement;
  }

  /**  Gets the closest parent element in the shadow root with matching selector of a child element or a slotted element. */
  private getParentElement(el: HTMLElement, selector: string) {
    if (el.getRootNode() instanceof ShadowRoot) {
      return el.closest(selector) as HTMLElement | null;
    }

    const slotName = el.closest('[data-grid] > [slot]')?.getAttribute('slot');
    const slot = this.shadowRoot?.querySelector(`slot[name="${slotName}"]`);
    return slot?.closest(selector) as HTMLElement | null;
  }

  /** If it's the header row, get the first body row, otherwise get next sibling row. */
  private getNextRow(rowElement: HTMLTableRowElement): HTMLTableRowElement {
    return this.isHeaderRow(rowElement)
      ? this.visibleRows[1]
      : (this.getVisibleSibling(rowElement, 'next') as HTMLTableRowElement);
  }

  /** Get previous row, or if none get the header row. */
  private getPreviousRow(rowElement: HTMLTableRowElement): HTMLTableRowElement {
    return (this.getVisibleSibling(rowElement, 'previous') as HTMLTableRowElement) || this.tableHeadRow;
  }

  /** Returns ArrowLeft or ArrowRight based on this.dir */
  private getHorizontalDirection(key: (typeof keys)['ArrowLeft'] | (typeof keys)['ArrowRight']) {
    return this.dir === 'rtl' ? (key === keys.ArrowLeft ? keys.ArrowRight : keys.ArrowLeft) : key;
  }

  private isRow(element: HTMLElement) {
    return element?.matches('tr');
  }

  private isCell(element: HTMLElement) {
    return element?.matches(this.cellSelector);
  }

  private isHeaderRow(element: HTMLElement) {
    return element?.matches('thead tr');
  }

  private isHeaderCell(element: HTMLElement) {
    return element?.matches('thead th');
  }

  private isVisible(element: HTMLElement) {
    return !element.matches('.data-grid__row--hidden') && !element.hidden;
  }

  /** Checks if an element is slotted in or a child in the shadow root of a specific row. */
  private isInRow(rowElement: HTMLTableRowElement, widget: HTMLElement) {
    const row = this.getRowData(rowElement);

    const slotNames = row?.id ? this.columns.map(x => this.getUniqueCellId(x.field, row.id)) : null;
    const slotSelector = slotNames?.map(x => `[slot="${x}"], [slot="${x}"] *`).join(', ');

    return widget?.matches(`#${rowElement?.id} *${slotSelector ? `, ${slotSelector}` : ''}`);
  }

  /** Checks if an element is slotted in or a child in the shadow root of a specific cell. */
  private isInCell(cellElement: HTMLTableCellElement, widget: HTMLElement) {
    const slotName = this.getCellSlotName(cellElement);
    const slotSelector = slotName ? `[slot="${slotName}"], [slot="${slotName}"] *` : null;

    return widget?.matches(`#${cellElement?.id} *${slotSelector ? `, ${slotSelector}` : ''}`);
  }

  private hasChildren(row: Row) {
    return (this.lazy && row?.hasLazyChildren) || this.rows.some(x => x?.parentId === row?.id);
  }

  /** @internal watcher */
  @watch('selectAll')
  async handleSelectAllChange() {
    if (!this.isMultiSelect) return;

    this.updateComplete.then(() => {
      this.updateSelectedRowsBasedOnSelectAll();
    });
  }

  /** @internal watcher */
  @watch('columns')
  handleColumnsChange() {
    this.initResizeObserver();
  }

  /** @internal watcher */
  @watch('rows')
  async handleRowsChange() {
    this.isTree = this.lazy || this.rows.some(x => x?.parentId);

    // total row count, including header row. -1 means the size is unknown.
    this.rowCount = this.lazy ? -1 : this.rows.length + 1;

    if (this.isMultiSelect) {
      // if all rows (excluding disabled) are selected, selectAll is checked
      const enabledRows = this.rows.filter(x => x?.disabled !== true);
      this.selectAll = enabledRows.length > 0 && enabledRows.every(x => x?.selected === true);

      // indeterminate if some rows are selected and some are not
      this._selectAllIndeterminate =
        enabledRows.some(x => x?.selected === true) && enabledRows.some(x => x?.selected !== true);
    }

    // assign unique id to any rows missing id
    this.rows.forEach((row, i, arr) => {
      if (row === undefined) {
        arr[i] = { id: `he-row-${uniqueRowId++}`, cells: {} };
      } else if (!row.id) {
        row.id = `he-row-${uniqueRowId++}`;
      }
    });

    if (this.isTree && !this.lazy) {
      this.expandAllExpanded = this.rows.every(x => !this.hasChildren(x) || x.expanded);
    }

    if (this.lazy) {
      this.updateExpandedRows();
    }
  }

  /** @internal watcher */
  @watch('navigationLocked', { waitUntilFirstUpdate: true })
  handleNavigationLockedChange() {
    this.currentLockedCell =
      this.navigationLocked && this.currentFocusItem ? this.getParentCell(this.currentFocusItem) : undefined;
  }

  disconnectedCallback() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this);
      this.resizeObserver.disconnect();
    }

    this.navigationLocked = false;

    super.disconnectedCallback();
  }

  private handleSort(field: Column['field']) {
    if (field === this.sortBy) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortBy = field;
      this.sortAscending = true;
    }

    this.emitSort();
  }

  private handleBulkSelect(name: BulkSelectOption['name']) {
    this.emit('he-bulk-select', { detail: name });
    this.bulkSelectDropdown.hide();
  }

  private async handleSelectAll(e: Event) {
    const key = (e as any).key;
    if (key && key !== ' ') {
      return;
    }

    const checkbox = e.target as Checkbox;

    this.toggleRows(checkbox.checked);
    this.emit('he-select-all-change', { detail: checkbox.checked });
  }

  private async handleSelectRow(row: Row, e: Event) {
    const checkbox = e.target as Checkbox;
    await this.updateComplete;

    if (!this.isMultiSelect) {
      this.toggleRowById(row.id);
    }

    if (this.isMultiSelect) {
      row.selected = checkbox.checked;

      // select all child rows
      this.selectDescendants(row, checkbox.checked);

      // trigger update of template repeat item
      this.rows = [...this.rows];
    }

    this.emit('he-row-select-change', { detail: row });
  }

  private selectDescendants(row: Row, checked: boolean) {
    this.getChildRows(row).forEach(child => {
      child.selected = checked;
      this.selectDescendants(child, checked);
    });
  }

  private updateSelectedRowsBasedOnSelectAll() {
    if (!this._selectAllIndeterminate && this.selectAll !== undefined) {
      this.rows = this.rows.map((x: Row) => {
        if (!x.disabled) {
          x.selected = this.selectAll;
        }
        return x;
      });
    }
  }

  private emitSort() {
    const sortBy: SortBy = {
      sortBy: this.sortBy as string,
      isAscending: this.sortAscending as boolean,
    };

    this.emit('he-sort', { detail: sortBy });
  }

  private toggleRows(checked: boolean) {
    this.rows = this.rows.map((row: Row) => {
      if (!row.disabled) {
        row.selected = checked;
      }
      return row;
    });
  }

  private toggleRowById(id: Row['id']) {
    this.rows = this.rows.map((row: Row) => {
      if (!row.disabled) {
        row.selected = row.id === id;
      }

      return row;
    });
  }

  private initResizeObserver() {
    this.columnCount = this.columns?.length;
    const hasResponsiveColumns = this.columns && this.columns.some(x => x?.display?.hideAt);
    if (!hasResponsiveColumns) {
      return;
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.componentWidth = this.clientWidth;
      this.columnCount = this.columns.filter(
        x => !x?.display?.hideAt || (this.componentWidth as number) > x.display.hideAt
      ).length;
    });

    this.resizeObserver.observe(this);
  }

  private handleExpandAll() {
    const expand = !this.expandAllExpanded;

    this.rows.forEach((row: Row) => {
      if (this.hasChildren(row)) {
        expand ? this.expandRow(row, false) : this.collapseRow(row, false);
      }
    });

    this.emit('he-expand-all-change', { detail: expand });
  }

  private async getLazyChildren(row: Row): Promise<Row[]> {
    const currentChildren = this.rows.filter(x => x.parentId === row.id);
    let loadChildren = await this.loadChildren(row, currentChildren);

    // if no children are returned, show a disabled row with a message
    if (!loadChildren?.length) {
      loadChildren = [
        {
          id: `${row.id}-empty-child`,
          disabled: true,
          cells: this.columns.reduce<Record<string, string>>((cell, column, i) => {
            cell[column.field] = i === 0 ? this.localize.term('no_results_found') : '';
            return cell;
          }, {}),
        },
      ];
    }

    // add parent id and selected state to children
    return loadChildren.map((child: Row) => {
      if (child.selected === undefined || child.selected === null) child.selected = row.selected;
      if (!child.parentId) child.parentId = row.id;
      return child;
    });
  }

  /** Loads children for row if lazy is on, otherwise returns existing children. */
  private async loadLazyChildren(row: Row) {
    if (!this.lazy) {
      return this.getChildRows(row);
    }

    const rowEl = this.shadowRoot?.querySelector(`#${this.getHTMLRowId(row.id)}`) as HTMLTableRowElement;

    rowEl.setAttribute('aria-busy', 'true');
    const children = await this.getLazyChildren(row);
    this.rows = this.setRowChildren(row.id, children);
    await this.updateComplete;
    rowEl.setAttribute('aria-busy', 'false');

    return children;
  }

  private setRowChildren(rowId: Row['id'], children: Row[]): Row[] {
    if (!children.length) return this.rows;

    // remove existing children
    const rows = this.rows.filter(x => x.parentId !== rowId);

    // return rows with new children
    return [...rows, ...children];
  }

  private updateExpandedRows() {
    if (!this.lazy) return;

    const expandedRows = this.rows.filter(x => x.expanded);

    expandedRows.forEach(async row => {
      // if children don't exist when lazy and expanded, state must must have been changed programmatically and not by user,
      // load them in now (with no animation)
      if (!this.getChildRows(row)?.length) {
        await this.updateComplete;
        this.loadLazyChildren(row);
      }
    });
  }

  private async expandRow(row: Row, animate: boolean = true) {
    if (row.expanded || !this.hasChildren(row)) return;

    const event = this.emit('he-row-expand', { detail: { expanded: true, ...row }, cancelable: true });
    if (event.defaultPrevented) return;

    const children = await this.loadLazyChildren(row);
    row.expanded = true;
    this.rows = [...this.rows];

    if (animate) await this.animateRows(children, 'expand');

    this.emit('he-after-row-expand', { detail: { row, expand: true } });
  }

  private async collapseRow(row: Row, animate: boolean = true) {
    if (!row.expanded) return;

    const event = this.emit('he-row-collapse', { detail: { expanded: false, ...row }, cancelable: true });
    if (event.defaultPrevented) return;

    row.expanded = false;
    this.rows = [...this.rows];

    if (animate) await this.animateRows(this.getChildRows(row), 'collapse');

    this.emit('he-after-row-collapse', { detail: { row, expand: false } });
  }

  private async toggleRowExpand(row: Row) {
    row.expanded ? this.collapseRow(row) : this.expandRow(row);
  }

  private handleFocusIn(event: FocusEvent) {
    const target = event.target as HTMLElement;

    this.tableTabIndex = -1;

    if (target === this.table) {
      this.handleTableFocusIn(event);
      return;
    }

    // target otherwise could be a cell, row, a widget in our shadowRoot, or a slotted widget

    const isRow = this.isRow(target);
    const isCell = this.isCell(target);
    const rowEl = isRow ? (target as HTMLTableRowElement) : this.getParentRow(target);
    const initialTabIndex = target.getAttribute('tabindex');

    // tab indexes for rows and cells will be set in template based on currentFocusId
    this.currentFocusId = isRow || isCell ? target.id : undefined;
    this.currentFocusItem = target;

    if (!isRow) this.currentlyFocusingExpanders = false;

    // if navigation is locked and focus was moved to another element in the table but outside the locked cell, unlock navigation
    if (this.navigationLocked && this.currentLockedCell && !event.composedPath().includes(this.currentLockedCell)) {
      this.navigationLocked = false;
    }

    // for some reason selected text combined with tabindex changes causes click not to be seen on checkboxes, so clear it first
    if (target.matches('[checkbox]')) {
      window?.getSelection()?.empty();
    }

    // update all tab indexes so widgets in current row are available for focus
    this.setAllWidgetsTabIndexByRow(rowEl);

    // if it's a Harmony widget, focus gets lost in chromium when focus() is called and the tabindex started as -1,
    // so in that scenario call focus again now that tab indexes are updated
    if (target.matches('.he-focusable') && initialTabIndex === '-1') {
      target.focus();
      return;
    }

    if (isCell) {
      // if focus is on the cell with an expander, remove expander from the tab order so Tab won't move to it
      target.querySelector(this.expanderSelector)?.setAttribute('tabindex', '-1');

      // if focus() was called on a cell, but there is a single approved widget in it, focus on it
      const focusWidget = this.getFocusCellWidget(target as HTMLTableCellElement);
      focusWidget?.focus();
      return;
    }
  }

  private handleTableFocusIn(event: FocusEvent) {
    // if the table has been focused, move the focus to the last focused item or the header row
    const focusItem =
      this.currentFocusItem && this.isVisible(this.currentFocusItem) ? this.currentFocusItem : this.tableHeadRow;
    const focusItemIsRow = this.isRow(focusItem);
    const focusItemIsCell = this.isCell(focusItem);

    if (!focusItemIsRow && !focusItemIsCell) {
      const rowEl = focusItemIsRow ? (focusItem as HTMLTableRowElement) : this.getParentRow(focusItem);
      this.setAllWidgetsTabIndexByRow(rowEl);
    }

    focusItem.focus();
  }

  private handleFocusOut(event: FocusEvent) {
    const relatedTarget = event.relatedTarget as HTMLElement;
    const externalSlots = this.externalSlotNames.map(s => `[slot="${s}"]`);
    const externalSlotChildren = this.externalSlotNames.map(s => `[slot="${s}"] *`);
    const skip = [...externalSlots, ...externalSlotChildren].join(', ');

    // if the element that got the focus is not in the table, reset tab indexes
    if (
      !relatedTarget ||
      (!this.contains(relatedTarget) && !this.renderRoot?.contains(relatedTarget)) ||
      relatedTarget?.matches(skip)
    ) {
      this.tableTabIndex = 0;
      this.allWidgets.filter(x => x !== this.currentFocusItem).forEach(widget => widget.setAttribute('tabindex', '-1'));
    }
  }

  private handleKeydown(e: KeyboardEvent) {
    // ignore keys we don't want to handle
    if (
      ![
        keys.ArrowDown,
        keys.ArrowLeft,
        keys.ArrowRight,
        keys.ArrowUp,
        keys.End,
        keys.Enter,
        keys.Escape,
        keys.F2,
        keys.Home,
        keys.Tab,
        keys.Space,
      ].includes(e.key) ||
      !(e.target instanceof HTMLElement)
    )
      return;

    this.navigationLocked ? this.handleLockedKeydown(e) : this.handleUnlockedKeydown(e);

    switch (e.key) {
      case keys.Home:
      case keys.End:
        e.preventDefault();
        this.isRow(e.target) ? this.handleRowHomeEnd(e) : this.handleCellHomeEnd(e);
        break;

      case keys.Escape:
        this.navigationLocked = false;
        this.getParentCell(e.target)?.focus();
        e.stopPropagation();
        break;
    }
  }

  private handleUnlockedKeydown(e: KeyboardEvent) {
    const el = e.target as HTMLElement;

    switch (e.key) {
      case keys.ArrowDown:
      case keys.ArrowUp:
        if (el.matches(this.usesArrowKeysSelector)) return;

        e.preventDefault();
        this.isRow(el) ? this.handleRowArrowUpDown(e) : this.handleCellArrowUpDown(e);
        break;

      case keys.ArrowRight:
      case keys.ArrowLeft:
        if (el.matches(this.usesArrowKeysSelector)) return;

        e.preventDefault();
        this.isRow(el) ? this.handleRowArrowRightLeft(e) : this.handleCellArrowRightLeft(e);
        break;

      case keys.Home:
      case keys.End:
        e.preventDefault();
        this.isRow(el) ? this.handleRowHomeEnd(e) : this.handleCellHomeEnd(e);
        break;

      case keys.Enter:
        this.handleEnterSpace(e);
        break;

      case keys.Space:
        e.shiftKey ? this.handleShiftSpace(e) : this.handleEnterSpace(e);
        break;

      case keys.F2:
        if (this.isCell(el)) {
          e.preventDefault();
          this.cellAction(el as HTMLTableCellElement);
        }
        break;

      case keys.Tab:
        this.handleTab(e);
        break;
    }
  }

  private handleRowArrowUpDown(e: KeyboardEvent) {
    if (!this.isRow(e.target as HTMLElement)) return;
    const el = e.target as HTMLTableRowElement;
    const focusRow = e.key === keys.ArrowDown ? this.getNextRow(el) : this.getPreviousRow(el);

    if (this.currentlyFocusingExpanders) {
      const expander = focusRow?.querySelector(this.expanderSelector) as HTMLElement | null;
      if (expander) {
        expander.focus();
        return;
      }
    }

    focusRow?.focus();
  }

  private handleRowArrowRightLeft(e: KeyboardEvent) {
    if (!this.isRow(e.target as HTMLElement)) return;
    const el = e.target as HTMLTableRowElement;

    if (!this.isHeaderRow(el) && !this.loading) {
      const row = this.getRowData(el);
      if (!row) return;

      // left arrow focuses on parent if it's a child row and collapsed
      if (e.key === this.getHorizontalDirection(keys.ArrowLeft) && row?.parentId && !row?.expanded) {
        const focusRow = this.shadowRoot?.querySelector(`#${this.getHTMLRowId(row.parentId)}`) as HTMLElement | null;
        focusRow?.focus();
        return;
      }
    }

    // right arrow focuses on first cell in the row otherwise
    if (e.key === this.getHorizontalDirection(keys.ArrowRight)) {
      const firstCell = el.querySelector(this.cellSelector) as HTMLTableCellElement | null;
      const expander = firstCell?.querySelector(this.expanderSelector) as HTMLElement | null;

      // if it has an expander, focus on that first
      (expander || firstCell)?.focus();
      return;
    }
  }

  private handleRowHomeEnd(e: KeyboardEvent) {
    if (!this.isRow(e.target as HTMLElement)) return;

    const focusRow = e.key === keys.Home ? this.tableHeadRow : this.visibleRows[this.visibleRows.length - 1];
    focusRow?.focus();
  }

  private handleCellArrowRightLeft(e: KeyboardEvent) {
    const el = e.target as HTMLElement;
    const currentCell = this.getParentCell(el);
    if (!currentCell) return;

    let nextCell = this.getVisibleSibling(
      currentCell,
      e.key === this.getHorizontalDirection(keys.ArrowRight) ? 'next' : 'previous'
    ) as HTMLTableCellElement | null;

    let nextExpander: HTMLElement | null = null;

    if (e.key === this.getHorizontalDirection(keys.ArrowRight)) {
      // if focus is on an expander, focus on the current cell
      if (el.matches(this.expanderSelector)) {
        nextCell = currentCell;
      } else {
        nextExpander = nextCell?.querySelector(this.expanderSelector) as HTMLElement | null;
      }
    } else if (e.key === this.getHorizontalDirection(keys.ArrowLeft)) {
      const thisCellExpander = currentCell?.querySelector(this.expanderSelector) as HTMLElement | null;

      // if there is an expander in this cell, focus on that
      if (thisCellExpander && !el.matches(this.expanderSelector)) {
        nextExpander = thisCellExpander;
      }

      // if there is no previous cell or expander, focus on parent
      if (!nextCell && (!thisCellExpander || el.matches(this.expanderSelector))) {
        currentCell.parentElement?.focus();
        return;
      }
    }

    // if nextExpander is set, focus on that, otherwise focus on the cell
    (nextExpander || nextCell)?.focus();
  }

  private handleCellArrowUpDown(e: KeyboardEvent) {
    const el = e.target as HTMLElement;
    const currentCell = this.getParentCell(el);
    if (!currentCell) return;

    // if focus is on an expander button, we keep focus on expander buttons and just move to the next one
    if (el.matches(this.expanderSelector)) {
      const nextFocus = this.getSiblingRowExpander(el, e.key === keys.ArrowDown ? 'next' : 'previous');

      if (!nextFocus?.matches(this.expanderSelector)) {
        // if there is no next expander, nextFocus will be the next row, keep track that user wants to focus on expanders
        this.currentlyFocusingExpanders = true;
      }

      nextFocus?.focus();
      return;
    }

    const nextCell =
      e.key === keys.ArrowDown
        ? this.getSiblingRowCell(currentCell, 'next')
        : this.getSiblingRowCell(currentCell, 'previous');

    // we are changing rows, so update tab indexes
    if (nextCell) this.setAllWidgetsTabIndexByRow(this.getParentRow(nextCell));

    nextCell?.focus();
  }

  private handleTab(e: KeyboardEvent) {
    const el = e.target as HTMLElement;

    // if shift isn't used or focus is on a row, do default Tab behavior
    if (!e.shiftKey || this.isRow(el)) return;

    const isCell = this.isCell(el);
    const expander = el.querySelector(this.expanderSelector) as HTMLElement | null;

    // if focus is on the first cell and it has an expander, focus on the expander
    if (isCell && expander) {
      e.preventDefault();
      expander.focus();
      return;
    }

    const widgets = this.allWidgets.filter(widget => this.isInRow(this.getParentRow(widget), el));

    // if focus is on a cell and there are widgets in the row in previous cells, it should do default behavior
    if (isCell && widgets.length > 0 && widgets[0] !== el) {
      const previousCells = this.getAllVisibleSiblings(el, 'previous') as HTMLTableCellElement[];

      if (previousCells.some(cell => widgets.some(widget => this.isInCell(cell, widget)))) {
        return;
      }
    }

    // if there are no previous widgets in row, shift tab should focus on parent row
    if (isCell || widgets.length === 0 || widgets[0] === el) {
      const row = this.getParentRow(el);
      if (!row) return;

      e.preventDefault();
      row.focus();
    }
  }

  private handleEnterSpace(e: KeyboardEvent) {
    const el = e.target as HTMLElement;

    // checkboxes/radios don't normally trigger with Enter, but in this case we still want it to do the default
    // action of selecting the row when the checkbox is focused
    if (e.key === keys.Enter && el.matches(this.selectionSelector)) {
      el.click();
      e.preventDefault();
      return;
    }

    // continue with default behavior if it's a widget focused and not a row or cell
    if (!this.isRow(el) && !this.isCell(el)) return;

    e.preventDefault();

    this.isRow(el) ? this.rowInvoke(el as HTMLTableRowElement) : this.cellAction(el as HTMLTableCellElement);
  }

  private handleShiftSpace(e: KeyboardEvent) {
    const el = e.target as HTMLElement;

    // default behavior if no shift key or rows are not selectable
    if (!e.shiftKey || !this.select) return;

    e.preventDefault();

    // shift space selects row
    const row = this.isRow(el) ? el : this.getParentRow(el);
    const selector = row.querySelector(this.selectionSelector) as HTMLElement | null;

    selector?.click();
  }

  private handleLockedKeydown(e: KeyboardEvent) {
    const el = e.target as HTMLElement;

    // ignore keys we don't want to handle
    if (
      ![keys.ArrowDown, keys.ArrowLeft, keys.ArrowRight, keys.ArrowUp, keys.F2, keys.Tab].includes(e.key) ||
      !(e.target instanceof HTMLElement)
    )
      return;

    // if we are focused on a row or cell, something went wrong and navigation should not be locked...
    if (this.isRow(el) || this.isCell(el)) {
      this.navigationLocked = false;
      this.handleUnlockedKeydown(e);
      return;
    }

    switch (e.key) {
      case keys.ArrowDown:
      case keys.ArrowUp:
      case keys.ArrowRight:
      case keys.ArrowLeft:
      case keys.Tab:
        // if a widget uses arrow keys and an arrow key was pushed, we should not handle it
        if (e.key !== keys.Tab && el.matches(this.usesArrowKeysSelector)) return;

        this.handleLockedDirectionKeys(e);
        break;

      case keys.F2:
        this.navigationLocked = false;
        this.getParentCell(el)?.focus();
        break;
    }
  }

  private handleLockedDirectionKeys(e: KeyboardEvent) {
    const el = e.target as HTMLElement;

    const widgets = this.getCellWidgets(this.getParentCell(el));
    const direction =
      e.key === keys.ArrowDown ||
      e.key === this.getHorizontalDirection(keys.ArrowRight) ||
      (e.key === keys.Tab && !e.shiftKey)
        ? 1
        : -1;

    // if grid is locked just due to a widget needing arrow keys, but there is only one widget in cell, tab should unlock navigation
    if (e.key === keys.Tab && widgets.length === 1 && widgets[0].matches(this.usesArrowKeysSelector)) {
      this.navigationLocked = false;
      return;
    }

    // if we are focused on a widget in the cell, focus next widget
    if (widgets.includes(el)) {
      e.preventDefault();

      const nextWidget = widgets[widgets.indexOf(el) + direction];

      if (nextWidget) {
        nextWidget?.focus();
      } else {
        // if there is no next widget, wrap around to the first/last widget
        widgets[direction === 1 ? 0 : widgets.length - 1]?.focus();
      }
    }
  }

  private handleCellHomeEnd(e: KeyboardEvent) {
    const el = e.target as HTMLElement;
    const currentCell = this.getParentCell(el);
    if (!currentCell) return;

    let focusCell;

    if (e.ctrlKey) {
      // with ctrl focus should move to the first/last cell in the column
      const row = e.key === keys.Home ? this.tableHeadRow : this.visibleRows[this.visibleRows.length - 1];
      focusCell = row?.children[currentCell.cellIndex];
    } else {
      // without ctrl it should focus on first/last cell in current row
      const row = currentCell.parentElement as HTMLTableRowElement;
      focusCell = e.key === keys.Home ? row.firstElementChild : row.lastElementChild;
    }

    (focusCell as HTMLTableCellElement)?.focus();
  }

  private handleSlotChange() {
    this.slottedWidgets
      .filter(x => x !== this.currentFocusItem)
      .forEach(widget => widget.setAttribute('tabindex', '-1'));
  }

  private handleRowClick = (e: MouseEvent) => {
    const row = e.currentTarget as HTMLTableRowElement;
    if (!this.isRow(row) || this.isHeaderRow(row)) return;

    // path of elements from click target to row
    const path = e
      .composedPath()
      .filter(x => x instanceof HTMLElement)
      .filter((x: HTMLElement) => this.isInRow(row, x)) as HTMLElement[];

    if (path.some(x => x.matches(this.tabbableSelector))) return;

    this.rowInvoke(row);
  };

  /** Make all widgets in row focusable, and all widgets in any other row non-focusable. */
  private setAllWidgetsTabIndexByRow(activeRowElement: HTMLTableRowElement) {
    this.allWidgets.forEach(widget => {
      this.setWidgetTabIndexByRow(activeRowElement, widget);
    });
  }

  private setWidgetTabIndexByRow(activeRowElement: HTMLTableRowElement, widget: HTMLElement) {
    // keep radios non focusable, focusing on radio group will focus correct radio
    if (widget.matches('[radio-group] [radio]') || widget.matches('[checkbox-group] [checkbox]')) return;

    if (this.isInRow(activeRowElement, widget)) {
      // elements with he-focusable have focus in their shadow root, tabbing is restored by removing the attribute
      // for others, set to 0 as they may have only been focusable due to having tabindex=0 on them to begin with
      widget.matches('.he-focusable') ? widget.removeAttribute('tabindex') : widget.setAttribute('tabindex', '0');
    } else {
      widget.setAttribute('tabindex', '-1');
    }
  }

  /** Get all widgets in cell, excluding expander. */
  private getCellWidgets(cellElement: HTMLTableCellElement): HTMLElement[] {
    return this.allWidgets.filter(
      widget => this.isInCell(cellElement, widget) && !widget.matches(this.expanderSelector)
    );
  }

  /** If there is exactly one widget, and it doesn't use arrow keys, focus should go to widget instead of cell */
  private getFocusCellWidget(cellElement: HTMLTableCellElement): HTMLElement | null {
    const widgets = this.getCellWidgets(cellElement);
    return widgets.length === 1 && !widgets[0].matches(this.usesArrowKeysSelector) ? widgets[0] : null;
  }

  /** Get next/previous visible sibling. */
  private getVisibleSibling(el: HTMLElement, direction: 'next' | 'previous' = 'next'): HTMLElement | null {
    const next = direction === 'previous' ? el.previousElementSibling : el.nextElementSibling;
    if (!next || !(next instanceof HTMLElement)) return null;

    if (!this.isVisible(next)) {
      return this.getVisibleSibling(next, direction);
    }

    return next;
  }

  /** Get all next/previous visible siblings. */
  private getAllVisibleSiblings(el: HTMLElement, direction: 'next' | 'previous' = 'next'): HTMLElement[] {
    const next = this.getVisibleSibling(el, direction);
    if (!next || !(next instanceof HTMLElement)) return [];

    return [next, ...this.getAllVisibleSiblings(next, direction)];
  }

  /** Get cell in the next/previous row in the same column. */
  private getSiblingRowCell(
    cellElement: HTMLTableCellElement,
    direction: 'next' | 'previous' = 'next'
  ): HTMLTableCellElement | null {
    const row = cellElement.parentElement as HTMLTableRowElement;
    const cells = [...row.querySelectorAll(this.cellSelector)];
    const columnNumber = cells.indexOf(cellElement);
    const nextRow = direction === 'previous' ? this.getPreviousRow(row) : this.getNextRow(row);
    if (!nextRow) return null;

    return [...nextRow.querySelectorAll(this.cellSelector)][columnNumber] as HTMLTableCellElement;
  }

  /** Get expander in the next/previous row, or return row if no expander. */
  private getSiblingRowExpander(
    expanderElement: HTMLElement,
    direction: 'next' | 'previous' = 'next'
  ): HTMLTableRowElement | Button | null {
    const currentRow = this.getParentRow(expanderElement);
    const nextRow = direction === 'previous' ? this.getPreviousRow(currentRow) : this.getNextRow(currentRow);
    if (!nextRow) return null;

    const expander = nextRow.querySelector(this.expanderSelector) as Button | null;

    return expander || nextRow;
  }

  private async animateRows(childRows: Row[], expandOrCollapse: 'expand' | 'collapse') {
    if (!childRows?.length) return;

    const keyframes =
      expandOrCollapse === 'expand'
        ? [
            { height: '0', opacity: '0' },
            { height: 'auto', opacity: '1' },
          ]
        : [
            { height: 'auto', opacity: '1' },
            { height: '0', opacity: '0' },
          ];

    await Promise.all(
      [...childRows]?.map(async (x: Row) => {
        const rowSelector = `#${this.getHTMLRowId(x.id)}`;
        const rowEl = this.shadowRoot?.querySelector(rowSelector) as HTMLElement;
        const animateEl = rowEl?.querySelectorAll('.data-grid__cell-content-wrapper');

        if (!rowEl) return;

        rowEl.classList.add('data-grid__row--animating');

        await Promise.all(
          [...animateEl]?.map(async (el: HTMLElement) => {
            await stopAnimations(el);
            await animateTo(el, shimKeyframesHeightAuto(keyframes, el.scrollHeight), {
              duration: 250,
              easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
            });
          })
        );

        rowEl.classList.remove('data-grid__row--animating');
      })
    );
  }

  /** Invoke default row action. */
  private rowInvoke(el: HTMLTableRowElement) {
    if (this.isHeaderRow(el) || !this.isRow(el)) return;

    const emit = this.emit('he-row-invoke', { detail: this.getRowData(el), cancelable: true });
    if (emit.defaultPrevented) return;

    // if selectOnClick is on and there is a checkbox/radio, click it
    if (this.select && this.selectOnClick) {
      const selector = el.querySelector(this.selectionSelector) as HTMLElement | null;
      if (selector) {
        selector.click();
        return;
      }
    }

    if (this.expandOnClick) {
      const expander = el.querySelector(this.expanderSelector) as HTMLElement | null;
      if (expander) {
        expander.click();
        return;
      }
    }
  }

  /** If there are multiple widgets in the cell or a widget that uses arrow keys, locks grid navigation and focuses on the first widget. */
  private cellAction(el: HTMLTableCellElement) {
    const widgets = this.getCellWidgets(el);

    if (widgets.length > 1 || widgets.some(x => x.matches(this.usesArrowKeysSelector))) {
      this.navigationLocked = true;
    }

    if (widgets.length > 0) widgets[0].focus();
  }

  render() {
    return html`
      <div
        class=${classMap({
          'data-grid': true,
          'data-grid--fixed-heading': this.fixedHeading,
          'data-grid--fixed-column': this.fixedColumn,
          'data-grid--no-scroll': this.noScroll,
          'data-grid--tree': this.isTree,
        })}
      >
        <div class="data-grid__controls">
          <slot name="data-grid-controls"></slot>
        </div>

        <div class="data-grid__responsive-table he-responsive-table">
          <table
            role="${this.isTree ? 'treegrid' : 'grid'}"
            aria-rowcount="${this.rowCount}"
            aria-colcount="${this.columnCount}"
            aria-busy="${this.loading}"
            aria-multiselectable="${this.isMultiSelect}"
            aria-label=${ifDefined(this.label)}
            class=${classMap({
              'data-grid__table': true,
              'he-table': true,
              'he-table--hover': true,
              'he-table--fixed-heading': this.fixedHeading,
              'he-table--fixed-column': this.fixedColumn,
            })}
            @keydown=${this.handleKeydown}
            @focusin=${this.handleFocusIn}
            @focusout=${this.handleFocusOut}
            tabindex=${this.tableTabIndex}
          >
            <thead role="rowgroup" part="table-head">
              <tr
                role="row"
                id=${this.headerRowId}
                aria-rowindex="${ifDefined(this.isTree && !this.lazy ? 1 : undefined)}"
                tabindex=${this.currentFocusId === this.headerRowId ? '0' : '-1'}
              >
                ${this.columns.map((x, i) => this.headingTemplate(x, i))}
              </tr>
            </thead>
            <tbody role="rowgroup" part="table-body">
              ${repeat(
                this.rootRows,
                row => row?.id,
                (row, index) => this.rowTemplate(row, index, this.rootRows.length)
              )}
            </tbody>
          </table>
        </div>

        <div class="data-grid__no-records" aria-live="polite">
          ${!this.rows || this.rows.length === 0
            ? html`
                <div class="data-grid__no-records__wrapper" part="no-records">
                  <slot name="no-records"></slot>
                </div>
              `
            : ''}
        </div>

        <div class="data-grid__pagination">
          <slot name="pagination"></slot>
        </div>
      </div>
    `;
  }

  private loadingTemplate = () => html`
    <${this.scope.tag('skeleton')}
      class="data-grid__loading"
      shape="rect"
      shimmer
    >
    </${this.scope.tag('skeleton')}>
  `;

  private selectAllTemplate = () => {
    if (!this.select) return '';

    const selectAllCheckbox = html`
      <${this.scope.tag('checkbox')}
        class="data-grid__selector data-grid__select-all data-grid__widget"
        ?indeterminate=${live(this._selectAllIndeterminate || this.selectAllIndeterminate)}
        ?checked=${live(this.selectAll)}
        ?disabled=${this.rows?.length === 0}
        @keyup=${(event: KeyboardEvent) => this.handleSelectAll(event)}
        @he-change=${(event: MouseEvent) => this.handleSelectAll(event)}
        tabindex="-1"
        hide-label
      >
        ${this.localize.term('select_all')}
      </${this.scope.tag('checkbox')}>
    `;

    const bulkOptionsDropdown = html`
      <${this.scope.tag('dropdown')} class="data-grid__bulk-select" ?fixed-placement=${this.fixedPlacement}>
        <button slot="trigger" class="data-grid__bulk-select__trigger data-grid__widget" tabindex="-1">
          <${this.scope.tag('icon')}
            name="chevrondown"
            label="${this.localize.term('more_options')}"
            class="data-grid__bulk-select__icon"
          >
            </${this.scope.tag('icon')}>
        </button>
        <${this.scope.tag('menu')}>
          ${this.bulkSelectOptions.map(
            x => html`
              <${this.scope.tag('menu-item')}
                class="data-grid__bulk-select__options"
                @click=${() => this.handleBulkSelect(x.name)}
              >
                ${x.content}
              </${this.scope.tag('menu-item')}>
            `
          )}
        </${this.scope.tag('menu')}>
      </${this.scope.tag('dropdown')}>
    `;

    return html`
      <th
        role="columnheader"
        class=${classMap({
          'data-grid__table-header': true,
          'data-grid__table-header--selector': true,
          'data-grid__table-header--bulk-options': this.bulkSelectOptions?.length,
        })}
        id="header-selection"
        tabindex=${this.currentFocusId === 'header-selection' ? '0' : '-1'}
      >
        ${when(
          this.isMultiSelect && !this.hideSelectAll,
          () =>
            html`
              <span class="data-grid__table-header__content">
                ${selectAllCheckbox} ${this.bulkSelectOptions?.length ? bulkOptionsDropdown : ''}
              </span>
            `
        )}
      </th>
    `;
  };

  private headingTemplate = (column: Column, index: number) => {
    const sortIcon = html`
      <${this.scope.tag('icon')}
        name="${this.sortAscending ? 'sortup' : 'sortdown'}"
        class=${classMap({
          'data-grid__sort-icon': true,
          'data-grid__sort-icon--sorted': this.sortBy === column?.field,
        })}
      ></${this.scope.tag('icon')}>
    `;

    const sortButton = html`
      <button
        class="data-grid__sort data-grid__widget"
        @click=${(event: MouseEvent) => this.handleSort(column?.field)}
        tabindex="-1"
      >
        ${column?.content} ${sortIcon}
      </button>
    `;

    const expandAllExpander = html`<span class="data-grid__expand-all data-grid__expander">
      ${!this.lazy
        ? html`
          <button
            class=${classMap({
              'data-grid__widget': true,
              'data-grid__expand-all__button': true,
              'data-grid__expander__button': true,
              'data-grid__expander__button--expanded': this.expandAllExpanded,
            })}
            aria-expanded="${this.expandAllExpanded}"
            @click=${this.handleExpandAll}
            tabindex="-1"
          >
            <${this.scope.tag('icon')}
              name="ChevronDown"
              label="${
                this.expandAllExpanded ? this.localize.term('collapse_all_rows') : this.localize.term('expand_all_rows')
              }"
            ></${this.scope.tag('icon')}>
          </button>`
        : ''}
    </span>`;

    const isHidden = !!(column?.display?.hideAt && column.display.hideAt >= (this.componentWidth as number));
    const cellId = this.getHTMLCellId(column?.field, this.headerRowId);

    return html`
      ${index === 0 ? this.selectAllTemplate() : ''}
      <th
        role="columnheader"
        class=${classMap({
          'data-grid__table-header': true,
          'data-grid__table-header--sortable': column?.sortable ? true : false,
          [`data-grid__table-header--${column.field}`]: true,
        })}
        style=${styleMap({
          width: column?.display?.width,
          'min-width': column?.display?.minWidth,
          'max-width': column?.display?.maxWidth,
        })}
        aria-sort="${ifDefined(
          this.sortBy === column?.field ? (this.sortAscending ? 'ascending' : 'descending') : undefined
        )}"
        id=${cellId}
        tabindex=${!isHidden && this.currentFocusId === cellId ? '0' : '-1'}
        ?hidden=${isHidden}
      >
        ${this.loading && !column
          ? this.loadingTemplate()
          : html`
              <span class="data-grid__table-header__content data-grid__cell-content-wrapper">
                ${index === 0 && this.isTree ? expandAllExpander : ''}
                <span class="data-grid__cell-content">
                  <span class="data-grid__column__content"> ${column.sortable ? sortButton : column.content} </span>
                  <slot
                    name="${this.getUniqueCellId(column.field, this.headerRowId)}"
                    class="data-grid__table-header__slot"
                    @slotchange=${this.handleSlotChange}
                  ></slot>
                </span>
              </span>
            `}
      </th>
    `;
  };

  private cellTemplate = (column: Column, row: Row, cellIndex: number, level: number = 1) => {
    const checkbox = html`
      <${this.scope.tag('checkbox')}
        class="data-grid__selector data-grid__select-row data-grid__widget"
        ?checked="${row?.selected}"
        ?disabled=${row?.disabled}
        @he-change="${(event: MouseEvent) => this.handleSelectRow(row, event)}"
        tabindex="-1"
        hide-label
      >
        ${this.localize.term('select_row')}
      </${this.scope.tag('checkbox')}>
      ${this.select && this.bulkSelectOptions?.length ? html`<span class="data-grid__bulk-select__spacer"></span>` : ''}
    `;

    const radio = html`
      <${this.scope.tag('radio')}
        class="data-grid__selector data-grid__select-row data-grid__widget"
        ?checked="${row?.selected}"
        ?disabled=${row?.disabled}
        @keydown="${(event: KeyboardEvent) => event.key === ' ' && event.preventDefault()}"
        @he-selected="${(event: CustomEvent) => this.handleSelectRow(row, event)}"
        tabindex="-1"
        hide-label
      >
        ${this.localize.term('select_row')}
      </${this.scope.tag('radio')}>
    `;

    const selectionCellId = this.getHTMLCellId('selection', row?.id);
    const selectionCell = html`<td
      role="gridcell"
      class="data-grid__cell data-grid__cell--selection"
      id=${selectionCellId}
      tabindex=${this.currentFocusId === selectionCellId ? '0' : '-1'}
    >
      <span class="data-grid__cell-content-wrapper">
        ${this.loading ? html`${this.loadingTemplate()}` : html`${this.isMultiSelect ? checkbox : radio}`}
      </span>
    </td>`;

    const cellData = row?.cells[column?.field] || '';
    const cellClasses = classMap({
      'data-grid__cell-content': true,
      'data-grid__line-clamp': column?.display?.lineClamp ? true : false,
    });
    const cellContentStyles = styleMap({
      '-webkit-line-clamp': column?.display?.lineClamp?.toString(),
      'white-space': column?.display?.noWrap ? 'nowrap' : 'normal',
    });

    const expander = html`<span
      class="data-grid__expander"
      style=${styleMap({
        'margin-inline-start': cellIndex === 0 && level > 1 ? `${(level - 1) * 24}px` : undefined,
      })}
      aria-hidden="${ifDefined(this.hasChildren(row) ? undefined : 'true')}"
    >
      ${when(
        this.hasChildren(row),
        () =>
          html`
            <button
              class=${classMap({
                'data-grid__widget': true,
                'data-grid__expander__button': true,
                'data-grid__expander__button--expanded': !!(this.isTree && row.expanded),
              })}
              appearance="stealth"
              aria-expanded="${ifDefined(this.isTree ? row?.expanded === true : undefined)}"
              @click=${() => this.toggleRowExpand(row)}
              tabindex="-1"
            >
              <${this.scope.tag('icon')}
                name="ChevronDown"
                label="${this.localize.term(row?.expanded ? 'collapse_row' : 'expand_row')}"
              ></${this.scope.tag('icon')}>
            </button>
            <${this.scope.tag('progress-ring')}
              class="data-grid__expander__progress"
              indeterminate
            ></${this.scope.tag('progress-ring')}>
          `
      )}
    </span>`;

    const cellId = this.getHTMLCellId(column?.field, row?.id);

    const tooltipContent = column?.display?.lineClamp
      ? cellData ||
        [...this.querySelectorAll(`[slot="${this.getUniqueCellId(column?.field, row?.id)}"]`)]
          .map(x => x.textContent)
          .join(' ')
      : undefined;

    const cellContent = html`<span class="${cellClasses}" style="${cellContentStyles}">
      <slot name="${this.getUniqueCellId(column?.field, row?.id)}" @slotchange=${this.handleSlotChange}>
        ${cellData}
      </slot>
      ${tooltipContent
        ? html`
          <${this.scope.tag('tooltip')}
            anchor=${cellId}
            aria-hidden="true"
          >
            ${tooltipContent}
          </${this.scope.tag('tooltip')}>
        `
        : ''}
    </span>`;

    const tabIndex = this.currentFocusId === cellId ? '0' : '-1';
    const isHidden = !!(column?.display?.hideAt && column.display.hideAt >= (this.componentWidth as number));

    return cellIndex === 0
      ? html` ${this.select ? selectionCell : ''}
          <th
            scope="row"
            role="rowheader"
            class="data-grid__row-header"
            id="${cellId}"
            tabindex=${tabIndex}
            ?hidden=${isHidden}
          >
            <span class="data-grid__row-header__content data-grid__cell-content-wrapper">
              ${this.isTree && !this.loading ? expander : ''} ${this.loading ? this.loadingTemplate() : cellContent}
            </span>
          </th>`
      : html`
          <td role="gridcell" class="data-grid__cell" id="${cellId}" tabindex=${tabIndex} ?hidden=${isHidden}>
            <span class="data-grid__cell-content-wrapper">
              ${this.loading ? this.loadingTemplate() : cellContent}
            </span>
          </td>
        `;
  };

  private rowTemplate: (row: Row, index: number, length: number, visible?: boolean, level?: number) => TemplateResult =
    (row, index, length, visible = true, level = 1) => {
      const hasChildren = this.isTree && this.hasChildren(row);

      return html`
        <tr
          role="row"
          class=${classMap({
            'data-grid__row': true,
            'data-grid__row--hidden': !visible,
            'data-grid__row--has-children': hasChildren,
          })}
          id="${this.getHTMLRowId(row?.id)}"
          aria-selected="${ifDefined(this.select && !this.loading ? row.selected === true : undefined)}"
          aria-expanded="${ifDefined(hasChildren ? row?.expanded === true : undefined)}"
          aria-rowindex="${ifDefined(this.isTree && !this.lazy ? this.rows.indexOf(row) + 2 : undefined)}"
          aria-level="${ifDefined(this.isTree ? level : undefined)}"
          aria-posinset="${ifDefined(this.isTree ? index + 1 : undefined)}"
          aria-setsize="${ifDefined(this.isTree ? length : undefined)}"
          aria-live="${ifDefined(this.isTree && level > 1 ? 'polite' : undefined)}"
          tabindex=${this.currentFocusId === this.getHTMLRowId(row?.id) ? '0' : '-1'}
          @click=${this.handleRowClick}
        >
          ${this.columns.map((column, cellIndex) => this.cellTemplate(column, row, cellIndex, level))}
        </tr>
        ${when(hasChildren, () => {
          const childrenVisible = !!row?.expanded && visible;
          const childRows = this.getChildRows(row);
          return repeat(
            childRows,
            x => x.id,
            (x, i) => this.rowTemplate(x, i, childRows.length, childrenVisible, level + 1)
          );
        })}
      `;
    };
}

export default DataGrid;
