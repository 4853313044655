import './formdata-event-polyfill.js';
import type { Button } from '../components/button/button.js';
import type { ReactiveController, ReactiveControllerHost } from 'lit';

const reportValidityOverloads: WeakMap<HTMLFormElement, () => boolean> = new WeakMap();

export interface FormSubmitControllerOptions {
  /** A function that returns the form containing the form control. */
  form: (input: unknown) => HTMLFormElement | null;
  /** A function that returns the form control's name, which will be submitted with the form data. */
  name: (input: unknown) => string;
  /** A function that returns the form control's current value. */
  value: (input: unknown) => unknown | unknown[];
  /** A function that returns the form control's default value. */
  defaultValue: (input: unknown) => unknown | unknown[];
  /** A function that returns the form control's current disabled state. If disabled, the value won't be submitted. */
  disabled: (input: unknown) => boolean;
  /**
   * Events to listen for to determine that the user has interacted with the control. This is used to hide validation
   * messages until the user types in the control or submits the form. Remember that not all events bubble, so listening
   * for focus/blur won't be effective (in this case, use focusin/focusout instead).
   */
  interactionEvents: string[];
  /** A function that maps to the form control's checkValidity() function. */
  checkValidity: (input: unknown) => boolean;
  /** A function that returns the form control's current validation message. */
  getValidationMessage: (input: unknown) => string;
  /**
   * A function that maps to the form control's reportValidity() function. When the control is invalid, this will
   * prevent submission and trigger the browser's constraint violation warning.
   */
  reportValidity: (input: unknown) => boolean;
  /** A function that sets the form control's value. */
  setValue: (input: unknown, value: unknown) => void;
  /** Disables built-in validation. */
  hasCustomValidation: boolean;
}

export class FormSubmitController implements ReactiveController {
  host?: ReactiveControllerHost & Element;
  form?: HTMLFormElement | null;
  options: FormSubmitControllerOptions;
  hasInteraction = false;

  constructor(host: ReactiveControllerHost & Element, options?: Partial<FormSubmitControllerOptions>) {
    (this.host = host).addController(this);
    this.options = {
      form: (input: HTMLInputElement) => input.closest('form'),
      name: (input: HTMLInputElement) => input.name,
      value: (input: HTMLInputElement) => input.value,
      defaultValue: (input: HTMLInputElement) => input.defaultValue,
      disabled: (input: HTMLInputElement) => input.disabled,
      interactionEvents: ['focusout'],
      getValidationMessage: (input: HTMLInputElement) => input.validationMessage,
      checkValidity: (input: HTMLInputElement) => {
        return typeof input.checkValidity === 'function' ? input.checkValidity() : true;
      },
      reportValidity: (input: HTMLInputElement) => {
        // To show the browser's validation message, change these to input.reportValidity
        return typeof input.checkValidity === 'function' ? input.checkValidity() : true;
      },
      setValue: (input: HTMLInputElement, value: string) => (input.value = value),
      hasCustomValidation: false,
      ...options,
    };
    this.handleInteraction = this.handleInteraction.bind(this);
    this.resetInteraction = this.resetInteraction.bind(this);
    this.handleFormData = this.handleFormData.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.reportFormValidity = this.reportFormValidity.bind(this);
  }

  async hostConnected() {
    await this.host?.updateComplete;
    this.form = this.options.form(this.host);

    if (this.form) {
      this.form.addEventListener('formdata', this.handleFormData);
      this.form.addEventListener('submit', this.handleFormSubmit);
      this.form.addEventListener('reset', this.handleFormReset);

      // Overload the form's reportValidity() method so it looks at Harmony form controls
      if (!reportValidityOverloads.has(this.form)) {
        reportValidityOverloads.set(this.form, this.form.reportValidity);
        this.form.reportValidity = () => this.reportFormValidity();
      }
    }

    // Listen for interactions
    this.options.interactionEvents.forEach(event => {
      this.host!.shadowRoot?.addEventListener(event, this.handleInteraction);
    });
  }

  hostDisconnected() {
    if (this.form) {
      this.form.removeEventListener('formdata', this.handleFormData);
      this.form.removeEventListener('submit', this.handleFormSubmit);
      this.form.removeEventListener('reset', this.handleFormReset);

      // Remove the overload and restore the original method
      if (reportValidityOverloads.has(this.form)) {
        this.form.reportValidity = reportValidityOverloads.get(this.form)!;
        reportValidityOverloads.delete(this.form);
      }

      this.form = undefined;
    }

    // Cleanup interactions
    this.options.interactionEvents.forEach(event => {
      this.host!.shadowRoot?.removeEventListener(event, this.handleInteraction);
    });
  }

  hostUpdated() {
    if (this.options.hasCustomValidation) {
      return;
    }

    const isValid = this.options.checkValidity(this.host);
    const formControlContainer = this.host!.shadowRoot!.querySelector('.form-control');
    const errorTextContainer = this.host!.shadowRoot!.querySelector('[part~="form-control-error-text-message"]');

    if (isValid) {
      formControlContainer?.classList.remove('form-control--has-error');
      if (errorTextContainer) errorTextContainer.textContent = '';
    } else {
      formControlContainer?.classList.add('form-control--has-error');
      if (errorTextContainer) errorTextContainer.textContent = this.options.getValidationMessage(this.host);
    }
  }

  handleInteraction() {
    const formControlContainer = this.host!.shadowRoot!.querySelector('.form-control');
    if (formControlContainer) formControlContainer.classList.add('form-control--has-interaction');
    this.hasInteraction = true;
  }

  resetInteraction() {
    const formControlContainer = this.host!.shadowRoot!.querySelector('.form-control');
    if (formControlContainer) formControlContainer.classList.remove('form-control--has-interaction');
    this.hasInteraction = false;
  }

  handleFormData(event: FormDataEvent) {
    const disabled = this.options.disabled(this.host);
    const name = this.options.name(this.host);
    const value = this.options.value(this.host);

    if (!disabled && typeof name === 'string' && typeof value !== 'undefined') {
      if (Array.isArray(value)) {
        (value as unknown[]).forEach(val => {
          event.formData.append(name, (val as string | number | boolean).toString());
        });
      } else {
        event.formData.append(name, (value as string | number | boolean).toString());
      }
    }
  }

  handleFormSubmit(event: Event) {
    const disabled = this.options.disabled(this.host);
    const reportValidity = this.options.reportValidity;

    this.handleInteraction();

    if (this.form && !this.form.noValidate && !disabled && !reportValidity(this.host)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  handleFormReset() {
    this.options.setValue(this.host, this.options.defaultValue(this.host));
    this.resetInteraction();
  }

  reportFormValidity() {
    //
    // Harmony form controls work hard to act like regular form controls. They support the Constraint Validation API
    // and its associated methods such as setCustomValidity() and reportValidity(). However, the HTMLFormElement also
    // has a reportValidity() method that will trigger validation on all child controls. Since we're not yet using
    // ElementInternals, we need to overload this method so it looks for any element with the reportValidity() method.
    //
    // We preserve the original method in a WeakMap, but we don't call it from the overload because that would trigger
    // validations in an unexpected order. When the element disconnects, we revert to the original behavior. This won't
    // be necessary once we can use ElementInternals.
    //
    // Note that we're also honoring the form's novalidate attribute.
    //
    if (this.form && !this.form.noValidate) {
      // This seems sloppy, but checking all elements will cover native inputs, Harmony inputs, and other custom
      // elements that support the constraint validation API.
      const elements = this.form.querySelectorAll<HTMLInputElement>('*');

      for (const element of elements) {
        if (typeof element.reportValidity === 'function') {
          if (!element.reportValidity()) {
            return false;
          }
        }
      }
    }

    return true;
  }

  doAction(type: 'submit' | 'reset', invoker?: HTMLInputElement | Button) {
    if (this.form) {
      const button = document.createElement('button');
      button.type = type;
      button.style.position = 'absolute';
      button.style.width = '0';
      button.style.height = '0';
      button.style.clipPath = 'inset(50%)';
      button.style.overflow = 'hidden';
      button.style.whiteSpace = 'nowrap';

      // Pass form attributes through to the temporary button
      if (invoker) {
        ['formaction', 'formmethod', 'formnovalidate', 'formtarget'].forEach(attr => {
          if (invoker.hasAttribute(attr)) {
            button.setAttribute(attr, invoker.getAttribute(attr)!);
          }
        });
      }

      this.form.append(button);
      button.click();
      button.remove();
    }
  }

  /** Resets the form, restoring all the control to their default value. */
  reset(invoker?: HTMLInputElement | Button) {
    this.doAction('reset', invoker);
  }

  /** Submits the form, triggering validation and form data injection. */
  submit(invoker?: HTMLInputElement | Button) {
    // Calling form.submit() bypasses the submit event and constraint validation. To prevent this, we can inject a
    // native submit button into the form, "click" it, then remove it to simulate a standard form submission.
    this.doAction('submit', invoker);
  }
}
