import { html } from 'lit/static-html.js';
import { property, query } from 'lit/decorators.js';
import { HasSlotController } from '../../internal/slot.js';
import HarmonyElement from '../../base-components/base.js';
import componentStyles from '../../internal/styles/component.styles.js';
import visuallyHidden from '../visually-hidden/visually-hidden.js';
import button from '../button/button.js';
import { Component } from '../../utilities/decorators.js';
import styles from './layout.styles.js';

/**
 * 
 * Layout components are designed for wrapping everything between the `<body>` tags and are intended to provide a consistent experience for users.
 * 
 * @tag he-layout
 * @since 5.5
 * @status early
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/Harmony-Toolkit?node-id=200%3A0
 *
 * @dependency he-button
 * @dependency he-visually-hidden
 * 
 * @slot - The default slot where content will be placed. This is where any thing you would put in a `<main>` tag would go.
 *   This slot is wrapped a `<main>` tag and should not have another `<main>` tag or an element with `role="main"` placed inside of it.
 * @slot header - Top level bar for things like navigation, search, avatars, etc.
 *   This is typically where something like a "portal" or <nav> would go. If no header is slotted, no space will be accounted for.
 *   This slot is wrapped by a div and has no landmark roles.
 * @slot sub-header - Second level bar just under the header. Typically an `<he-breadcrumb-bar>` would go here.
 *   This slot is wrapped by a div and has no landmark roles.
 * @slot menu - A menu on the left-hand side of the page for things like `<he-task-menu>`.
 *   This slot is wrapped by a div and has no landmark roles.
 * @slot aside - An aside on the right hand side generally used for slotting in a `<he-pane-group>` with multiple `<push-pane>`s.
 *   This slot is wrapped by a div and has no landmark roles.
 * @slot panel - The panel slot is used for slotting in `<fly-in-panel>` elements that have a `contain` property on them
 * @slot dialog - The dialog slot is used for slotting in `<he-dialog>` elements so that they can have the highest z-index and overlay everything.
 *
 * @csspart base - The wrapping div around the entire layout.
 * @csspart header - The wrapping div around the header slot.
 * @csspart body - The wrapper div around the sub-header and content parts.
 * @csspart sub-header - The wrapper around the sub-header slot.
 * @csspart content - The wrapper around the menu, main, and aside parts.
 * @csspart main - The wrapper `<main>` around the default slot.
 * @csspart menu - The wrapper div around the menu slot.
 * @csspart aside - The wrapper div around the aside slot.
 * @csspart panel - The wrapper div around the panel slot.
 * @csspart dialog - The wrapper div around the dialog slot.

 * @cssproperty [--layout-height=calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))] - The max size of the layout container.
 * @cssproperty [--layout-main-padding=var(--he-spacing-x-small) clamp(var(--he-spacing-medium), 4.166667vw, var(--he-spacing-2x-large))] - Padding for main content area, has a fluid scale with min of 16px and max of 32px.
 * 
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('layout', [button, visuallyHidden])
export class Layout extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** Don't include "Skip to main" link. */
  @property({ attribute: 'disable-skip-to-main' }) disableSkipToMain: boolean = false;

  @query('main')
  private mainElement: HTMLElement | null;

  private readonly hasSlotController = new HasSlotController(this, 'header', 'sub-header');

  handleHeaderSlotChange() {
    const hasHeaderSlot = this.hasSlotController.test('header');

    const attribute = 'has-header';

    if (hasHeaderSlot) {
      this.setAttribute(attribute, '');
    } else {
      this.removeAttribute(attribute);
    }
  }

  handleSubHeaderSlotChange() {
    const hasSubHeaderSlot = this.hasSlotController.test('sub-header');

    const attribute = 'has-sub-header';

    if (hasSubHeaderSlot) {
      this.setAttribute(attribute, '');
    } else {
      this.removeAttribute(attribute);
    }
  }

  render() {
    return html`
      <div class="layout" part="base">
      	<${this.scope.tag('visually-hidden')}
      		class="layout__skip-to-main"
      		part="skip-to-main"
      		?hidden=${this.disableSkipToMain}
    		>
      		<${this.scope.tag('button')}
      			appearance="link"
      			part="skip-to-main-anchor"
      			exportparts="
							control:skip-to-main-anchor__control,
      			"
      			@click=${(e: Event) => {
              e.preventDefault();
              this.mainElement?.focus();
            }}
      		>
      			${this.localize.term('skip_to_main')}
      		</${this.scope.tag('button')}>
      	</${this.scope.tag('visually-hidden')}>

        <div class="layout__header" part="header">
          <slot name="header" @slotchange=${this.handleHeaderSlotChange}></slot>
        </div>

        <div class="layout__body" part="body">
          <div class="layout__sub-header" part="sub-header">
            <slot name="sub-header" @slotchange=${this.handleSubHeaderSlotChange}></slot>
          </div>

          <div class="layout__content" part="content">
            <div class="layout__menu" part="menu">
              <slot name="menu"></slot>
            </div>

            ${'' /* <main> Needs a tabindex so we can call .focus() on it. */}
            <main tabindex="-1" class="layout__main" part="main" id="he-layout-main">
              <slot></slot>
            </main>

            <div class="layout__aside" part="aside">
              <slot name="aside"></slot>
            </div>
          </div>

          <div class="layout__panel" part="panel">
            <slot name="panel"></slot>
          </div>

          <div class="layout__dialog" part="dialog">
            <slot name="dialog"></slot>
          </div>
        </div>
      </div>
    `;
  }
}

export default Layout;
