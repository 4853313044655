import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    --height: 4px;
    --track-color: var(--he-color-neutral-200);
    --indicator-color: var(--he-color-primary-600);
    --label-color: var(--he-color-neutral-0);

    display: block;
  }

  .progress {
    position: relative;
    background-color: var(--track-color);
    height: var(--height);
    border-radius: var(--he-border-radius-pill);
    overflow: hidden;
  }

  .progress__indicator {
    height: 100%;
    font-family: var(--he-font-sans-serif);
    font-size: 12px;
    font-weight: var(--he-font-weight-normal);
    background-color: var(--indicator-color);
    color: var(--label-color);
    text-align: center;
    line-height: var(--height);
    white-space: nowrap;
    overflow: hidden;
    transition: 400ms width, 400ms background-color;
    user-select: none;
  }

  /* Indeterminate */
  .progress--indeterminate .progress__indicator {
    position: absolute;
    animation: indeterminate 2.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
  }

  @keyframes indeterminate {
    0% {
      inset-inline-start: -50%;
      width: 50%;
    }
    75%,
    100% {
      inset-inline-start: 100%;
      width: 50%;
    }
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .progress {
      forced-color-adjust: none;
      background-color: ${SystemColors.Field};
      outline: var(--he-form-control-border-width) solid ${SystemColors.FieldText};
    }

    .progress__indicator {
      background-color: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }
  }
`;
