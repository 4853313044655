import { StaticValue, unsafeStatic } from 'lit/static-html.js';
import HarmonyElement from '../base-components/base.js';
import { trackComponent } from '../internal/track-usage.js';
import partnerCenterTheme from '../themes/partner-center/index.js';
import { minifyCssString } from './minify.js';
import type { CSSResult } from 'lit';

export type HarmonyEnablerGlobalScope = {
  /** This map identifies a component by its base name (to allow easier importing in the docs) */
  baseNameMap: Map<string, typeof HarmonyElement>;
  /** This map identifies the appropriate scope from a tag name */
  tagMap: Map<string, HarmonyEnablerScope>;
};

// In case it doesn't exist...
if (!window.HarmonyEnablers) {
  window.HarmonyEnablers = {};
}

// We use a global object to track scope collisions since users may be using different origins and versions
window.HarmonyEnablers.scope ??= {
  baseNameMap: new Map<string, typeof HarmonyElement>(),
  tagMap: new Map<string, HarmonyEnablerScope>(),
};
const { baseNameMap, tagMap } = window.HarmonyEnablers.scope;

// Default to the latest CDN version so users don't have to bother setting it
export const defaultBasePath = 'https://harmonyenablers.microsoft.com/npm/@harmony/enablers/6.52.0';
const defaultPrefix = 'he';

export type ScopeOptions = {
  basePath?: string;
  components?: Array<typeof HarmonyElement>;
  /** @deprecated - This will be removed in the next major release. Use `suffix` instead. */
  prefix?: string;
  suffix?: string;
  reactInstance?: any;
  /** @deprecated Please use the `theme` component to scope styles  */
  rootElement?: Element;
  /** @deprecated Please use the `theme` component to scope styles  */
  styles?: CSSResult[];
  /** @deprecated Please use the `theme` component to scope styles  */
  theme?: CSSResult;
};

/**
 * Creates a custom Harmony Enabler scope, optionally applying themes and registering components. Once a scope is
 * created, it cannot be destroyed without a page reload because custom elements cannot be unregistered.
 *
 * @example
 * import { theme } from '@harmony/enablers/themes/partner-center';
 * import cssUtilities from '@harmony/enablers/utilities/css';
 * import { button } from '@harmony/enablers/components/button/button';
 * import { card } from '@harmony/enablers/components/card/card';
 *
 * const scope = createScope({
 *  // (@deprecated) Please use the `theme` component to scope styles
 *  rootElement: myElement,
 *  suffix: 'support',
 *  // (@deprecated) Please use the `theme` component to scope styles
 *  styles: [theme, cssUtilities]
 *  components: [button, card],
 * });
 */
export function createScope(options?: ScopeOptions) {
  scope = new HarmonyEnablerScope(options);
  return scope;
}

/**
 * Returns a custom element's scope using an element instance or its tag name.
 *
 * @example
 * const scope = getScope(el);
 * const tagName = scope.tagName('button'); // outputs "he-button", "prefix_he-button", or "he-button_suffix"
 */
export function getScope(elementOrTagName: Element | string): HarmonyEnablerScope {
  const tagName = typeof elementOrTagName === 'string' ? elementOrTagName : elementOrTagName.tagName.toLowerCase();
  return tagMap.get(tagName)!;
}

export class HarmonyEnablerScope {
  protected reactInstance: any;

  private basePath: string;
  /** @deprecated - This will be removed in the next major release. Use `suffix` instead. */
  private prefix: string;
  private suffix: string;
  /** @deprecated Please use the `theme` component to scope styles  */
  private rootElement: Element;
  /** @deprecated Please use the `theme` component to scope styles  */
  private styles: CSSResult[];
  private registeredComponents = new Set<typeof HarmonyElement>();
  private themeSyncMutationObserver: MutationObserver;
  /** @deprecated Please use the `theme` component to scope styles  */
  private stylesheet: HTMLStyleElement;

  constructor(options?: ScopeOptions) {
    this.updateOptions(options);
  }

  public updateOptions(options?: ScopeOptions) {
    const previousRootElement = this.rootElement;
    const previousScopeName = this.getScopeName();
    this.initProperties(options);
    this.setScopeAttribute(previousRootElement, previousScopeName);
    this.applyStyles(this.styles);
    this.setupThemeSyncFromDocument();
    // Attempt to reregister any previously registered components - to handle the settings possibly having been changed
    this.registerComponent(...this.registeredComponents);
    // Registers any newly added components
    this.registerComponent(...(options?.components ?? []));
  }

  protected initProperties(options?: ScopeOptions) {
    this.rootElement = options?.rootElement ?? this.rootElement ?? document.documentElement;
    this.basePath = options?.basePath ?? this.basePath ?? defaultBasePath;
    this.prefix = options?.prefix ?? this.prefix ?? defaultPrefix;
    this.suffix = options?.suffix ?? this.suffix ?? '';
    this.reactInstance = options?.reactInstance ?? this.reactInstance;
    this.styles = options?.styles ??
      (options?.theme !== undefined ? [options.theme] : undefined) ??
      this.styles ?? [partnerCenterTheme];
    this.setTagScope();
  }

  protected setTagScope() {
    // Prefixes must begin with a letter and contain only letters and numbers thereafter. This is our rule to enforce
    // both custom element naming rules and a "prefix_he-basename" convention. The default prefix "he" is also allowed.
    if (!this.isValidTagScope(this.prefix) || !this.isValidTagScope(this.suffix)) {
      throw new Error(
        `Cannot create a Harmony Enabler scope with the "${this.getScopeName()}" prefix or suffix. ` +
          'Prefixes and suffixes must contain only letters numbers, hyphens, and underscores.'
      );
    }

    // If we're not using the default prefix, enforce the "prefix_he-basename" convention.
    if (this.prefix !== defaultPrefix && !this.prefix.endsWith(`_${defaultPrefix}`)) {
      this.prefix += `_${defaultPrefix}`;
    }

    if (this.suffix !== '' && !this.suffix.startsWith('_')) {
      this.suffix = `_${this.suffix}`;
    }
  }

  private hasScopedTagName() {
    return this.getScopeName() !== '';
  }

  private getScopeName() {
    const scope = this.suffix?.replace('_', '') || this.prefix?.replace(new RegExp(`_?${defaultPrefix}`), '') || '';
    return scope;
  }

  private setScopeAttribute(previousRoot: Element | undefined, previousScopeName: string) {
    previousRoot?.removeAttribute(`scope-${previousScopeName}`);
    // Add scope attribute to root element
    if (this.hasScopedTagName()) {
      this.rootElement.setAttribute(`scope-${this.getScopeName()}`, '');
    }
  }

  private setupThemeSyncFromDocument() {
    // If the document doesn't yet have a light/dark theme set, initialize it to light theme
    if (
      !document.documentElement.classList.contains('he-theme-light') &&
      !document.documentElement.classList.contains('he-theme-dark')
    ) {
      document.documentElement.classList.add('he-theme-light');
    }
    // If the root element isn't <html>, watch for theme class changes and sync them. This ensures feature developers
    // don't have to do anything special to support dark mode aside from applying the theme to their feature.
    if (this.rootElement !== document.documentElement) {
      const syncTheme = () => {
        const isDark = document.documentElement.classList.contains('he-theme-dark');
        this.rootElement.classList.toggle('he-theme-dark', isDark);
        this.rootElement.classList.toggle('he-theme-light', !isDark);
      };

      this.themeSyncMutationObserver?.disconnect();
      const observer = new MutationObserver(() => syncTheme());
      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['class'],
      });
      this.themeSyncMutationObserver = observer;

      syncTheme();
    }
  }

  /** @deprecated Applies a theme to the root element. */
  applyTheme(theme: CSSResult) {
    this.applyStyles([theme]);
  }

  /** @deprecated - Applies styles to the root element. Use the `theme` component to apply scoped styles instead. */
  applyStyles(styles: CSSResult[], rootElement = this.rootElement) {
    this.stylesheet?.remove();
    const targetElement = rootElement === document.documentElement ? document.head : this.rootElement;
    const style = document.createElement('style');
    style.textContent = this.getScopedStyles(styles);
    targetElement.prepend(style);
    this.stylesheet = style;
  }

  /** Registers a component in the current scope. */
  registerComponent(...components: Array<typeof HarmonyElement>) {
    components.forEach(component => {
      const baseName = component.baseName;
      const tagName = `${this.prefix}-${baseName}${this.suffix}`;

      // Keep track of components we've attempted to register - if the scope options are updated later on, we need to re-register those components
      this.registeredComponents.add(component);
      trackComponent(tagName, component);

      // If the component has already been registered, we're done here
      if (tagMap.has(tagName)) {
        return;
      }

      if (!baseName) {
        console.error(`
					No basename set for: ${tagName}.
					The component does not have: @Component('<name>') above its class definition.`);
        return;
      }

      // Register the component
      tagMap.set(tagName, this);

      baseNameMap.set(baseName, component);

      if (!customElements.get(tagName)) customElements.define(tagName, class extends component {});
    });
  }

  /** Gets a registered component. */
  getComponent(baseName: string) {
    return baseNameMap.get(baseName);
  }

  /** Returns the base name of any scoped element. */
  getBaseName(elementOrTagName: Element | string) {
    const tagName = elementOrTagName instanceof Element ? elementOrTagName.tagName.toLowerCase() : elementOrTagName;
    return tagName
      .replace(new RegExp(`^((.*)_)?${defaultPrefix}-`, 'i'), '') // remove prefix
      .replace(/_.*/, ''); // remove suffix
  }

  /** Generates a React-wrapped component. A "reactInstance" must be set before calling this method. */
  forReact<T extends typeof HarmonyElement>(_component: T) {
    throw new Error(
      "Please use the '@harmony/enablers/react' entrypoint if you are going to be using the React wrappers."
    );
  }

  reactInstanceMustBeSet() {
    if (!this.reactInstance) {
      throw new Error(
        'To use React-wrapped components, you must provide an instance of React to the "reactInstance" property when you create the scope.'
      );
    }
  }

  /** Sets the React instance. Useful if you can't set it during instantiation. */
  setReactInstance(reactInstance: any) {
    this.reactInstance = reactInstance;
  }

  /** Returns the library's base path. If a path is provided, it will be joined to form a full path to the asset. */
  makePath(path?: string) {
    const basePath = this.basePath.replace(/\/$/, '');
    return path ? basePath + '/' + path : basePath;
  }

  /**
   * Converts a base name such as "button" to a scoped tag name such as "he-button" or "prefix_he-button". Useful for
   * generating selectors for scoped tags for use in JavaScript and CSS. Avoid using this function inside templates.
   * Instead, use scope.tag() to generate a static Lit template tag.
   */
  tagName(baseName: string): string {
    if (baseName === null) {
      throw Error('No base name given');
    }

    if (baseName.startsWith(`${defaultPrefix}-`)) {
      console.error(
        `Invalid base name: "${baseName}". Avoid using the "${defaultPrefix}-" prefix when calling scope.tag() or scope.tagName().`
      );
      baseName = baseName.replace(new RegExp(`^${defaultPrefix}-`), '');
    }

    return `${this.prefix}-${baseName}${this.suffix}`;
  }

  /**
   * Converts a base name such as "button" to a scoped static template tag that can be used inside templates. Use this
   * instead of tagName() when composing templates, because Lit doesn't allow strings to be interpolated as tag names.
   */
  tag(baseName: string): StaticValue {
    return unsafeStatic(this.tagName(baseName)) as StaticValue;
  }

  /** If components are scoped, it returns a scoped version of the selected theme. */
  private getScopedStyles(styles: CSSResult[]): string {
    return styles
      .map(x => {
        /** return if there are not style to scope */
        if (!x) {
          return '';
        }

        const minifiedStyles = minifyCssString(x.toString());

        /** return the default styles if no scope is being used */
        if (!this.hasScopedTagName()) {
          return minifiedStyles;
        }
        const scope = this.getScopeName();

        /** scope component styles */
        return (
          minifiedStyles
            .toString()
            .replace(/:root,/g, '')
            .replace(/.he-theme-light/g, `[scope-${scope}].he-theme-light`)
            .replace(/.he-theme-dark/g, `[scope-${scope}].he-theme-dark`) || ''
        );
      })
      .join('');
  }

  private isValidTagScope = (tagScope: string) => tagScope.length === 0 || /^([a-z0-9_-]+)$/.test(tagScope);
}

/**
 * @deprecated - This will be removed in the next major release.
 * Partner Center now has its own mechanism to check the user's preferred color scheme and apply the appropriate styles.
 *
 * Partner Center has a pattern of applying dark mode using <body class="darkMode"> instead of the preferred syntax
 * <html class="he-theme-dark">. For compatibility reasons, we sync their class to ours in that environment.
 */
function isPartnerCenter(): boolean {
  const origin = window.location.origin.toLowerCase();
  return (
    origin.includes('partner.microsoft-ppe.com') ||
    origin.includes('partner.microsoft-int.com') ||
    origin.includes('partner.microsoft-tst.com') ||
    origin.includes('partner.microsoft.com') ||
    origin.includes('partner.partnercenter.microsoftonline.cn')
  );
}

if (isPartnerCenter()) {
  const syncTheme = () => {
    const isDark = document.body.classList.contains('darkMode');
    document.documentElement.classList.toggle('he-theme-dark', isDark);
    document.documentElement.classList.toggle('he-theme-light', !isDark);
  };

  const observer = new MutationObserver(() => syncTheme());
  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['class'],
  });

  // Initial sync
  if (document.readyState === 'complete') {
    syncTheme();
  } else {
    window.addEventListener('DOMContentLoaded', syncTheme);
  }
}

export let scope = new HarmonyEnablerScope();
