import { property, query, state } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { PropertyValueMap } from 'lit';
import { Dropdown } from '../dropdown/dropdown.js';
import icon from '../icon/icon.js';
import dropdown from '../dropdown/dropdown.js';
import menu from '../menu/menu.js';
import menuItem from '../menu-item/menu-item.js';
import HarmonyElement from '../../base-components/base.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './pagination.styles.js';

/**
 *
 * Pagination is a component that allows users to navigate through a series of pages. This is designed to be flexible and used independent of any other component. This component can be integrated with the Data Grid or used with a standard HTML table.
 *
 * @tag he-pagination
 * @since 4.3
 * @status stable
 * @design approved
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=15%3A32&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-icon
 * @dependency he-dropdown
 * @dependency he-menu
 * @dependency he-menu-item
 *
 * @csspart pagination__button - CSS part for styling all buttons in the component.
 * @csspart pagination__button--current - CSS part for styling the button of the currently selected page.
 *
 * @cssproperty [--current-page-border-color=var(--he-color-primary-500)] - The border color of the button for the currently selected page.
 * @cssproperty [--button-hover-background-color=var(--he-color-primary-500)] - The hover background color.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event {number} he-page-change - Emitted when the page number changes.
 * @event {number} he-previous-page - Emitted when the "Previous" button is clicked.
 * @event {number} he-next-page - Emitted when the "Next" button is clicked.
 * @event {number} he-first-page - Emitted when the "First" button is clicked - non-numeric.
 * @event {number} he-last-page - Emitted when the "Last" button is clicked - non-numeric.
 */
@Component('pagination', [icon, dropdown, menu, menuItem])
export class Pagination extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHePageChange: new CustomEvent<number>('he-page-change'),
    onHePreviousPage: new CustomEvent<number>('he-previous-page'),
    onHeNextPage: new CustomEvent<number>('he-next-page'),
    onHeFirstPage: new CustomEvent<number>('he-first-page'),
    onHeLastPage: new CustomEvent<number>('he-last-page'),
  };

  private resizeObserver = new ResizeObserver(() => this.handleResize());
  private lastPageItem?: HTMLElement;

  /** The selected page number. */
  @property({ type: Number, reflect: true })
  public value: number = 1;

  /** The total number of pages. */
  @property({ attribute: 'page-count', type: Number, reflect: true })
  public pageCount: number = 1;

  /** Indicates whether or not page numbers should be displayed. */
  @property({ attribute: 'non-numeric', type: Boolean, reflect: true })
  public nonNumeric: boolean = false;

  /** Indicates if the user is on the first page (non-numeric controls). */
  @property({ attribute: 'first-page', type: Boolean, reflect: true })
  public firstPage: boolean = false;

  /** Indicates if the user is on the last page (non-numeric controls). */
  @property({ attribute: 'last-page', type: Boolean, reflect: true })
  public lastPage: boolean = false;

  /** Enable this option to prevent the calendar from being clipped when the component is placed inside a container with `overflow: auto|hidden|scroll`. */
  @property({ type: Boolean, attribute: 'fixed-placement', reflect: true })
  public fixedPlacement = false;

  /** This determines the default direction of the overflow dropdown when space is not available above or below the component. */
  @property({ attribute: 'overflow-position', reflect: true })
  public overflowPosition?: 'bottom' | 'top';

  @state()
  private displaySize: number = 5;

  @state()
  private isMobile: boolean = false;

  @query('.pagination__list')
  private base: HTMLElement;

  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver.observe(this);
  }

  disconnectedCallback() {
    this.resizeObserver.unobserve(this);
    this.resizeObserver.disconnect();
    super.disconnectedCallback();
  }

  protected willUpdate(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.willUpdate(changedProperties);
    if (changedProperties.has('pageCount') || changedProperties.has('nonNumeric') || changedProperties.has('value')) {
      this.firstPage = this.nonNumeric ? false : this.value === 1;
      this.lastPage = this.nonNumeric ? false : this.value === this.pageCount;
    }
  }

  private getLowerCollapsedPages(): number[] {
    const result = [];
    const max =
      this.value === this.pageCount && this.displaySize === 1 ? this.pageCount - 1 : this.getMiddleDisplayedPages()[0];
    for (let index = 2; index < max; index++) {
      result.push(index);
    }

    return result;
  }

  private getMiddleDisplayedPages(): number[] {
    const min = this.getMiddleMinValue();
    const max = this.getMiddleMaxValue();
    const result = [];

    if (this.isMobile) {
      return [this.value];
    }

    if ((this.displaySize === 1 && this.value === 1) || (this.displaySize === 1 && this.value === this.pageCount)) {
      return [];
    }

    if ((this.displaySize === 1 && this.value > 1) || (this.displaySize === 1 && this.value < this.pageCount)) {
      return [this.value];
    }

    for (let index = min; index < max && index < this.pageCount; index++) {
      result.push(index);
    }

    return result;
  }

  private getUpperCollapsedPages(): number[] {
    const result = [];
    const min =
      this.value === 1 && this.displaySize === 1
        ? 2
        : this.value < this.displaySize
        ? this.displaySize + 1
        : this.getMiddleDisplayedPages()[this.displaySize - 1] + 1;

    for (
      let index = min;
      index < this.pageCount && (this.value < this.displaySize || this.value < this.pageCount - this.displaySize + 1);
      index++
    ) {
      result.push(index);
    }

    return result;
  }

  private getDisplayBuffer() {
    return Math.floor(this.displaySize / 2);
  }

  private handlePageNumberClick(pageNumber: number) {
    if (this.value === pageNumber) {
      return;
    }

    this.value = pageNumber;
    this.shadowRoot?.querySelectorAll<Dropdown>('.pagination__dropdown').forEach(x => x.hide());
    this.emitPageChange();
    this.updateComplete.then(() => {
      this.shadowRoot?.querySelector<HTMLButtonElement>('[aria-current="page"]')?.focus();
    });
  }

  private handlePrevPageClick() {
    if ((!this.nonNumeric && 1 > this.value - 1) || this.firstPage) {
      return;
    }
    this.value--;
    this.emitPageChange();
    this.emit('he-previous-page', { detail: this.getEmittedValue() });
  }

  private handleNextPageClick() {
    if ((!this.nonNumeric && this.pageCount < this.value + 1) || this.lastPage) {
      return;
    }
    this.value++;
    this.emitPageChange();
    this.emit('he-next-page', { detail: this.getEmittedValue() });
  }

  private handleFirstPageClick() {
    if (this.firstPage) {
      return;
    }
    this.firstPage = true;
    this.lastPage = false;
    this.emit('he-first-page');
  }

  private handleLastPageClick() {
    if (this.lastPage) {
      return;
    }
    this.firstPage = false;
    this.lastPage = true;
    this.emit('he-last-page');
  }

  private handleResize() {
    if (this.nonNumeric) {
      return;
    }

    if (this.clientWidth < this.base.clientWidth && this.displaySize > 1) {
      this.displaySize--;
    }

    const controlWidth = this.lastPageItem?.clientWidth ?? 48;
    const newBuffer = Math.floor((this.displaySize + 1) / 2);
    const changeSize =
      newBuffer > 1 ? this.base.clientWidth + controlWidth * newBuffer : this.base.clientWidth + controlWidth * 2;
    if (this.clientWidth > changeSize && this.displaySize < 5) {
      this.displaySize++;
    }

    setTimeout(() => (this.isMobile = this.base.clientWidth > this.clientWidth || this.clientWidth < 400));
  }

  private emitPageChange() {
    this.emit('he-page-change', { detail: this.getEmittedValue() });
  }

  private getMiddleMinValue() {
    return this.pageCount <= this.displaySize + 1 || this.value < this.displaySize
      ? 2
      : this.value > this.pageCount - this.displaySize
      ? this.pageCount - this.displaySize + 1
      : this.value - this.getDisplayBuffer();
  }

  private getMiddleMaxValue() {
    return (
      (this.value < this.displaySize
        ? this.displaySize
        : this.value > this.pageCount - this.displaySize
        ? this.pageCount
        : this.value + this.getDisplayBuffer()) + 1
    );
  }

  private getEmittedValue() {
    return this.nonNumeric ? undefined : this.value;
  }

  private getPaginationControls() {
    if (this.nonNumeric) {
      return this.nonNumericPagination();
    }

    if (this.isMobile) {
      return this.mobilePagination();
    }

    return this.numericPagination();
  }

  render() {
    return html`
      <nav
        aria-label="${this.localize.term('pagination')}"
        class=${classMap({
          pagination: true,
          [`pagination--${this.dir}`]: true,
        })}
      >
        ${this.getPaginationControls()}
      </nav>
      <!-- Added to announce page changes to screen readers -->
      <span class="visually-hidden" aria-live="polite">${this.localize.term('page', [this.value])}</span>
    `;
  }

  private previousPage = () => html`
  <li class="pagination__item pagination__arrow">
    <button
      class="pagination__button pagination__previous-page ${
        (!this.nonNumeric && this.value <= 1) || this.firstPage ? 'pagination__button--disabled' : ''
      }"
      part="pagination__button"
      aria-disabled="${(!this.nonNumeric && this.value <= 1) || this.firstPage}"
      @click=${() => this.handlePrevPageClick()}
    >
      <${this.scope.tag('icon')} 
        name="chevronleft" 
        class="pagination__prev-icon pagination__icon"
      ></${this.scope.tag('icon')}>
      <span class="pagination__link-text ${this.isMobile ? 'visually-hidden' : ''}">
        ${this.localize.term('previous')}
      </span>
    </button>
  </li>
  `;

  private nextPage = () => html`
  <li class="pagination__item pagination__arrow">
    <button
      class="pagination__button pagination__next-page ${
        (!this.nonNumeric && this.value >= this.pageCount) || this.lastPage ? 'pagination__button--disabled' : ''
      }"
      part="pagination__button"
      aria-disabled="${(!this.nonNumeric && this.value >= this.pageCount) || this.lastPage}"
      @click=${() => this.handleNextPageClick()}
    >
      <span class="pagination__link-text ${this.isMobile ? 'visually-hidden' : ''}">
        ${this.localize.term('next')}
      </span>
      <${this.scope.tag('icon')} 
        name="chevronright" 
        class="pagination__next-icon pagination__icon"
      ></${this.scope.tag('icon')}>
    </button>
  </li>
  `;

  private numericPagination = () => {
    const dropdownDirection = this.overflowPosition === 'top' ? 'top-start' : 'bottom-start';

    const firstPage = html`
      <li class="pagination__item">
        <button
          class="pagination__button ${this.value === 1 ? 'pagination__button--current' : ''}"
          part="pagination__button ${this.value === 1 ? 'pagination__button--current' : ''}"
          aria-label="${this.localize.term('page', [1])}"
          aria-current="${ifDefined(this.value === 1 ? 'page' : undefined)}"
          @click="${() => this.handlePageNumberClick(1)}"
        >
          <span aria-hidden="true">1</span>
        </button>
      </li>
    `;

    const lowerOverflow = html`
    <li class="pagination__item ${!this.getLowerCollapsedPages().length ? 'pagination__item--hidden' : ''}">
        <${this.scope.tag('dropdown')} 
          class="pagination__dropdown" 
          position=${dropdownDirection} 
          ?fixed-placement=${this.fixedPlacement}
        >
          <button 
            slot="trigger" 
            class="pagination__button" 
            part="pagination__button" 
            aria-label="${this.localize.term('more_options')}"
          >
            <span aria-hidden="true">...</span>
          </button>
          <${this.scope.tag('menu')} class="pagination__dropdown__menu">
            ${this.getLowerCollapsedPages().map(
              x =>
                html`<${this.scope.tag('menu-item')}
                  @click="${() => this.handlePageNumberClick(x)}"
                >
                  ${x}
                </${this.scope.tag('menu-item')}>`
            )}
          </${this.scope.tag('menu')}>
        </${this.scope.tag('dropdown')}>
      </li>
  `;

    const middlePage = (page: number) => html`
      <li class="pagination__item">
        <button
          class="pagination__button ${this.value === page ? 'pagination__button--current' : ''}"
          part="pagination__button ${this.value === page ? 'pagination__button--current' : ''}"
          aria-label="${this.localize.term('page', [page])}"
          aria-current="${ifDefined(this.value === page ? 'page' : undefined)}"
          @click="${() => this.handlePageNumberClick(page)}"
        >
          <span aria-hidden="true">${page}</span>
        </button>
      </li>
    `;

    const upperOverflow = html`
    <li class="pagination__item ${!this.getUpperCollapsedPages().length ? 'pagination__item--hidden' : ''}">
      <${this.scope.tag('dropdown')} 
        class="pagination__dropdown" 
        position=${dropdownDirection} 
        ?fixed-placement=${this.fixedPlacement}
      >
        <button 
          slot="trigger" 
          class="pagination__button" 
          part="pagination__button" 
          aria-label="${this.localize.term('more_options')}"
        >
          <span aria-hidden="true">...</span>
        </button>
        <${this.scope.tag('menu')} class="pagination__dropdown__menu">
          ${this.getUpperCollapsedPages().map(
            x =>
              html`<${this.scope.tag('menu-item')}
                @click="${() => this.handlePageNumberClick(x)}"
              >
                ${x}
              </${this.scope.tag('menu-item')}>`
          )}
        </${this.scope.tag('menu')}>
      </${this.scope.tag('dropdown')}>
    </li>
  `;

    const lastPage = html`
      <li class="pagination__item">
        <button
          class="pagination__button ${this.value === this.pageCount ? 'pagination__button--current' : ''}"
          part="pagination__button ${this.value === this.pageCount ? 'pagination__button--current' : ''}"
          aria-label="${this.localize.term('page', [this.pageCount])}"
          aria-current="${ifDefined(this.value === this.pageCount ? 'page' : undefined)}"
          @click="${() => this.handlePageNumberClick(this.pageCount)}"
        >
          <span aria-hidden="true">${this.pageCount}</span>
        </button>
      </li>
    `;

    return html`
      <ul class="pagination__list">
        ${this.previousPage()} ${firstPage} ${lowerOverflow} ${this.getMiddleDisplayedPages().map(x => middlePage(x))}
        ${upperOverflow} ${this.pageCount > 1 ? lastPage : ''} ${this.nextPage()}
      </ul>
    `;
  };

  private nonNumericPagination = () => {
    const firstPage = html`
    <li>
      <button
        class="pagination__button pagination__first-page ${this.firstPage ? 'pagination__button--disabled' : ''}"
        part="pagination__button"
        aria-disabled="${this.firstPage}"
        @click=${() => this.handleFirstPageClick()}>
          <${this.scope.tag(
            'icon'
          )} name="doublechevronup" class="pagination__first-icon pagination__icon"></${this.scope.tag('icon')}>
          <span class="pagination__link-text">${this.localize.term('first')}</span>
      </button>
    </li>
  `;

    const lastPage = html`
    <li>
      <button
        class="pagination__button pagination__last-page ${this.lastPage ? 'pagination__button--disabled' : ''}"
        part="pagination__button"
        aria-disabled="${this.lastPage}"
        @click=${() => this.handleLastPageClick()}>
          <span class="pagination__link-text">${this.localize.term('last')}</span>
          <${this.scope.tag('icon')} 
            name="doublechevronup" 
            class="pagination__last-icon pagination__icon"
          >
          </${this.scope.tag('icon')}>
      </button>
    </li>
  `;

    return html`
      <ul class="pagination__list">
        ${firstPage} ${this.previousPage()}
        <li class="pagination__spacer" aria-hidden="true"></li>
        ${this.nextPage()} ${lastPage}
      </ul>
    `;
  };

  private mobilePagination = () => {
    const firstPage = html`
    <li>
      <button
        class="pagination__button pagination__first-page ${this.firstPage ? 'pagination__button--disabled' : ''}"
        part="pagination__button"
        aria-disabled="${this.firstPage}"
        @click="${() => this.handlePageNumberClick(1)}">
          <${this.scope.tag('icon')} 
            name="doublechevronup" 
            class="pagination__first-icon pagination__icon">
          </${this.scope.tag('icon')}>
          <span class="pagination__link-text visually-hidden">${this.localize.term('first')}</span>
      </button>
    </li>
  `;

    const lastPage = html`
    <li>
      <button
        class="pagination__button pagination__last-page ${this.lastPage ? 'pagination__button--disabled' : ''}"
        part="pagination__button"
        aria-disabled="${this.lastPage}"
        @click="${() => this.handlePageNumberClick(this.pageCount)}">
          <span class="pagination__link-text visually-hidden">${this.localize.term('last')}</span>
          <${this.scope.tag('icon')} 
            name="doublechevronup" 
            class="pagination__last-icon pagination__icon"
          >
          </${this.scope.tag('icon')}>
      </button>
    </li>
  `;

    return html`
      <ul class="pagination__list">
        ${firstPage} ${this.previousPage()}
        <li>
          <span class="pagination__button">${this.value}</span>
        </li>
        ${this.nextPage()} ${lastPage}
      </ul>
    `;
  };
}

export default Pagination;
