import { httpRequestRetryStore } from '../../services/http-request-store';
import { HttpFunctions } from '../../services/http-service';
import { ConnectorPromiseStore } from '../../services/types/window-connectors';
import { HarmonyConnectorError } from '../connector-error';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { trackError } from '../../services/telemetry-service/library-telemetry-service';
import { localeConnector } from '../locale-connector/locale-connector';
import { PortalEnvironment } from '../../services/context/environment-helper';
import { environmentConnector } from '../environment-connector/environment-connector';

const ErrorSource = 'LocalizationConnector';
const FailureLocalizationConnectorMessage = 'Failure while trying to retreive localization resource';
const CONFIG_ID = 'V1';

let preFetchPromise: Promise<ResourceResponse | undefined> | undefined = undefined;

export type ResourceResponse = {
  [key: string]: string;
};

export const loadLocalizationResource = async (forceNew: boolean = false): Promise<ResourceResponse | undefined> => {
  if (preFetchPromise && !forceNew) {
    return preFetchPromise;
  }

  const testExt = environmentConnector() === PortalEnvironment.prod ? '' : '-tst';
  const url = `https://partner-portalplatform.microsoft${testExt}.com/portal/loc/${localeConnector()}/resource.json`;

  const connectorPromiseStoreConfig: ConnectorPromiseStore = {
    connectorType: 'localization',
    uniqueId: url + CONFIG_ID,
  };

  try {
    const preFetchPromiseResponse = httpRequestRetryStore<ResourceResponse>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin');
    if (preFetchPromiseResponse) {
      preFetchPromise = preFetchPromiseResponse as Promise<ResourceResponse | undefined>;
      return preFetchPromise;
    }
  } catch (error: any) {
    const message: string = error?.message ?? FailureLocalizationConnectorMessage;

    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: ErrorSource,
      statusCode: '500',
      errorObject: JSON.stringify(error) ?? '',
    };
    trackError(ErrorSource, errorInformation);
    throw new HarmonyConnectorError(ErrorSource, FailureLocalizationConnectorMessage);
  }

  return preFetchPromise;
};

export const localizedValue = async (key: string): Promise<string | undefined> => {
  const resource = await loadLocalizationResource();
  return resource ? resource[key] : undefined;
};
