import { html } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import HarmonyElement from '../../base-components/base.js';
import { HasSlotController } from '../../internal/slot.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './progress-ring.styles.js';

/**
 *
 * Progress rings are used to visualize a known percentage value or to represent an unspecified, indeterminate wait time.
 *
 * @tag he-progress-ring
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19302&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @slot default - The label to show next to the progress ring.
 *
 * @cssproperty [--ring-size=32px] Progress ring width and height.
 * @cssproperty [--track-width=3px] Width of the progress ring indicator.
 * @cssproperty [--track-color=--he-color-primary-200] Color of the unshaded track.
 * @cssproperty [--indicator-color=--he-color-primary-600] Color of the shaded in track.
 * @cssproperty [--label-font-size=14px] Font size of the label text.
 * @cssproperty [--label-font-color=--he-color-primary-600] Font color of the label text.
 * @cssproperty [--label-line-height=20px] Line height of the label text
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('progress-ring')
export class ProgressRing extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  private readonly hasSlotController = new HasSlotController(this, '[default]');

  @query('.progress-ring__indicator')
  private indicator: SVGCircleElement;

  @state()
  private indicatorOffset: string;

  /** Value between 0 and 100 to represent the progress visually and to assistive technologies. */
  @property({ type: Number, reflect: true }) value = 0;

  /** A custom label rendered under the progress ring. */
  @property() label = '';

  /** When true, percentage is ignored, the label is hidden, and the progress bar is drawn in an indeterminate state. */
  @property({ type: Boolean, reflect: true }) indeterminate = false;

  /** The preferred placement of the progress label. */
  @property({ attribute: 'label-placement' })
  public labelPlacement: 'bottom' | 'start' | 'end' | 'top' = 'bottom';

  protected updated(changedProps: Map<string, unknown>) {
    super.updated(changedProps);

    if (changedProps.has('percentage')) {
      const radius = parseFloat(getComputedStyle(this.indicator).getPropertyValue('r'));
      const circumference = 2 * Math.PI * radius;
      const offset = circumference - (this.value / 100) * circumference;

      this.indicatorOffset = `${offset}px`;
    }
  }

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          'progress-ring': true,
          'progress-ring--indeterminate': this.indeterminate,
          [`progress-ring--${this.labelPlacement}`]: true,
        })}
        role="progressbar"
        aria-labelledby="label"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow="${this.indeterminate ? 0 : this.value}"
        style="--percentage: ${this.indeterminate ? 0 : this.value / 100}"
      >
        <div class="progress-ring__container">
          <svg class="progress-ring__image">
            <circle class="progress-ring__track"></circle>
            <circle class="progress-ring__indicator" style="stroke-dashoffset: ${this.indicatorOffset}"></circle>
          </svg>
        </div>
        <div id="label" class="label">
          ${this.label || this.hasSlotController.test('[default]')
            ? html`<span part="label" class="progress-ring__label">
                ${this.label}
                <slot></slot>
              </span>`
            : html`<span class="visually-hidden">${this.localize.term('progress')}</span>`}
        </div>
      </div>
    `;
  }
}

export default ProgressRing;
