import { html } from 'lit/static-html.js';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { FormSubmitController } from '../../internal/form.js';
import { HasSlotController } from '../../internal/slot.js';
import HarmonyInputElement from '../../base-components/input.js';
import componentStyles from '../../internal/styles/component.styles.js';
import formControlStyles from '../../internal/styles/form-control.styles.js';
import { Component } from '../../utilities/decorators.js';
import Icon from '../icon/icon.js';
import styles from './search-box.styles.js';

/**
 *
 * Search Box is a component that allows users to input text and submit a search query.
 *
 * @tag he-search-box
 * @since 3.0
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19303&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-icon
 *
 * @slot label - The input's label. Alternatively, you can use the label prop.
 * @slot help-text - Help text that describes how to use the input. Alternatively, you can use the `help-text` attribute.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-change - Emitted when an alteration to the control's value is committed. This event also fires when the control is cleared or the `Enter` key is pressed.
 * @event he-search - Emitted when the user presses the `Enter` key or when the the clear button is used.
 * @event he-clear - Emitted when the clear button is activated.
 * @event he-input - Emitted when the control receives input and its value changes.
 * @event he-focus - Emitted when the control gains focus.
 * @event he-blur - Emitted when the control loses focus.
 *
 * @csspart form-control - The form control that wraps the label, input, and help-text.
 * @csspart form-control-label - The label's wrapper.
 * @csspart form-control-input - The input's wrapper.
 * @csspart form-control-help-text - The help text's wrapper.
 * @csspart form-control-error-text - The control's error text's wrapper.
 * @csspart form-control-error-text-icon - The icon in the control's error text.
 * @csspart form-control-error-text-message - The message in the control's error text.
 * @csspart base - The component's internal wrapper.
 * @csspart input - The input control.
 * @csspart search-icon - The input prefix container.
 * @csspart clear-button - The clear button.
 */
@Component('search-box', [Icon])
export class SearchBox extends HarmonyInputElement {
  static styles = [componentStyles, formControlStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeBlur: new CustomEvent('he-blur'),
    onHeChange: new CustomEvent('he-change'),
    onHeClear: new CustomEvent('he-clear'),
    onHeFocus: new CustomEvent('he-focus'),
    onHeInput: new CustomEvent('he-input'),
    onHeSearch: new CustomEvent('he-search'),
  };

  @query('.search-box__control')
  protected input?: HTMLInputElement;

  private readonly formSubmitController = new FormSubmitController(this);
  private readonly hasSlotController = new HasSlotController(this, 'help-text', 'label');
  protected usesArrowKeys = true;

  /** The input's type. */
  @property({ reflect: true }) type:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url' = 'text';

  /** The minimum length of input that will be considered valid. */
  @property({ type: Number }) minlength: number;

  /** The maximum length of input that will be considered valid. */
  @property({ type: Number }) maxlength: number;

  /** A pattern to validate input against. */
  @property() pattern: string;

  /** Controls whether and how text input is automatically capitalized as it is entered/edited by the user. */
  @property() autocapitalize: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';

  /**
   * (Non-standard - Safari only). A string which indicates whether to activate automatic correction while the user
   * is editing this field.
   */
  @property() autocorrect: string;

  /**
   * Permission the user agent has to provide automated assistance in filling out form field values and the type of
   * information expected in the field.
   */
  @property() autocomplete: string;

  /**
   * Used to customize the label or icon of the Enter key on virtual keyboards.
   */
  @property() enterkeyhint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

  /** Enables spell checking on the input. */
  @property({ type: Boolean, reflect: true }) spellcheck: boolean;

  /** Hints at the type of data that might be entered by the user while editing the element or its contents. */
  @property() inputmode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';

  /** The input's value attribute. */
  @property({ reflect: true })
  get value(): string | number {
    return this._value;
  }

  set value(val: string | number) {
    super.value = val;
    if (!this.hasUpdated) return;
    this.invalid = !this.input?.checkValidity();
    this.requestUpdate();
  }

  constructor() {
    super();

    // Set a default, localized placeholder if one hasn't been provided
    if (!this.placeholder) {
      this.placeholder = this.localize.term('search');
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.invalid = !this.input?.checkValidity();
  }

  /** Selects all the text in the input. */
  select() {
    this.input?.select();
  }

  /** Sets the start and end positions of the text selection (0-based). */
  setSelectionRange(
    selectionStart: number,
    selectionEnd: number,
    selectionDirection: 'forward' | 'backward' | 'none' = 'none'
  ) {
    this.input?.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }

  /** Replaces a range of text with a new string. */
  setRangeText(
    replacement: string,
    start: number,
    end: number,
    selectMode: 'select' | 'start' | 'end' | 'preserve' = 'preserve'
  ) {
    this.input?.setRangeText(replacement, start, end, selectMode);

    if (this.input && this.value !== this.input?.value) {
      this.value = this.input.value;
      this.emitInput();
      this.emitChange();
    }
  }

  private handleChange() {
    if (this.input && this.value !== this.input.value) {
      this.value = this.input.value;
      this.emitChange();
    }
  }

  private handleClearClick(event: MouseEvent) {
    this.value = '';
    this.emit('he-clear');
    this.emitInput();
    this.emitChange();
    this.emit('he-search');
    this.input?.focus();

    event.stopPropagation();
  }

  private handleInput() {
    this.value = this.input!.value;
    this.emitInput();
  }

  private handleInvalid() {
    this.invalid = true;
  }

  private handleKeyDown(event: KeyboardEvent) {
    const hasModifier = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input, but we wait a tick before
    // submitting to allow users to cancel the keydown event if they need to
    if (event.key === 'Enter' && !hasModifier) {
      this.emitChange();
      this.emit('he-search');
      setTimeout(() => {
        if (!event.defaultPrevented) {
          this.formSubmitController.submit();
        }
      });
    }
  }

  render() {
    const hasLabelSlot = this.hasSlotController.test('label');
    const hasHelpTextSlot = this.hasSlotController.test('help-text');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const hasHelpText = this.helpText ? true : !!hasHelpTextSlot;
    const hasClearIcon = !this.disabled && !this.readonly && (typeof this.value === 'number' || this.value!.length > 0);

    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--has-label': hasLabel,
          'form-control--has-help-text': hasHelpText,
          'form-control--has-error': this.invalid,
          'form-control--has-interaction': this.hadFocus,
        })}
      >
        <label
          part="form-control-label"
          class=${classMap({
            'form-control__label': true,
            'visually-hidden': this.hideLabel,
          })}
          for="input"
          aria-hidden=${hasLabel ? 'false' : 'true'}
        >
          <slot name="label">${this.label}</slot>
          ${this.requiredTemplate()}
        </label>
        <div part="form-control-input" class="form-control-input">
          <div
            part="base"
            class=${classMap({
              'search-box': true,
              'search-box--disabled': this.disabled,
              'search-box--focused': this.hasFocus,
              'search-box--empty': !this.value,
              'search-box--invalid': this.invalid,
            })}
          >
            <span part="search-icon" class="search-box__search-icon">
              <${this.scope.tag('icon')} name="search">
              </${this.scope.tag('icon')}>
            </span>
            <input
              part="input"
              id="input"
              class="search-box__control"
              type="search"
              name=${ifDefined(this.name)}
              ?disabled=${this.disabled}  
              ?readOnly=${this.readonly || this.disabled}
              ?required=${this.required}
              placeholder=${ifDefined(this.placeholder)}
              minlength=${ifDefined(this.minlength)}
              maxlength=${ifDefined(this.maxlength)}
              .value=${live(this.value!.toString())}
              autocapitalize=${ifDefined(this.autocapitalize)}
              autocomplete=${ifDefined(this.autocomplete) as any}
              autocorrect=${ifDefined(this.autocorrect)}
              ?autofocus=${this.autofocus}
              spellcheck=${ifDefined(this.spellcheck)}
              pattern=${ifDefined(this.pattern)}
              enterkeyhint=${ifDefined(this.enterkeyhint)}
              inputmode=${ifDefined(this.inputmode)}
              aria-disabled=${this.disabled ? 'true' : 'false'}
              aria-describedby="help-text"
              aria-invalid=${this.invalid && this.hadFocus ? 'true' : 'false'}
              @change=${this.handleChange}
              @input=${this.handleInput}
              @invalid=${this.handleInvalid}
              @keydown=${this.handleKeyDown}
            />
            ${
              hasClearIcon
                ? html`
                  <button
                    part="clear-button"
                    class="search-box__clear"
                    type="button"
                    aria-label=${this.localize.term('clear_entry')}
                    @click=${this.handleClearClick}
                    tabindex="-1"
                  >
                    <${this.scope.tag('icon')} name="clear" library="system" class="search-box__clear-icon">
                    </${this.scope.tag('icon')}>
                  </button>
                `
                : ''
            }
          </div>
        </div>
        <div
          part="form-control-help-text"
          id="help-text"
          class=${classMap({
            'form-control__help-text': true,
            'visually-hidden': this.hideLabel,
          })}
          aria-hidden=${hasHelpText ? 'false' : 'true'}
        >
          <slot name="help-text">${this.helpText}</slot>
        </div>
        ${this.errorTextTemplate()}
      </div>
    `;
  }
}

export default SearchBox;
