import { html } from 'lit/static-html.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { HasSlotController } from '../../internal/slot.js';
import { watch } from '../../internal/watch.js';
import { animateTo, stopAnimations } from '../../internal/animations.js';
import button from '../button/button.js';
import icon from '../icon/icon.js';
import HarmonyDismissibleElement from '../../base-components/dismissible.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './message-bar.styles.js';

const KEY_FRAMES = {
  right: {
    open: [
      { opacity: 0, transform: 'translateX(100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(100%)' },
    ],
  },
  left: {
    open: [
      { opacity: 0, transform: 'translateX(-100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(-100%)' },
    ],
  },
};

/**
 *
 * A message bar is a banner that displays errors, warnings, or important information. For example, if a file failed to upload an error message bar will appear.
 *
 * @tag he-message-bar
 * @since 2.1
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19294&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @slot - The message content of the message bar.
 * @slot icon - Optional icon to override the built-in icons.
 * @slot actions - Optional action buttons to display for the message bar.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-message-bar-show - Emitted when the message bar is opened.
 * @event he-message-bar-after-show - Emitted after the message bar is opened and the transitions are complete.
 * @event he-message-bar-hide - Emitted when the message bar is closed.
 * @event he-message-bar-after-hide - Emitted after the message bar is closed and the transitions are complete.
 * @event he-show - (@deprecated) Use `he-message-bar-show` instead.
 * @event he-after-show - (@deprecated) Use `he-message-bar-after-show` instead.
 * @event he-hide - (@deprecated) Use `he-message-bar-hide` instead.
 * @event he-after-hide - (@deprecated) Use `he-message-bar-after-hide` instead.
 *
 * @csspart base - The component's base wrapper.
 * @csspart content - The "content" of the collapsed or single line message.
 * @csspart footer - The footer portion of the message only displayed when expanded.
 * @csspart icon - The icon of the message bar.
 * @csspart message - The base of the message portion of the bar.
 * @csspart actions - The base of the actions slot's content.
 *
 * @dependency he-button
 * @dependency he-icon
 */
@Component('message-bar', [button, icon])
export class MessageBar extends HarmonyDismissibleElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeMessageBarShow: new CustomEvent('he-message-bar-show'),
    onHeMessageBarAfterShow: new CustomEvent('he-message-bar-after-show'),
    onHeMessageBarHide: new CustomEvent('he-message-bar-hide'),
    onHeMessageBarAfterHide: new CustomEvent('he-message-bar-after-hide'),
    /** @deprecated Use `onHeMessageBarShow` instead. */
    onHeShow: new CustomEvent('he-show'),
    /** @deprecated Use `onHeMessageBarAfterShow` instead. */
    onHeAfterShow: new CustomEvent('he-after-show'),
    /** @deprecated Use `onHeMessageBarHide` instead. */
    onHeHide: new CustomEvent('he-hide'),
    /** @deprecated Use `onHeMessageBarAfterHide` instead. */
    onHeAfterHide: new CustomEvent('he-after-hide'),
  };

  private resizeObserver: ResizeObserver;
  private closeTimeout: null | number = null;
  @query('.message-bar') private base?: HTMLElement;
  @query('.message-bar__message-container') private messageContainer?: HTMLElement;
  @query('.message-bar__message-inner-container') private messageContent?: HTMLElement;
  private hasSlotController = new HasSlotController(this, '[default]', 'actions', 'icon');
  private originalTrigger: HTMLElement | null;
  protected scopedEventName = MessageBar.baseName;

  /**
   * The direction the message bar will open and close from.
   *
   * Note: "right" and "left" will be deprecated in a future version. Please use the logical properties "end" or
   * "start", which are based on language direction.
   */
  @property() direction: 'end' | 'start' | 'right' | 'left' = 'end';

  // direction converted to right or left based on direction
  @state()
  private get _direction() {
    switch (this.direction) {
      case 'start':
        return this.dir === 'rtl' ? 'right' : 'left';
      case 'end':
        return this.dir === 'rtl' ? 'left' : 'right';
      default:
        return this.direction;
    }
  }

  /** Indicates the transition speed for animations in ms. */
  @property({ type: Number, attribute: 'transition-speed' }) transitionSpeed = 400;

  /** Expands the message if it is a multi line message. */
  @property({ type: Boolean, reflect: true }) expanded: boolean = false;

  /** Hides the close button (message will still auto close). */
  @property({ type: Boolean, reflect: true, attribute: 'no-close-button' }) noCloseButton: boolean = false;

  /**
   * The time in milliseconds before the message automatically closes. By default, the message bar will remain open
   * indefinitely.
   */
  @property({ type: Number }) duration = Infinity;

  /** Indicates the type and appearance of the message bar. */
  @property() appearance: 'info' | 'notice' | 'success' | 'warning' | 'error' = 'info';

  /** Allows developers to manually control the multiline behavior */
  @property({ type: Boolean, reflect: true }) expandable?: boolean;

  /** @internal watcher */
  @watch('appearance')
  handleAppearanceChange(oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      this.iconName = this.getIconName(newValue);
    }
  }

  /** @internal watcher */
  @watch('expandable')
  handleExpandableChange() {
    this.setMultiline();
  }

  @state()
  private iconName: string = 'info';

  @state()
  private multiline: boolean = false;

  @state()
  private visible: boolean = false;

  /** @internal watcher */
  @watch('visible')
  handleVisibleChange() {
    this.classList.toggle('he-hidden', !this.visible);
  }

  connectedCallback() {
    super.connectedCallback();
    this.initResizeObserver();
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.open) {
      this.visible = true;
      this.startDuration();
      this.updateComplete.then(() => this.setMultiline());
    } else {
      this.visible = false;
    }
  }

  disconnectedCallback() {
    this.resizeObserver.unobserve(this);
    this.resizeObserver.disconnect();
    super.disconnectedCallback();
  }

  private get keyFrames() {
    switch (this._direction) {
      case 'left':
        return KEY_FRAMES.left;
      default:
        return KEY_FRAMES.right;
    }
  }

  private startDuration() {
    // Reset the timer
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    if (this.duration > 0 && this.duration < Infinity) {
      this.closeTimeout = window.setTimeout(() => {
        if (this.open) {
          this.hide();
        }
      }, this.duration);
    }
  }

  private handleSlotChange() {
    this.setMultiline();
  }

  private setMultiline() {
    if (!this.open) return;

    if (this.expandable !== undefined) {
      this.multiline = this.expandable;
    } else if (this.expanded) {
      this.multiline = true;
    } else {
      const containerWidth = this.messageContainer?.offsetWidth || 0;
      if (containerWidth === 0) return;

      const containerHeight = this.messageContainer?.clientHeight || 0;
      if (containerHeight === 0) return;

      const contentWidth = this.messageContent?.offsetWidth || 0;
      const contentHeight = this.messageContent?.clientHeight || 0;
      const isTooWide = contentWidth >= containerWidth;
      const isTooTall = contentHeight > containerHeight + 4;

      this.multiline = isTooWide || isTooTall;
    }
  }

  private initResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => this.setMultiline());
    this.resizeObserver.observe(this);
  }

  /** Toggle the expanded attribute to expand or collapse multi line message. */
  public toggleExpand() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.messageContent?.focus();
    }
  }

  private getIconName(type: string) {
    switch (type) {
      case 'success':
        return 'completed';
      case 'warning':
        return 'warning';
      case 'error':
        return 'errorbadge';
      case 'notice':
      default:
        return 'info';
    }
  }

  public override async show(): Promise<void> {
    this.originalTrigger = this.findRootNode(this)?.activeElement as HTMLElement | null;
    await super.show();
    this.messageContent?.focus();
    return Promise.resolve();
  }

  public override async hide() {
    if (!this.open) {
      return;
    }

    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    this.expanded = false;

    // restore focus to the original trigger
    if (this.originalTrigger && typeof this.originalTrigger.focus === 'function') {
      this.originalTrigger.focus();
    }

    return super.hide();
  }

  /** @internal watcher */
  @watch('open', { waitUntilFirstUpdate: true })
  async openChange() {
    if (!this.base) {
      return;
    }

    if (this.open) {
      await stopAnimations(this.base);
      this.visible = true;
      await animateTo(this.base, this.keyFrames.open, { duration: this.transitionSpeed, easing: 'ease' });
      this.startDuration();
      this.emitAfterShow();
    } else {
      await animateTo(this.base, this.keyFrames.close, { duration: this.transitionSpeed, easing: 'ease' });
      this.visible = false;
      this.closeTimeout = null;
      this.emitAfterHide();
    }
  }

  render() {
    return html`
      <div class="message-bar__wrapper">
        <div
          part="base"
          class=
          ${classMap({
            'message-bar': true,
            'message-bar--open': this.open,
            'message-bar--collapsed': !this.expanded,
            'message-bar--expanded': this.expanded,
            [`message-bar--${this.appearance}`]: true,
            'message-bar--has-actions': this.hasSlotController.test('actions'),
          })}
          ?hidden=${!this.visible}
        >
          <div part="content" class="message-bar__content">
            <div part="icon" class="message-bar-icon">
              <slot name="icon">
                <${this.scope.tag('icon')} name="${this.iconName}"></${this.scope.tag('icon')}>
              </slot>
            </div>
            <div part="message" class='message-bar__message-container'>
              <div class="message-bar__message-inner-container" tabindex="-1" role="text">
                <slot @slotchange=${this.handleSlotChange}></slot>
              </div>
            </div>
            <div part="actions" class="message-bar__actions">
              ${!this.expanded ? html`<slot name="actions"></slot>` : ''}
              ${
                this.multiline
                  ? html`
                    <${this.scope.tag('button')}
                      class="message-bar__button message-bar__multiline__button"
                      appearance="stealth"
                      ?expanded=${this.expanded}
                      @click=${this.toggleExpand}
                    >
                      <${this.scope.tag('icon')}
                        name=${this.expanded ? 'doublechevronup' : 'doublechevrondown'}
                        label="${this.localize.term('more_info')}"
                      ></${this.scope.tag('icon')}>
                    </${this.scope.tag('button')}>
                  `
                  : ''
              }
              ${
                !this.noCloseButton
                  ? html`
                    <${this.scope.tag('button')}
                      class="message-bar__button"
                      appearance="stealth"
                      @click=${this.hide}
                    >
                      <${this.scope.tag('icon')} name="cancel" label=${this.localize.term('close_message')}>
                      </${this.scope.tag('icon')}>
                    </${this.scope.tag('button')}>
                  `
                  : ''
              }
            </div>
          </div>
          ${
            this.expanded
              ? html`
                  <div part="footer" class="message-bar__footer">
                    <slot name="actions"></slot>
                  </div>
                `
              : ''
          }
        </div>
      </div>
    `;
  }
}

export default MessageBar;
