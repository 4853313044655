import { windowHandler } from '../helpers/window-helper';
import { trackError } from '../services/telemetry-service/library-telemetry-service';
import { ErrorInformation } from '../services/telemetry-service/types/error-information';
import VersionPickerResponse, { emptyVersionPickerResponse } from './version-picker-response';

/**
 * @since 3.0.0
 * @status experimental
 * @part - This is version picker script referenced by shell library consumers on their pages and sets to the window.
 *
 */

const globalVersionPicker = getVersions();

export type ShellLibraryKeys = {
  [key: `shellLibraryConnectors-${string}`]: string;
};

export class VersionPicker {
  static instance: VersionPicker;
  private windowHandler: windowHandler = windowHandler.getInstance();
  private nameof = <T>(name: keyof T) => name;

  public static getInstance() {
    if (!VersionPicker.instance) {
      VersionPicker.instance = new VersionPicker();
    }

    return VersionPicker.instance;
  }

  public async loadShellLibrary(): Promise<any> {
    let version: string | null = null;
    const defaultVersion = '3.0.8';
    const splitVersion = defaultVersion.split('.');

    if (splitVersion?.length > 0 && splitVersion[0]) {
      const majorVersion = splitVersion[0];
      try {
        const versionPicker = await globalVersionPicker;

        if (versionPicker.versions) {
          const latestVersion = versionPicker.versions[majorVersion];
          if (latestVersion) {
            version = latestVersion;
          }
        }
      } catch (err) {
        const errorInformation: ErrorInformation = {
          errorMessage: 'Failed to get version from version picker. Using default version.',
          source: 'versionPicker',
          errorObject: err,
          statusCode: '400',
        };
        trackError('version-picker-failure', errorInformation);
      }
    }

    if (!version) {
      version = defaultVersion;
    }

    const key = this.getKey(version);
    let shellLibraryInstance = this.windowHandler.tryGetFromWindow(key);
    if (!shellLibraryInstance) {
      let shellLibraryPath = 'https://partner-portalplatform.microsoft.com/he-shell-library/{version}/internal.js';
      shellLibraryPath = shellLibraryPath.replace('{version}', version);

      try {
        shellLibraryInstance = importDynamically(shellLibraryPath);
        console.log(`Shell library (version ${version}) loaded.`);
        this.windowHandler.addToWindowObject(key, shellLibraryInstance);
      } catch (err) {
        const errorInformation: ErrorInformation = {
          errorMessage: 'Failed to dynamically import shell library. Using default version.',
          source: 'versionPicker',
          errorObject: err,
          statusCode: '400',
        };
        trackError('version-picker-failure', errorInformation);
      }
    }

    return shellLibraryInstance;
  }

  public getKey(version: string): keyof ShellLibraryKeys {
    return this.nameof<ShellLibraryKeys>(`shellLibraryConnectors-${version}`);
  }
}

function importDynamically(path: string) {
  // we wrap the import in an eval to avoid webpack from trying to resolve the import at build time
  // call indirect eval by using window.eval
  // https://esbuild.github.io/content-types/#real-esm-imports
  return (window as any).eval(`import('${path}');`);
}

async function getVersions(): Promise<VersionPickerResponse> {
  const versionPickerResponse = emptyVersionPickerResponse;
  const versionPickerPath = 'https://partner-portalplatform.microsoft.com/he-shell-library/he-shell-library.json';

  try {
    const response = await fetch(`${versionPickerPath}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const versions = (await response.json()) as { [key: string]: string };
      if (versions) {
        versionPickerResponse.versions = versions;
      }
    }
  } catch (err) {
    versionPickerResponse.errorMessage = JSON.stringify(err);
  }

  return versionPickerResponse;
}
