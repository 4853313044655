import { html, LitElement, PropertyValueMap } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { defaultValue } from '../../internal/default-value.js';
import { watch } from '../../internal/watch.js';
import { FormSubmitController } from '../../internal/form.js';
import HarmonyInputElement from '../../base-components/input.js';
import componentStyles from '../../internal/styles/component.styles.js';
import formControlStyles from '../../internal/styles/form-control.styles.js';
import { HasSlotController } from '../../internal/slot.js';
import { Component } from '../../utilities/decorators.js';
import Icon from '../icon/icon.js';
import styles from './checkbox.styles.js';

/**
 *
 * Use a checkbox either to indicate a binary (yes/no) option or when the user can make multiple selections in a form.
 *
 * @tag he-checkbox
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19255&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-icon
 *
 * @slot - The checkbox's label.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-blur - Emitted when the control loses focus.
 * @event he-change - Emitted when the control's checked state changes.
 * @event he-focus - Emitted when the control gains focus.
 *
 * @csspart base - The component's internal wrapper.
 * @csspart control - The checkbox control.
 * @csspart checked-icon - The container the wraps the checked icon.
 * @csspart indeterminate-icon - The container that wraps the indeterminate icon.
 * @csspart label - The checkbox label.
 * @csspart control-label-wrapper - The container wrapping control and label parts.
 * @csspart form-control-error-text - The control's error text's wrapper.
 * @csspart form-control-error-text-icon - The icon in the control's error text.
 * @csspart form-control-error-text-message - The message in the control's error text.
 * @csspart form-control - The components outer wrapper.
 *
 * @omit helpText
 * @omit placeholder
 */
@Component('checkbox', [Icon])
export class Checkbox extends HarmonyInputElement {
  static shadowRootOptions = { ...LitElement.shadowRootOptions, delegatesFocus: false };
  static styles = [componentStyles, formControlStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeBlur: new CustomEvent('he-blur'),
    onHeChange: new CustomEvent('he-change'),
    onHeFocus: new CustomEvent('he-focus'),
  };

  private _originallyDisabled = false;
  private _disabledByCheckboxGroup = false;
  private readonly hasSlotController = new HasSlotController(this, '[default]');

  private readonly formSubmitController = new FormSubmitController(this, {
    value: (control: Checkbox) => (control.checked ? control.value || 'on' : undefined),
    defaultValue: (control: Checkbox) => control.defaultChecked,
    setValue: (control: Checkbox, checked: boolean) => (control.checked = checked),
    hasCustomValidation: true,
  });

  @query('input[type="checkbox"]')
  input?: HTMLInputElement;

  /** Draws the checkbox in a checked state. */
  @property({ type: Boolean, reflect: true })
  checked = false;

  /** Draws the checkbox in an indeterminate state. */
  @property({ type: Boolean, reflect: true })
  indeterminate = false;

  /** @internal - Used to disable checkbox at a group level */
  @property({ attribute: false, type: Boolean })
  set disabledByCheckboxGroup(disabled: boolean) {
    this._disabledByCheckboxGroup = disabled;
    this.disabled = this._originallyDisabled || this._disabledByCheckboxGroup;
  }

  /** @internal Gets or sets the default value used to reset this element. The initial value corresponds to the one originally specified in the HTML that created this element. */
  @defaultValue('checked')
  defaultChecked = false;

  @state() private isOption = false;

  protected update(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    super.update(changedProperties);
    this.isOption = this.getAttribute('role') === 'option';
    if (this.isOption) {
      this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('focus', e => {
      if (!this.isOption) {
        this.input?.focus();
      }
    });
    this.addEventListener('blur', e => {
      if (!this.isOption) {
        this.input?.blur();
      }
    });
    this.addEventListener('click', this.handleClick);
  }

  /** @internal Focus on the element. */
  public focus() {
    super.focus();
    if (!this.isOption) {
      this.input?.focus();
    }
  }

  /** @internal Blur from the element. */
  public blur() {
    super.blur();
    if (!this.isOption) {
      this.input?.blur();
    }
  }

  /** @internal Click the checkbox. */
  public click() {
    super.click();
    if (!this.isOption && !this.disabled && !this.readonly) {
      this.input?.click();
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.invalid = !this.checkValidity();
    this._originallyDisabled = this.disabled;
  }

  private handleClick(e: MouseEvent) {
    if (this.disabled || this.readonly) {
      e.preventDefault();
      return;
    }
    // we can't set checked inside the click handler on a normal checkbox, as screen readers won't read the state change
    // of the input when set programmatically, so this is only for when it's in a checkbox group
    if (!this.isOption || this.disabled) {
      return;
    }

    e.preventDefault();
    this.checked = !this.checked;
    this.indeterminate = false;
    this.emitChange();
  }

  private handleInputChange() {
    this.checked = this.input!.checked;
    this.indeterminate = false;
    this.emitChange();
  }

  private handleKeyDown(e: KeyboardEvent) {
    if ((this.disabled || this.readonly) && e.key === ' ') {
      e.preventDefault();
    }
  }

  private handleInputBlur() {
    if (this.invalid) {
      this.reportValidity();
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(val: boolean) {
    super.disabled = val;
    if (this.disabled && !this._disabledByCheckboxGroup) {
      this._originallyDisabled = true;
    }
  }

  /** @internal watcher */
  @watch('checked', { waitUntilFirstUpdate: true })
  @watch('indeterminate', { waitUntilFirstUpdate: true })
  async handleStateChange() {
    await this.updateComplete;
    this.reportValidity();
  }

  render() {
    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--group-item': true,
          'form-control--has-error': this.invalid,
          'form-control--has-interaction': this.hadFocus,
          'form-control--has-label': !!this.label || this.hasSlotController.test('[default]'),
        })}
      >
        <label
          part="base"
          class=${classMap({
            checkbox: true,
            'checkbox--checked': this.checked,
            'checkbox--disabled': this.disabled,
            'checkbox--focused': this.hasFocus,
            'checkbox--indeterminate': this.indeterminate,
          })}
        >
          <input
            class="checkbox__input"
            type="checkbox"
            name=${ifDefined(this.name)}
            .value=${live(this.value?.toString())}
            .indeterminate=${this.indeterminate}
            .checked=${live(this.checked)}
            ?required=${this.required}
            ?autofocus=${this.autofocus}
            ?readonly=${this.readonly || this.disabled}
            aria-disabled=${this.disabled ? 'true' : 'false'}
            aria-errormessage="error-text"
            aria-invalid=${this.invalid && this.hadFocus ? 'true' : 'false'}
            ?hidden=${this.isOption}
            @blur=${this.handleInputBlur}
            @change=${this.handleInputChange}
            @keydown=${this.handleKeyDown}
          />

          <span part="control-label-wrapper" class="checkbox__control-label-wrapper">
            <span part="control" class="checkbox__control">
              ${!this.indeterminate && this.checked
                ? html`
                    <svg
                      part="checked-icon"
                      class="checkbox__icon checkbox__icon--checked"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 2048 2048"
                      fill="currentColor"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M1837 557L768 1627l-557-558 90-90 467 466 979-978 90 90z" />
                    </svg>
                  `
                : ''}
              ${this.indeterminate
                ? html`
                    <svg
                      part="indeterminate-icon"
                      class="checkbox__icon checkbox__icon--indeterminate"
                      viewBox="0 0 20 20"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <rect fill="currentColor" x="5" y="5" width="10" height="10" rx="1" fill-rule="evenodd" />
                    </svg>
                  `
                : ''}
            </span>

            <span
              part="label"
              class=${classMap({
                checkbox__label: true,
                'form-control--group-item__label': true,
                'visually-hidden': this.hideLabel,
              })}
            >
              ${this.label || html`<slot></slot>`}${this.requiredTemplate()}
            </span>
          </span>
        </label>

        ${this.errorTextTemplate(false)}
      </div>
    `;
  }
}

export default Checkbox;
