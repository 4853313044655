import { html } from 'lit';
import HarmonyElement from '../../base-components/base.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './visually-hidden.styles.js';

/**
 *
 * Visually hidden content is useful for accessibility and is used to hide content that is meant to be read by assistive technologies like screen readers.
 * If an element within the component is focusable, it will become visible when focused.
 *
 * @tag he-visually-hidden
 * @since 3.0
 * @status stable
 *
 * @slot - The content you'd like to be visually hidden.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('visually-hidden')
export class VisuallyHidden extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  render() {
    return html`<slot></slot>`;
  }
}

export default VisuallyHidden;
