import { css } from 'lit';

export default css`
  :host {
    --layout-height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
    --layout-sub-header-height: 48px;
    --layout-header-height: 48px;

    /**
		 * On small screens (< 768px), we want 16px, on large screens (>= 768px), we want 32px.
     * 768px / 32px = 0.041666666 (4.166666vw)
     * We could add mediaQuery event listener in the component, but I think fluid padding is fine for now.
		 * https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/Harmony-Toolkit?node-id=688%3A1318&t=sbrw5XexOfQiPV2t-0
     */
    --layout-main-padding: var(--he-spacing-small)
      clamp(var(--he-spacing-medium), 4.166667vw, var(--he-spacing-2x-large));

    display: block;
    background-color: var(--he-color-body-background);
    color: var(--he-type-primary);

    /** Add overflow hidden to prevent accidental scrolling. */
    overflow: hidden;
  }

  .layout {
    display: grid;
    /* Body */
    grid-template-rows: minmax(0, 1fr);
    min-height: var(--layout-height);
    height: var(--layout-height);
  }

  .layout__header,
  .layout__sub-header {
    display: none;
  }

  :host([has-header]) .layout {
    /* Header, Body */
    grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  }

  :host([has-sub-header]) .layout__body {
    /* Subheader, Content, Panel, Dialog */
    grid-template-rows: minmax(0, max-content) minmax(0, 1fr) repeat(2, minmax(0, auto));
  }

  :host([has-sub-header]) .layout__sub-header,
  :host([has-header]) .layout__header {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }

  :host([has-sub-header]) .layout__sub-header {
    border-bottom: 1px solid var(--he-color-card-divider);
  }

  .layout__header {
    min-height: var(--layout-header-height);
  }

  .layout__sub-header {
    min-height: var(--layout-sub-header-height);
  }

  .layout__header ::slotted(:not(.he-nav-header)),
  .layout__sub-header ::slotted(:not(.he-nav-header)) {
    padding-inline-start: var(--he-spacing-small);
    padding-inline-end: var(--he-spacing-small);
  }

  .layout__body {
    position: relative;
    display: grid;
    /* Content, Panel, Dialog */
    grid-template-rows: minmax(0, 1fr) repeat(2, minmax(0, auto));
    min-height: 100%;
    height: 100%;
  }

  .layout__content {
    display: grid;
    /* Sidebar, Main, Aside */
    grid-template-columns: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
    height: 100%;
    grid-template-rows: minmax(0, 1fr);
  }

  .layout__menu ::slotted(*) {
    height: 100%;
    border-inline-end: 1px solid var(--he-color-card-divider);
  }

  .layout__aside ::slotted(*) {
    height: 100%;
  }

  .layout__main {
    width: 100%;
    max-width: 100%;
    padding: var(--layout-main-padding);
  }

  /* We don't put an overflow on menu since task-menu is a special case. */
  .layout__aside,
  .layout__main {
    overflow: auto;
  }

  .layout__menu,
  .layout__aside {
    background-color: var(--he-color-body-background);
  }

  .layout__dialog {
    isolation: isolate;
    width: 0;
    height: 0;
    z-index: 5;
  }

  .layout__panel {
    isolation: isolate;
    width: 0;
    height: 0;
    z-index: 4;
  }

  /** Without this, things like stretched links bleed through */
  .layout__menu {
    position: relative;
    z-index: 1;
  }

  /** Without this, things like stretched links bleed through */
  .layout__aside {
    position: relative;
    z-index: 2;
  }

  .layout__body,
  .layout__aside,
  .layout__main,
  .layout__content,
  .layout__menu {
    max-height: 100%;
  }

  .layout__skip-to-main {
    width: 100%;
    height: 48px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--he-color-neutral-500);
    background-color: var(--he-color-neutral-200);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
  }

  :host::part(skip-to-main-anchor) {
    --he-focus-ring: transparent;
  }

  main:focus {
    outline: transparent;
  }
`;
