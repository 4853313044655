import { getScope } from '../utilities/scope.js';
import type { ReactiveController, ReactiveControllerHost } from 'lit';

export class HostClassController implements ReactiveController {
  host: ReactiveControllerHost & Element;

  constructor(host: ReactiveControllerHost & Element) {
    (this.host = host).addController(this);
  }

  hostConnected() {
    // Adds a special class to the host element using the he-[basename] convention. This lets us target a static class
    // instead of a tag name, which can change depending on the scope's prefix.
    const scope = getScope(this.host);
    // @deprecated - this class is deprecated, use the attribute instead
    this.host.classList.add(`he-${scope.getBaseName(this.host)}`);
  }
}
