import { css } from 'lit';

export const titleStyles = css`
  .title {
    font-size: var(--he-font-size-3x-large);
    line-height: var(--he-line-height-3x-large);
    font-weight: var(--he-font-weight-semibold);
    color: var(--he-color-neutral-900);
    margin: 0;
    padding: 0;
  }

  .title__normal,
  .divider {
    font-weight: var(--he-font-weight-normal);
  }

  .title > span {
    /* this allows spans to change visual order in rtl */
    display: inline-block;
  }
`;
