import { css } from 'lit';

export default css`
  :host {
    --icon-color: ;
    --label-color: ;
    --spacing: var(--he-spacing-x-small);
    --icon-size: max(1em, var(--he-icon-size));

    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }

  .icon {
    flex: 0 0 auto;
    display: flex;
    width: 1em;
    height: 1em;
    font-size: var(--icon-size);
    margin-inline-end: var(--spacing);
  }

  .icon svg {
    width: 100%;
    height: 100%;
    color: var(--icon-color, var(--he-color-neutral-700));
  }

  .icon ::slotted(.he-icon) {
    display: flex !important;
    font-size: var(--icon-size) !important;
    color: var(--icon-color, var(--he-color-neutral-700)) !important;
  }

  .label {
    flex: 1 1 auto;
    color: var(--label-color, var(--he-color-neutral-700));
    white-space: normal;
  }

  :host([size='small']) {
    --icon-size: var(--he-font-size-small);
    --spacing: var(--he-spacing-2x-small);
    font-size: var(--he-font-size-small);
  }

  :host([size='medium']) {
    --icon-size: var(--he-font-size-large);
    font-size: var(--he-font-size-medium);
  }

  :host([size='large']) {
    --icon-size: var(--he-font-size-2x-large);
    font-size: var(--he-font-size-large);
  }
`;
