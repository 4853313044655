import { html } from 'lit/static-html.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { waitForEvent } from '../../utilities/wait-for-event.js';
import popup from '../popup/popup.js';
import HarmonyDismissibleElement from '../../base-components/dismissible.js';
import { watch } from '../../internal/watch.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './dropdown.styles.js';

import type { Popup } from '../popup/popup.js';
import type { Menu } from '../menu/menu.js';
import type { MenuItem } from '../menu-item/menu-item.js';

/** @deprecated This will be removed in the next major release */
export const DropdownPosition = {
  top: 'top',
  topStart: 'top-start',
  topEnd: 'top-end',
  topCenter: 'top-center',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
  bottomCenter: 'bottom-center',
} as const;

/** @deprecated This will be removed in the next major release */
export type DropdownPositionValues = (typeof DropdownPosition)[keyof typeof DropdownPosition];

export type DropdownCloseSource = 'change' | 'trigger' | 'document' | 'keyboard';

export interface DropdownRequestCloseEvent {
  source: DropdownCloseSource;
}

// id for slotted trigger (will only be added if one doesn't exist)
let dropdownButtonId = 0;

// id for slotted menu (will only be added if one doesn't exist)
let menuId = 0;

/**
 *
 * Dropdown allows showing content dropped down from a trigger element when it is clicked. Commonly used to display a dropdown menu with a list of actions.
 *
 * @tag he-dropdown
 * @since 3.0
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19262&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-popup
 *
 * @slot - The default slot containing the menu to show when the trigger is clicked.
 * @slot trigger - The button or other element that will trigger the dropdown. (If more than one element is slotted, it will only use the first one.)
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-dropdown-show - Emitted when the dropdown menu is shown
 * @event he-dropdown-after-show - Emitted after the dropdown menu is shown and all transitions are complete
 * @event he-dropdown-hide - Emitted when the dropdown menu closes.
 * @event he-dropdown-after-hide - Emitted after the dropdown menu closes and all transitions are complete.
 * @event {DropdownRequestCloseEvent} he-dropdown-request-close - Emitted when the user attempts to
 *   close the dropdown menu by selecting a menu item, clicking the trigger, clicking outside of the menu, or pressing the
 *   escape key. Calling `event.preventDefault()` will prevent the menu from closing. You can check `event.detail.source`
 *   to determine how the request was initiated. Avoid using this unless closing the menu will result in destructive
 *   behavior and you have provided another method to close it.
 * @event he-show - (@deprecated) Use `he-dropdown-show` instead.
 * @event he-after-show - (@deprecated) Use `he-dropdown-after-show` instead.
 * @event he-hide - (@deprecated) Use `he-dropdown-hide` instead.
 * @event he-after-hide - (@deprecated) Use `he-dropdown-after-hide` instead.
 * @event {DropdownRequestCloseEvent} he-request-close - (@deprecated) Use `he-dropdown-request-close` instead.
 *
 * @csspart menu-wrapper - The wrapper containing the default slot.
 * @csspart popup - The popup component.
 * @csspart popup__popup - The popup component's container. Useful for adjusting z-index if the menu is showing behind other elements.
 *
 * @cssproperty [--he-elevation=8] - Elevation of the menu.
 * @cssproperty [--max-menu-height=360px] - Maximum height of the menu (will still be restricted to available space in the viewport).
 */
@Component('dropdown', [popup])
export class Dropdown extends HarmonyDismissibleElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeDropdownShow: new CustomEvent('he-dropdown-show'),
    onHeDropdownAfterShow: new CustomEvent('he-dropdown-after-show'),
    onHeDropdownHide: new CustomEvent('he-dropdown-hide'),
    onHeDropdownAfterHide: new CustomEvent('he-dropdown-after-hide'),
    onHeDropdownRequestClose: new CustomEvent<DropdownRequestCloseEvent>('he-dropdown-request-close'),
    /** @deprecated Use `onHeDropdownShow` instead. */
    onHeShow: new CustomEvent('he-show'),
    /** @deprecated Use `onHeDropdownAfterShow` instead. */
    onHeAfterShow: new CustomEvent('he-after-show'),
    /** @deprecated Use `onHeDropdownHide` instead. */
    onHeHide: new CustomEvent('he-hide'),
    /** @deprecated Use `onHeDropdownAfterHide` instead. */
    onHeAfterHide: new CustomEvent('he-after-hide'),
    /** @deprecated Use `onHeDropdownRequestClose` instead. */
    onHeRequestClose: new CustomEvent<DropdownRequestCloseEvent>('he-request-close'),
  };

  protected scopedEventName = Dropdown.baseName;

  @state()
  protected trigger: HTMLElement | undefined;

  @state()
  protected menu: Menu | undefined;

  @state()
  protected viewportElement: HTMLElement | null | undefined;

  @state()
  protected menuDefaultSlot: HTMLSlotElement | null | undefined;

  /** @internal used for submenus */
  @state()
  public items: MenuItem[];

  @query('.he-popup')
  protected popup: Popup;

  @query('.menu-wrapper')
  protected menuWrapper: HTMLElement;

  /**
   * Controls the placement of the menu to the trigger. When the position is undefined, it is placed above or below the
   * trigger based on available space.
   */
  @property({ reflect: true })
  position: DropdownPositionValues = 'bottom-start';

  /**
   * Whether the anchored region is positioned using css "position: fixed". Otherwise the region uses "position: absolute".
   * Fixed placement allows the dropdown contents to break out of parent containers.
   */
  @property({ reflect: true, attribute: 'fixed-placement', type: Boolean })
  fixedPlacement: boolean = false;

  /** @internal watcher */
  @watch('open')
  handleOpenChanged() {
    this.setTriggerAttribute(this.trigger!, 'expanded', this.open.toString());

    if (this.open) {
      // this is necessary for keydown event listeners when using non focusable slotted items
      this.setAttribute('tabindex', '-1');
      setTimeout(() => {
        this.items[0]?.focus();
      });
    } else {
      this.removeAttribute('tabindex');
    }
  }

  /** @internal watcher */
  @watch('trigger')
  @watch('menu')
  setAriaLabel() {
    if (!this.menu || !this.trigger) return;

    this.setTriggerAttribute(this.trigger, 'expanded', this.open.toString());
    this.setTriggerAttribute(this.trigger, 'haspopup', 'menu');

    // TODO: This causes axe test to fail, as FAST sets aria-controls on the <button> control inside the he-button shadowroot
    // this.trigger.setAttribute('aria-controls', this.menu.id);

    if (!this.trigger.id) {
      this.trigger.id = `he-dropdown-button-${++dropdownButtonId}`;
    }

    if (!this.menu.id) {
      this.menu.id = `he-menu-${++menuId}`;
    }

    if (!this.menu.getAttribute('aria-labelledby')) {
      this.menu.setAttribute('aria-labelledby', this.trigger.id);
    }
  }

  protected handleTriggerSlotChange(e: Event) {
    const target = e.target as HTMLSlotElement;

    // don't change trigger if slot change event is coming from slots inside button
    if (target.name !== 'trigger') return;

    const elements = target.assignedElements({ flatten: true }) as HTMLElement[];

    if (!elements.length) return;

    // remove event listener from old trigger
    this.trigger?.removeEventListener('click', this.handleClick);

    this.trigger = elements[0];

    // add listener to new trigger
    this.trigger?.addEventListener('click', this.handleClick);

    this.setAriaLabel();
  }

  protected handleDefaultSlotChange(e: Event) {
    const target = e.target as HTMLSlotElement;

    const menuElements = target
      .assignedElements({ flatten: true })
      .filter(el => el.getAttribute('role') === 'menu') as Menu[];

    if (menuElements.length <= 0) return;

    this.menu = menuElements[0];

    this.removeMenuListener();
    this.attachMenuListener();
    this.setAriaLabel();
  }

  protected attachMenuListener() {
    this.menuDefaultSlot = this.menu?.shadowRoot?.querySelector('slot:not([name])');
    this.menuDefaultSlot?.addEventListener('slotchange', this.handleMenuDefaultSlotChange);
    this.handleMenuDefaultSlotChange();
  }

  protected removeMenuListener() {
    this.menuDefaultSlot?.removeEventListener('slotchange', this.handleMenuDefaultSlotChange);
  }

  // for notifier on slotted menu
  protected handleMenuDefaultSlotChange = async () => {
    await this.updateComplete;

    this.items = [...this.querySelectorAll('.he-menu-item')] as MenuItem[];

    this.items.forEach((item: MenuItem) => {
      item.addEventListener('keydown', this.boundHandleMenuItemKeyDown);
      item.submenuFixedPlacement = true;
    });
  };

  protected boundHandleKeyDown = this.handleKeyDown.bind(this);
  protected boundHandleMenuItemKeyDown = this.handleMenuItemKeyDown.bind(this);

  async connectedCallback() {
    super.connectedCallback();

    document.addEventListener('click', this.handlePageClick);
    this.addEventListener('he-change', this.handleDropdownChange);
    this.addEventListener('keydown', this.boundHandleKeyDown);

    await this.updateComplete;

    this.setTriggerAttribute(this.trigger!, 'expanded', this.open.toString());
  }

  protected handlePageClick = (e: Event) => {
    if (e.composedPath().includes(this) || !this.open) return;

    this.requestClose('document');
  };

  firstUpdated() {
    super.firstUpdated();
    this.attachMenuListener();
  }

  disconnectedCallback() {
    document.removeEventListener('click', this.handlePageClick);
    this.removeMenuListener();
    this.removeEventListener('he-change', this.handleDropdownChange);
    this.removeEventListener('keydown', this.boundHandleKeyDown);
    super.disconnectedCallback();
  }

  // override HarmonyDismissibleElement show()
  override async show(): Promise<void> {
    if (this.open) return;
    // timeout added to prevent close from external trigger
    setTimeout(() => (this.open = true));
    return waitForEvent(this, `he-${this.scopedEventName}-after-show`);
  }

  protected async handlePopupTransition(e: TransitionEvent) {
    await this.updateComplete;
    this.open ? this.emitAfterShow() : this.emitAfterHide();
  }

  protected get triggerIsDisabled(): boolean {
    if (this.trigger == null) return true;

    const trigger = this.trigger;

    return trigger.hasAttribute('disabled') || trigger.ariaDisabled === 'true';
  }

  protected handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape' && this.open) {
      this.requestClose('keyboard');
      e.stopPropagation();
    }

    if (!this.trigger || this.triggerIsDisabled) return;

    if (this.open) {
      // keyboard functions when dropdown is open
      switch (e.key) {
        case 'ArrowDown':
          if (e.target !== this.trigger) return true;
          e.preventDefault();

          // focus on first item
          this.menu?.focus();
          return false;

        case 'ArrowUp':
          if (e.target !== this.trigger) return true;
          e.preventDefault();

          // focus on last item
          this.menu?.handleMenuKeyDown(new KeyboardEvent('keydown', { key: 'End' }));
          return false;

        case 'Home':
        case 'End':
          if (e.target !== this.trigger) return true;
          e.preventDefault();

          // trigger key event in menu
          this.menu?.handleMenuKeyDown(new KeyboardEvent('keydown', { key: e.key }));
          return false;

        case 'Tab':
          if (e.shiftKey) {
            if (e.target === this.trigger && this.open) {
              e.preventDefault();
              this.requestClose('keyboard');
            }
            return true;
          }

          if (!this.menu) return;

          e.preventDefault();

          if (e.target === this.trigger) {
            // focus on first item
            this.menu?.focus();
          } else {
            // close menu and focus on trigger
            this.requestClose('keyboard');
            this.trigger.focus();
          }

          return false;

        default:
          // if we are not handling the event, do not prevent default
          return true;
      }
    } else {
      // keyboard functions when dropdown is closed
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp':
          if (!e.composedPath().includes(this.trigger)) return true;

          e.preventDefault();
          this.open = true;
          return false;

        default:
          return true;
      }
    }
  }

  protected handleDropdownChange = (e: Event) => {
    // close menu when menuitem is picked
    const role = (e.target as MenuItem)?.role;

    if (!role) return;

    if (role.startsWith('menuitem')) {
      this.requestClose('change');
    }
  };

  protected handleMenuItemKeyDown(e: KeyboardEvent): boolean {
    // focus back on trigger element if change is triggered through keyboard
    if ((e.key === 'Enter' || e.key === ' ') && !this.open) this.trigger?.focus();
    return true;
  }

  protected async requestClose(source: DropdownCloseSource) {
    const requestClose = this.emitRequestClose(source, this.menuWrapper);
    if (!requestClose) return false;

    this.trigger?.focus();
    return true;
  }

  protected handleClick = (e: MouseEvent) => {
    if (this.triggerIsDisabled || e.defaultPrevented) {
      return;
    }

    if (this.open === true) {
      this.requestClose('trigger');
      return;
    }

    this.open = true;
  };

  protected setTriggerAttribute(
    element: HTMLElement,
    attribute: 'expanded' | 'pressed' | 'label' | 'current' | 'haspopup' | 'controls',
    value: string
  ) {
    if (!element) return;

    const attr = element.classList.contains('he-focusable') ? attribute : `aria-${attribute}`;
    element.setAttribute(attr, value);
  }

  protected removeTriggerAttribute(
    element: HTMLElement,
    attribute: 'expanded' | 'pressed' | 'label' | 'current' | 'haspopup' | 'controls'
  ) {
    if (!element) return;

    const attr = element.classList.contains('he-focusable') ? attribute : `aria-${attribute}`;
    element.removeAttribute(attr);
  }

  protected handleFocusOut(e: FocusEvent) {
    if (!this.open || this.menu) return;

    setTimeout(() => {
      if (this.contains(this.findRootNode(this)?.activeElement)) return;
      this.trigger?.focus();
      this.hide();
    });
  }

  render() {
    return html`
      <div class=${classMap({
        dropdown: true,
        'dropdown--open': this.open,
      })}>
        <slot
          id="anchor" name="trigger"
          @slotchange=${this.handleTriggerSlotChange}
        >
        </slot>

        <${this.scope.tag('popup')}
          class="popup"
          part="popup"
          exportparts="
            base:popup,
            popup:popup__popup
          "
          placement=${this.position}
          strategy=${this.fixedPlacement ? 'fixed' : 'absolute'}
          shift
          shift-padding="4"
          auto-size="both"
          flip
          ?active=${this.open}
          .shiftBoundary=${this.viewportElement}
          .flipBoundary=${this.viewportElement}
          .autoSizeBoundary=${this.viewportElement}
          anchor="anchor"
        >
          ${this.content()}
        </${this.scope.tag('popup')}>
      </div>
    `;
  }

  protected content() {
    return html`
      <div
        @transitionend=${this.handlePopupTransition}
        @transitioncancel=${this.handlePopupTransition}
        class="menu-wrapper"
        part="menu-wrapper"
        @focusout=${this.handleFocusOut}
        aria-hidden=${this.open ? 'false' : 'true'}
      >
        <slot @slotchange=${this.handleDefaultSlotChange}></slot>
      </div>
    `;
  }

  isAlreadyCheckedMenuItem(target: EventTarget | null): boolean {
    return !!(
      this.open &&
      target &&
      (target as HTMLElement).getAttribute('role') === 'menuitemradio' &&
      (target as MenuItem)?.checked
    );
  }
}

export default Dropdown;
