import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    --checkbox-size: var(--he-form-control-toggle-size);

    display: inline-block;
  }

  .checkbox {
    display: inline-flex;
    align-items: top;
    font-family: var(--he-form-control-font-family);
    font-size: var(--he-form-control-font-size);
    font-weight: var(--he-font-weight-normal);
    color: var(--he-form-control-color);
    vertical-align: middle;
    cursor: pointer;
    position: relative;
  }

  .checkbox__control {
    flex: 0 0 auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: solid 1px var(--he-form-control-toggle-border-color);
    border-radius: 2px;
    background-color: var(--he-form-control-background-color);
    color: var(--he-color-neutral-0);
  }

  .checkbox__input {
    position: absolute;
    inset: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }

  .checkbox__control .checkbox__icon {
    display: inline-flex;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
  }

  /* Scale down the size of the check mark to match Fluent */
  .checkbox--checked .checkbox__icon {
    width: calc(var(--checkbox-size) * 0.8);
    height: calc(var(--checkbox-size) * 0.8);
  }

  .checkbox__control,
  .checkbox__control svg {
    width: var(--checkbox-size) !important;
    height: var(--checkbox-size) !important;
  }

  .checkbox__control-label-wrapper {
    display: inline-flex;
    align-items: flex-start;
  }

  /* Hover */
  .checkbox:not(.checkbox--checked):hover:not(.checkbox--disabled) .checkbox__control {
    background-color: var(--he-neutral-fill-stealth-hover);
  }

  /* Active */
  .checkbox:not(.checkbox--checked):active:not(.checkbox--disabled) .checkbox__control {
    background-color: var(--he-neutral-fill-stealth-active);
  }

  /* Focus */
  .checkbox .checkbox__input:focus-visible ~ .checkbox__control-label-wrapper {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 1px;
  }

  :host(:focus-visible) .checkbox__control-label-wrapper {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 1px;
  }

  :host(:focus-visible) {
    outline: none;
  }

  /* Checked */
  .checkbox--checked .checkbox__control {
    border-color: var(--he-accent-fill-rest);
    background-color: var(--he-accent-fill-rest);
  }

  /* Checked + hover */
  .checkbox.checkbox--checked:hover:not(.checkbox--disabled) .checkbox__control {
    border-color: var(--he-accent-fill-hover);
    background-color: var(--he-accent-fill-hover);
  }

  /* Checked + active */
  .checkbox.checkbox--checked:active:not(.checkbox--disabled) .checkbox__control {
    border-color: var(--he-accent-fill-active);
    background-color: var(--he-accent-fill-active);
  }

  /* Indeterminate */
  .checkbox--indeterminate .checkbox__control {
    border-color: var(--he-accent-fill-rest);
    color: var(--he-accent-fill-rest);
    background-color: var(--he-color-neutral-0);
  }

  /* Indeterminate + hover */
  .checkbox.checkbox--indeterminate:hover:not(.checkbox--disabled) .checkbox__control {
    border-color: var(--he-accent-fill-hover);
    color: var(--he-accent-fill-hover);
    background-color: var(--he-color-neutral-0);
  }

  /* Indeterminate + active */
  .checkbox.checkbox--indeterminate:active:not(.checkbox--disabled) .checkbox__control {
    border-color: var(--he-accent-fill-active);
    color: var(--he-accent-fill-active);
    background-color: var(--he-color-neutral-0);
  }

  /* Disabled */
  .checkbox--disabled {
    cursor: not-allowed;
  }

  .checkbox--disabled .checkbox__label {
    color: var(--he-type-disabled);
  }

  .checkbox--disabled .checkbox__control {
    border-color: var(--he-form-control-disabled-background);
  }

  .checkbox--disabled.checkbox--checked .checkbox__control {
    background-color: var(--he-form-control-disabled-background);
  }

  .checkbox--disabled.checkbox--indeterminate .checkbox__control {
    border-color: var(--he-form-control-disabled-background);
    color: var(--he-form-control-disabled-background);
  }

  /* Label */
  .checkbox__label {
    line-height: var(--checkbox-size);
  }

  /* Error states */
  .form-control--has-interaction.form-control--has-error .checkbox__control {
    border-color: var(--he-form-control-error-color) !important;
  }

  .form-control--has-interaction.form-control--has-error:not(.checkbox--disabled):focus-within .checkbox__control {
    outline: solid 1px var(--he-form-control-error-color) !important;
    outline-offset: -2px;
  }

  /* Checkbox group */
  :host(.he-checkbox-group__checkbox) {
    display: flex;
  }

  :host(.he-checkbox-group__checkbox:not(.he-checkbox-group__checkbox--last)) {
    margin-bottom: var(--he-spacing-x-small);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .checkbox__control,
    .checkbox:not(.checkbox--checked):hover:not(.checkbox--disabled) .checkbox__control {
      border-color: ${SystemColors.ButtonText};
      background: ${SystemColors.ButtonFace};
    }

    .checkbox__icon {
      fill: ${SystemColors.ButtonText};
    }

    /* Hover */
    .checkbox:hover:not(.checkbox--disabled) .checkbox__control {
      box-shadow: none;
      outline: ${SystemColors.ButtonText} solid 2px;
      outline-offset: -3px;
    }

    /* Checked */
    .checkbox--checked .checkbox__control,
    .checkbox.checkbox--checked:hover:not(.checkbox--disabled) .checkbox__control {
      border-color: ${SystemColors.HighlightText};
      background: ${SystemColors.Highlight};
    }

    .checkbox--checked .checkbox__icon {
      fill: ${SystemColors.HighlightText};
    }

    /* Indeterminate */
    .checkbox--indeterminate .checkbox__control,
    .checkbox.checkbox--indeterminate:hover:not(.checkbox--disabled) .checkbox__control {
      border-color: ${SystemColors.Highlight};
      background-color: ${SystemColors.HighlightText};
      color: ${SystemColors.Highlight};
    }

    /* Disabled */
    .checkbox--disabled {
      opacity: 1;
    }

    .checkbox--disabled .checkbox__control {
      border-color: ${SystemColors.GrayText};
      background-color: ${SystemColors.ButtonFace};
    }

    .checkbox--disabled .checkbox__label {
      color: ${SystemColors.GrayText};
    }

    .checkbox--disabled.checkbox--checked .checkbox__icon {
      fill: ${SystemColors.GrayText};
    }

    .checkbox--disabled.checkbox--indeterminate .checkbox__control {
      border-color: ${SystemColors.GrayText};
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.GrayText};
    }
  }
`;
