import { httpRequestRetryStore } from '../../services/http-request-store';
import { HttpFunctions } from '../../services/http-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { ConnectorPromiseStore } from '../../services/types/window-connectors';
import { trackError } from '../../services/telemetry-service/library-telemetry-service';
import { HarmonyConnectorError } from '../connector-error';
import { environmentConnector } from '../environment-connector/environment-connector';
import { PortalEnvironment } from '../../services/context/environment-helper';
import { PortalDomains } from './types/portal-domains';

let preFetchPromise: Promise<PortalDomains | undefined> | undefined = undefined;
const FailureMasterWorkspaceList = 'Failure while trying to retrieve portal domains';
const ErrorSource = 'PortalDomainsConnector';
const ErrorIdentifier = 'portal-domains-failure';

/**
 * @description It is the portal domain connector that is responsible for providing the list of domains per environment and portal.
 *
 * @since 0.0.0
 * @status experimental
 */
const CONFIG_ID = 'V1';

export const portalDomainsConnector = async (forceNew: boolean = false): Promise<PortalDomains | undefined> => {
  if (preFetchPromise && !forceNew) {
    return preFetchPromise;
  }

  const env = environmentConnector();
  const testSuffix = env !== PortalEnvironment.prod ? '-tst' : '';
  const domain = window.location.hostname.split('.')[0];

  // Add domain to the query to cache bust per portal
  const url = `https://partner-portalplatform.microsoft${testSuffix}.com/portal/portalDomains.json?portal=${domain}`;
  const connectorPromiseStoreConfig: ConnectorPromiseStore = {
    connectorType: 'portalDomainsConnector',
    uniqueId: url + CONFIG_ID,
  };
  try {
    const preFetchPromiseResponse = httpRequestRetryStore<PortalDomains>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin');
    if (preFetchPromiseResponse) {
      preFetchPromise = preFetchPromiseResponse as Promise<PortalDomains | undefined>;
    }
  } catch (error: any) {
    const message: string = error?.message ?? FailureMasterWorkspaceList;

    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: ErrorSource,
      statusCode: '500',
      errorObject: JSON.stringify(error) ?? '',
    };
    trackError(ErrorIdentifier, errorInformation);
    throw new HarmonyConnectorError(ErrorSource, FailureMasterWorkspaceList);
  }

  return preFetchPromise;
};
