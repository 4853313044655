import { ApplicationInsights, IMetricTelemetry } from '@microsoft/applicationinsights-web';

import { AiV1 } from './ai-v1-utils.js';
import { AiV2 } from './ai-v2-utils.js';

/* Disable logging within harmony hub (includes localhost) */
const DISABLED_IN_HUB = true;
const HARMONY_ENABLER_VERSION = '6.52.0';

export enum HeTelemetryType {
  error = 'error',
  clicks = 'clicks',
  userEvent = 'userEvent',
  performance = 'performance',
  enablerUsage = 'enablerUsage',
}

export interface HeTelemetryEvent {
  /*
    Name of the Harmony enabler, feature, connector, etc.. the event is originating from.
  */
  enabler: string;

  /*
   *  HeTelemetryType used for context and filtering
   */
  type: HeTelemetryType;

  /*
   *  Forward event to custom event handler registered with with HeTelemetry.
   *  If name this string is present HeTelemetry will attempt to call the custom handler regardless of context and then disregard the event.
   */
  customEventHandler?: string;

  /*
   *  If provided HeTelemetry will attempt to use the provided App Insights key for logging regardless of context
   */
  aiInstrumentationKey?: string;

  /**
   * The primary description of the event
   */
  message?: string;

  /**
   * Metric name useful for lookup and reporting in app insights logs.
   */
  metricName?: string;

  /**
   * Captured exception to log in an error case
   */
  error?: Error;

  /**
   * Specifies severity of the exception in an error case
   */
  severity?: number;

  /**
   * Property bag to contain additional custom properties you may wish to capture
   */
  customProperties?: { [key: string]: number };

  /**
   * Performance metrics property bag used for data aggregation in app insights on performance tracking events
   */
  performanceMetrics?: IMetricTelemetry;
}

const IsEventInContext = (eventType: HeTelemetryType, context: HeTelemetryType[]) => !!context.find(x => x);

export default class HeTelemetry {
  private static disabled: boolean = false;
  private static handlerMap = new Map();
  private static aiInstanceMap = new Map();
  private static instance: HeTelemetry;
  private static context: HeTelemetryType[] = [];
  private static verboseMode: boolean = false;
  private static consoleEnabled: boolean = false;
  private static AiVersion: number = 0;
  private static AiUtils = AiV1;
  private static eventListenerTypes = {
    internal: 'he-telemetry-internal',
    public: 'he-telemetry',
  };

  private static appInsights: ApplicationInsights;

  private static setAiVersion() {
    if (window.Microsoft?.ApplicationInsights) {
      const version = window.Microsoft.ApplicationInsights?.Version;
      if (!!version && parseInt(version) < 2) {
        this.AiVersion = 1;
        this.AiUtils = AiV1;
      } else {
        this.AiVersion = 2;
        this.AiUtils = AiV2;
      }
    } else {
      //We could not detect AI on window assume v1 functions but leave version number 0 to retry detection.
      this.AiVersion = 0;
      this.AiUtils = AiV1;
    }
  }

  /* Returns the instance of HeTelemetry */
  public static getInstance(): HeTelemetry {
    if (!HeTelemetry.instance) {
      this.instance = new HeTelemetry();
      this.instance.setEventListener();
      this.setAiVersion();
    }

    if (!window.HarmonyEnablers) {
      window.HarmonyEnablers = {
        version: HARMONY_ENABLER_VERSION,
        telemetry: this.instance,
      };
    } else if (!window.HarmonyEnablers?.telemetry) {
      window.HarmonyEnablers.telemetry = this.instance;
      window.HarmonyEnablers.version = HARMONY_ENABLER_VERSION;
    }

    return this.instance;
  }

  /*
   * Registers a custom event handler to be called when event.detail.customEventHandler contains the name matching your handler
   * Duplicate names will not be registered
   * Returns true for success else false
   */
  public registerCustomHandler(name: string, handler: (event: CustomEvent) => void): boolean {
    if (HeTelemetry.disabled) {
      HeTelemetry.consoleEnabled && console.log(`Telemetry is currently disabled in harmony hub.`);
      return false;
    }

    if (!!name && !!handler) {
      if (!HeTelemetry.handlerMap.get(name)) {
        HeTelemetry.handlerMap.set(name, handler);
        return true;
      }
    }
    return false;
  }

  /* This assumes App Insights has already been loaded and is available on window
   * Registers and instantiates an instance of App Insights for the provided key
   * Config is not required but recommended. If no config is passed a default config will be used.
   * Any events captured with an aiInstrumentationKey matching this instance will be logged to this instance.
   * Duplicate instrumentation keys will not be registered.
   * Returns true for success else false
   */

  public registerAiInstance(instrumentationKey: string, config?: any): boolean {
    if (HeTelemetry.disabled) {
      HeTelemetry.consoleEnabled && console.log(`Telemetry is currently disabled in harmony hub.`);
      return false;
    }
    //Always set version before attempting register.
    HeTelemetry.setAiVersion();

    if (!HeTelemetry.aiInstanceMap.get(instrumentationKey)) {
      if (window.Microsoft?.ApplicationInsights) {
        const aiConfig = config ? { config: { ...config, instrumentationKey } } : { config: { instrumentationKey } };
        try {
          const aiInstance = HeTelemetry.AiUtils.registerAiInstance(instrumentationKey, aiConfig);

          if (!!aiInstance) {
            HeTelemetry.aiInstanceMap.set(instrumentationKey, aiInstance);
            HeTelemetry.consoleEnabled && console.log('custom ai instance registered');
            return true;
          }
          throw new Error('Registration of secondary AppInsights failed');
        } catch (err) {
          HeTelemetry.consoleEnabled && console.warn(`Registration of secondary AppInsights failed.`);
          HeTelemetry.AiUtils.trackError(
            new CustomEvent('he-telemetry-internal', {
              bubbles: true,
              composed: true,
              detail: {
                error: err,
                severity: 2,
                href: window.location.href,
                pathName: window.location.pathname,
                instrumentationKey,
                enabler: 'HeTelemetry',
                type: HeTelemetryType.error,
                message: 'Error registering custom instance of AppInsights',
              },
            }),
            HeTelemetry.appInsights,
            HARMONY_ENABLER_VERSION
          );

          HeTelemetry.consoleEnabled && console.warn('AppInsights bundle not detected');
          return false;
        }
      }
    }
    return false;
  }

  private getAiInstanceForEvent(event: CustomEvent): any {
    const customAiKey = event?.detail?.aiInstrumentationKey;

    if (!!customAiKey) {
      const customInstance = HeTelemetry.aiInstanceMap.get(customAiKey);
      if (customInstance) {
        HeTelemetry.consoleEnabled && console.info('Custom ai instance retrieved');
        return customInstance;
      } else {
        const register = this.registerAiInstance(customAiKey);
        if (register) {
          return HeTelemetry.aiInstanceMap.get(customAiKey);
        } else {
          return undefined;
        }
      }
    }
    const aiInstance = HeTelemetry.appInsights;
    if (!aiInstance) {
      HeTelemetry.consoleEnabled && console.warn(`AI Instance not found unable to log telemetry.`);
    }
    return aiInstance;
  }

  private trackEvent(event: CustomEvent) {
    const aiInstance = this.getAiInstanceForEvent(event);

    if (aiInstance) {
      HeTelemetry.AiUtils.trackEvent(event, aiInstance, HARMONY_ENABLER_VERSION);
    }
  }

  private trackError(event: CustomEvent) {
    const aiInstance = this.getAiInstanceForEvent(event);
    if (aiInstance) {
      HeTelemetry.AiUtils.trackError(event, aiInstance, HARMONY_ENABLER_VERSION);
    }
  }

  private trackPerformance(event: CustomEvent) {
    if (!event.detail?.performanceMetrics) {
      this.trackEvent(event);
      return;
    }

    const aiInstance = this.getAiInstanceForEvent(event);
    if (aiInstance) {
      HeTelemetry.AiUtils.trackPerformance(event, aiInstance, HARMONY_ENABLER_VERSION);
    }
  }

  /* Set the telemetry types to listen for. Note: heInternal is always on */
  public setTelemetryContext(context: HeTelemetryType[]) {
    HeTelemetry.context = context;
  }

  /** Returns current telemetry context */
  public getTelemetryContext() {
    return HeTelemetry.context;
  }

  /* Listen for all telemetry types ignores current context.*/
  public setVerbose(enabled: boolean) {
    HeTelemetry.verboseMode = enabled;
  }

  /* Log to browser console */
  public enableConsoleLog(enabled: boolean) {
    console.log(
      `Console telemetry enabled: ${enabled}, version: ${HARMONY_ENABLER_VERSION}, Handler Status: ${
        HeTelemetry.disabled ? 'disabled' : 'enabled'
      }`
    );

    HeTelemetry.consoleEnabled = enabled;
  }

  private handleTelemetry(event: CustomEvent) {
    if (HeTelemetry.disabled) {
      return;
    }

    if (!!event.detail?.customEventHandler && event?.type === HeTelemetry.eventListenerTypes.public) {
      const customHandler = HeTelemetry.handlerMap.get(event.detail.customEventHandler);
      !!customHandler && customHandler(event);

      HeTelemetry.consoleEnabled &&
        console.info(`Event forwarded to custom handler: ${event.detail.customEventHandler}, ${event}`);

      return;
    }

    //If AiVersion is not set
    if (HeTelemetry.AiVersion === 0) {
      HeTelemetry.setAiVersion();
    }

    if (
      HeTelemetry.verboseMode ||
      event?.type === HeTelemetry.eventListenerTypes.internal ||
      IsEventInContext(event?.detail?.type, HeTelemetry.context) ||
      !!event?.detail?.aiInstrumentationKey
    ) {
      HeTelemetry.consoleEnabled && console.info(event);

      if (!HeTelemetry.appInsights && window.frontDoorAppInsights) {
        HeTelemetry.appInsights = window.frontDoorAppInsights;
      }

      const instance = HeTelemetry.getInstance();

      switch (event.detail.type) {
        case HeTelemetryType.error:
          instance.trackError(event);
          break;
        case HeTelemetryType.performance:
          instance.trackPerformance(event);
          break;
        default:
          instance.trackEvent(event);
          break;
      }
    }
  }

  private setEventListener() {
    document.addEventListener(HeTelemetry.eventListenerTypes.internal, this.handleTelemetry);
    document.addEventListener(HeTelemetry.eventListenerTypes.public, this.handleTelemetry);
  }

  private constructor() {
    if (DISABLED_IN_HUB) {
      const origin = window.location.origin.toLowerCase();
      if (origin.includes('harmonyhub.azurewebsites.net') || origin.includes('localhost')) {
        HeTelemetry.disabled = true;
      }
    }

    if (!HeTelemetry.appInsights && window.frontDoorAppInsights) {
      HeTelemetry.appInsights = window.frontDoorAppInsights;
    }
  }
}
