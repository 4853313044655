import { css } from 'lit';
import { litElevation as elevation } from '../../internal/styles/elevation.js';

export default css`
  :host {
    --he-elevation: 64; /* only used in mobile view */
    --he-background-luminance: 0.2;
    --min-width: 280px;
    --transition-speed: 0.33s;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    font-size: 0;
  }

  :host([view='mobile']) {
    flex-grow: 0;
    min-width: var(--he-form-control-height);
    min-height: var(--he-form-control-height);
  }

  .positioning-region {
    display: flex;
    min-width: var(--min-width);
    width: 100%;
    height: 100%;
  }

  :host([view='mobile']) > .positioning-region {
    position: absolute;
    width: auto;
    transition: transform var(--transition-speed) ease-out, box-shadow var(--transition-speed) ease-out;
    transform: translateX(0deg);
  }

  :host([view='mobile'][expanded]) > .positioning-region {
    ${elevation};
  }

  .task-menu {
    order: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--he-color-body-background);
    overflow-y: auto;
  }

  /* prevent items from being keyboard focusable when closed */
  :host([view='mobile']:not([expanded])) .task-menu {
    visibility: hidden;
    transition: visibility var(--transition-speed);
  }

  .task-items {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .expand-collapse-button-container {
    background-color: var(--he-color-body-background);
  }

  .expand-collapse-button {
    order: 1;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    min-width: var(--he-form-control-height);
    height: 100%;
    border-radius: 0;
    border: none;
    outline: none;
    margin: 0;
    padding: var(--he-spacing-x-small) 0;
    background: transparent;
    border-inline-end: 1px var(--he-card-border-style) transparent; /* he-card-border-style applies border only in dark mode, since shadows aren't visible */
    cursor: pointer;
  }

  .expand-collapse-button:is(:hover, :focus) {
    background-color: var(--he-neutral-fill-stealth-hover);
  }

  .expand-collapse-button:active {
    background-color: var(--he-neutral-fill-stealth-active);
  }

  :host([expanded]) .expand-collapse-button,
  :host(.he-task-menu-animation) .expand-collapse-button {
    border-inline-end-color: var(--he-color-body-divider);
  }

  .expand-collapse-button:focus-visible {
    box-shadow: inset 0 0 0 1px var(--he-focus-ring-color);
  }

  .expand-collapse-glyph {
    transition: transform var(--transition-speed) ease;
    width: var(--he-icon-size);
    height: var(--he-icon-size);
    fill: var(--he-neutral-foreground);
    pointer-events: none;
  }

  .has-header .header,
  .has-footer .footer {
    font-size: var(--he-font-size-medium);
    padding: var(--he-spacing-x-small) var(--he-spacing-medium);
  }

  :host([view='mobile']) .positioning-region .expand-collapse-button {
    border-inline-start: var(--he-form-control-border-width) solid transparent;
  }

  :host([view='mobile'][expanded]) .positioning-region .expand-collapse-button {
    border-inline-start-color: var(--he-color-body-divider);
  }

  /* ltr */
  :host([view='mobile']) > .positioning-region--ltr {
    right: 0;
  }

  :host([view='mobile'][expanded]) > .positioning-region--ltr {
    transform: translateX(calc(100% - var(--he-form-control-height)));
  }

  .positioning-region--ltr .expand-collapse-glyph {
    transform: rotate(0deg);
  }

  :host([view='mobile'][expanded]) > .positioning-region--ltr .expand-collapse-glyph {
    transform: rotate(180deg);
  }

  /* rtl */
  :host([view='mobile']) > .positioning-region--rtl {
    left: 0;
  }

  :host([view='mobile'][expanded]) > .positioning-region--rtl {
    transform: translateX(calc(-100% + var(--he-form-control-height)));
  }

  .positioning-region--rtl .expand-collapse-glyph {
    transform: rotate(180deg);
  }

  :host([view='mobile'][expanded]) > .positioning-region--rtl .expand-collapse-glyph {
    transform: rotate(0deg);
  }
`;
