import { html } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { TaskItem } from '../task-item/task-item.js';
import HarmonyElement from '../../base-components/base.js';
import { HasSlotController } from '../../internal/slot.js';
import { watch } from '../../internal/watch.js';
import { scrollbarStyles } from '../../internal/styles/scrollbar.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './task-menu.styles.js';

// unique id for task-items container, set on taskItemsId
let id = 0;

export type TaskMenuItemType = 'none' | 'page' | 'step' | 'location' | 'date' | 'time';
export interface TaskMenuExpandedChangeEvent {
  expanded: boolean;
}

export interface TaskMenuViewChangeEvent {
  view: boolean;
}

/**
 *
 * Used to present a flat or hierarchical list of Task Items. If the list is nested, the parent task item will display as a heading, and will not be selectable or clickable.
 *
 * @tag he-task-menu
 * @since 1.0
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=199%3A22065&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @slot - Supports he-task-item elements.
 * @slot header - Optional slot to place content at the very top of the task menu.
 * @slot footer - Optional slot to place content at the very bottom of the task menu.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event {TaskMenuExpandedChangeEvent} he-expanded-change - Emitted when the mobile menu's expanded state changes.
 * @event {TaskMenuViewChangeEvent} he-view-change - Emitted when the menu's view mode changes.
 * @event he-selected-change - Emitted when an item has been selected. (Bubbled from Task Item children)
 *
 * @csspart positioning-region - The wrapper of the task menu used for positioning.
 * @csspart task-menu - Contains the header slot, footer slot, and task items container.
 * @csspart task-items - The container for the default slot, contains task items.
 * @csspart expand-collapse-button - The button for expanding and collapsing the mobile menu.
 *
 * @cssproperty [--min-width=280px] - Minimum width of task menu.
 * @cssproperty [--he-elevation=64] - Elevation of the task menu when in mobile view.
 */
@Component('task-menu')
export class TaskMenu extends HarmonyElement {
  static styles = [scrollbarStyles, componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeExpandedChange: new CustomEvent<TaskMenuExpandedChangeEvent>('he-expanded-change'),
    onHeViewChange: new CustomEvent<TaskMenuViewChangeEvent>('he-view-change'),
    onHeSelectedChange: new CustomEvent('he-selected-change'),
  };

  private mediaQueryList: MediaQueryList;
  private readonly hasSlotController = new HasSlotController(this, 'header', 'footer');

  /**
   * The type of list items - this will be the value of aria-current on selected items. A value of 'none' should be used
   * if no other options describe it's use, and will result in `aria-current="true"`.
   *
   * See [aria-current](https://www.w3.org/TR/wai-aria-1.1/#aria-current) for more information.
   */
  @property({ reflect: true, attribute: 'item-type' })
  public itemType: TaskMenuItemType = 'page';

  /** @internal watcher */
  @watch('itemType')
  handleItemTypeChanged() {
    this.querySelectorAll('.he-task-item').forEach((taskItem: TaskItem) => (taskItem.itemType = this.itemType));
  }

  /**
   * Whether or not the task menu will collapse to mobile view automatically based on `breakpoint` screen width.
   */
  @property({ reflect: true, attribute: 'auto-collapse', type: Boolean }) public autoCollapse: boolean = false;

  /** @internal watcher */
  @watch('autoCollapse')
  handleAutoCollapseChanged(): void {
    this.autoCollapse ? this.addMqlEventListener() : this.removeMqlEventListener();
  }

  /**
   * The max-width of the screen that will show the collapsed mobile view of task menu. Must include CSS unit that is
   * valid in media queries (ie. `768px`)
   *
   * See [media query units](https://drafts.csswg.org/mediaqueries/#units) for more information.
   */
  @property() public breakpoint: string = '768px';

  /** @internal watcher */
  @watch('breakpoint')
  handleBreakpointChanged(): void {
    if (this.autoCollapse) {
      // remove event listener and add new one with new breakpoint
      this.removeMqlEventListener();
      this.addMqlEventListener();
    }
  }

  /**
   * Default or mobile view. If `autoCollapse` is set, this will change based on viewport width changes. To use mobile
   * view outside of `he-page-template`, make sure the element that is wrapping the task menu and content has
   * `overflow: hidden`.
   *
   * @public
   */
  @property({ reflect: true }) public view: 'default' | 'mobile' = 'default';

  @watch('view')
  handleViewChanged(): void {
    this.emit('he-view-change', { detail: { view: this.view } });
  }

  /**
   * The expanded state of the task menu in mobile view.
   */
  @property({ reflect: true, type: Boolean }) expanded: boolean = false;

  /** @internal watcher */
  @watch('expanded')
  expandedChanged(): void {
    this.emit('he-expanded-change', { detail: { expanded: this.expanded } });

    if (this.expanded) {
      setTimeout(() => this.focusFirstTaskItem());
    }
  }

  /** The current selected task-item */
  @state() private currentSelected: HTMLElement | TaskItem | null;

  /** The unique id of task-items. */
  private taskItemsId: string = `he-taskitems-${++id}`;

  /** The list of task items currently inside the task menu. */
  @state()
  private taskItems: TaskItem[] = [];

  @queryAssignedElements({ selector: '.he-task-item', flatten: true })
  @state()
  private slottedTaskItems: TaskItem[];

  private handleSlottedTaskItemsChanged(e: Event): void {
    this.taskItems = this.getTaskItems(this.slottedTaskItems);
    this.setItems();
  }

  private setItems() {
    const selectedItems = [...this.querySelectorAll<TaskItem>('[selected]')];

    if (selectedItems.length >= 0) {
      this.currentSelected = selectedItems[0];
      selectedItems.slice(1).forEach(el => (el.selected = false));
    }
  }

  private focusFirstTaskItem() {
    const firstTaskItem = this.taskItems.filter(item => item.disabled !== true)[0];
    const firstChildTaskItem = firstTaskItem.querySelector<TaskItem>('.he-task-item');

    (firstChildTaskItem || firstTaskItem)?.focus();
  }

  connectedCallback(): void {
    super.connectedCallback();
    if (this.autoCollapse) this.addMqlEventListener();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    if (this.autoCollapse) this.removeMqlEventListener();
  }

  protected handleExpandCollapseButtonClick = (_e: MouseEvent): void => {
    this.expanded = !this.expanded;
  };

  private handleMqlChange = (e: MediaQueryListEvent | MediaQueryList): void => {
    this.view = e.matches && this.autoCollapse && this.breakpoint ? 'mobile' : 'default';
  };

  private addMqlEventListener() {
    this.mediaQueryList = window.matchMedia(`(max-width: ${this.breakpoint})`);
    this.mediaQueryList.addEventListener('change', this.handleMqlChange);
    this.handleMqlChange(this.mediaQueryList);
  }

  private removeMqlEventListener() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeEventListener('change', this.handleMqlChange);
      this.handleMqlChange(this.mediaQueryList);
    }
  }

  private handleItemSelected = (e: CustomEvent): void => {
    if (e.defaultPrevented) return;
    const newSelection: HTMLElement = e.target as HTMLElement;
    if (newSelection !== this.currentSelected) {
      if (this.currentSelected != null) {
        (this.currentSelected as TaskItem).selected = false;
      }
      this.currentSelected = newSelection;
    }
  };

  /** Get all the task items, including nested ones. */
  private getTaskItems(elements: TaskItem[]): TaskItem[] {
    const taskItems: TaskItem[] = [];
    elements.forEach(item => {
      taskItems.push(item);

      // add nested items to array
      const items = [...item.querySelectorAll<TaskItem>('.he-task-item')];
      taskItems.push(...items);
    });

    return taskItems;
  }

  render() {
    const hasHeader = this.hasSlotController.test('header');
    const hasFooter = this.hasSlotController.test('footer');

    return html`
      <div
        class=${classMap({
          'positioning-region': true,
          [`positioning-region--${this.dir}`]: true,
          mobile: this.view === 'mobile',
        })}
        part="positioning-region"
      >
        <div
          part="task-menu"
          class=${classMap({
            'task-menu': true,
            'has-header': hasHeader,
            'has-footer': hasFooter,
          })}
        >
          <div class="header" part="header">
            <slot name="header"></slot>
          </div>

          <div
            class="task-items"
            part="task-items"
            role="list"
            id=${this.taskItemsId}
            @he-selected-change=${this.handleItemSelected}
          >
            <slot @slotchange=${this.handleSlottedTaskItemsChanged}></slot>
          </div>

          <div class="footer" part="footer">
            <slot name="footer"></slot>
          </div>
        </div>
        ${when(
          this.view === 'mobile',
          () => html`
            <div class="expand-collapse-button-container">
              <button
                aria-expanded=${this.expanded}
                aria-controls=${this.taskItemsId}
                aria-label=${this.expanded
                  ? this.localize.term('collapse_task_menu')
                  : this.localize.term('expand_task_menu')}
                class="expand-collapse-button"
                part="expand-collapse-button"
                @click=${this.handleExpandCollapseButtonClick}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                  class="expand-collapse-glyph"
                >
                  <path
                    d="M0 0.533203L5.4668 6L0 11.4668L0.533203 12L6.5332 6L0.533203 0L0 0.533203ZM5.25 0.533203L10.7168 6L5.25 11.4668L5.7832 12L11.7832 6L5.7832 0L5.25 0.533203Z"
                  />
                </svg>
              </button>
            </div>
          `
        )}
      </div>
    `;
  }
}

export default TaskMenu;
