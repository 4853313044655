import {
  arrow,
  autoUpdate,
  computePosition,
  ComputePositionReturn,
  ElementRects,
  flip,
  limitShift,
  Middleware,
  offset,
  platform,
  shift,
  size,
} from '@floating-ui/dom';

// Why is this here? Glad you asked.
// TLDR: `offsetParent` does not work in shadow dom.
// https://github.com/floating-ui/floating-ui/pull/2160
// https://github.com/floating-ui/floating-ui/releases/tag/%40floating-ui%2Fdom%401.0.2
// https://github.com/shoelace-style/shoelace/issues/1135
import { offsetParent } from 'composed-offset-position';

import { html } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import HarmonyElement from '../../base-components/base.js';
import { watch } from '../../internal/watch.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './popup.styles.js';

import type { CSSResultGroup } from 'lit';

export type PopupPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export interface PopupRepositionEvent {
  placement: PopupPlacement;
}

/**
 *
 * Popup is a utility that lets you declaratively anchor "popup" containers to another element.
 *
 * @tag he-popup
 * @since 6.0
 * @status stable
 * @design n/a
 * @figma n/a
 *
 * @event {PopupRepositionEvent} he-reposition - Emitted when the popup is repositioned. This event can fire a lot, so avoid putting expensive
 *  operations in your listener or consider debouncing it.
 *
 * @slot - The popup's content.
 * @slot anchor - The element the popup will be anchored to.
 *
 * @csspart arrow - The arrow's container. Avoid setting `top|bottom|left|right` properties, as these values are
 *  assigned dynamically as the popup moves. This is most useful for applying a background color to match the popup, and
 *  maybe a border or box shadow.
 * @csspart popup - The popup's container. Useful for setting a background color, box shadow, etc.
 *
 * @cssproperty [--arrow-size=6px] - The size of the arrow. Note that an arrow won't be shown unless the `arrow`
 *  attribute is used.
 * @cssproperty [--arrow-color=var(--he-color-neutral-0)] - The color of the arrow.
 * @cssproperty [--auto-size-available-width] - A read-only custom property that determines the amount of width the
 *  popup can be before overflowing. Useful for positioning child elements that need to overflow. This property is only
 *  available when using `auto-size`.
 * @cssproperty [--auto-size-available-height] - A read-only custom property that determines the amount of height the
 *  popup can be before overflowing. Useful for positioning child elements that need to overflow. This property is only
 *  available when using `auto-size`.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('popup')
export class Popup extends HarmonyElement {
  static styles: CSSResultGroup = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeReposition: new CustomEvent('he-reposition'),
  };

  /** A reference to the internal popup container. Useful for animating and styling the popup with JavaScript. */
  @query('.popup')
  public popup: HTMLElement;

  /** A reference to the internal arrow element. Useful for animating and styling the popup with JavaScript. */
  @query('.popup__arrow')
  public arrowEl: HTMLElement;

  private anchorEl: HTMLElement | null;
  private cleanup: ReturnType<typeof autoUpdate> | undefined;

  /**
   * The element the popup will be anchored to. If the anchor lives outside of the popup, you can provide its `id` or a
   * reference to it here. If the anchor lives inside the popup, use the `anchor` slot instead.
   */
  @property() anchor: Element | string;

  /**
   * Activates the positioning logic and shows the popup. When this attribute is removed, the positioning logic is torn
   * down and the popup will be hidden.
   */
  @property({ type: Boolean, reflect: true }) active = false;

  /**
   * The preferred placement of the popup. Note that the actual placement will vary as configured to keep the
   * panel inside of the viewport.
   */
  @property({ reflect: true }) placement: PopupPlacement = 'top';

  /**
   * Determines how the popup is positioned. The `absolute` strategy works well in most cases, but if
   * overflow is clipped, using a `fixed` position strategy can often workaround it.
   */
  @property({ reflect: true }) strategy: 'absolute' | 'fixed' = 'absolute';

  /** The distance in pixels from which to offset the panel away from its anchor. */
  @property({ type: Number }) distance: string | number = 0;

  /** The distance in pixels from which to offset the panel along its anchor. */
  @property({ type: Number }) skidding: string | number = 0;

  /**
   * Attaches an arrow to the popup. The arrow's size and color can be customized using the `--arrow-size` and
   * `--arrow-color` custom properties. For additional customizations, you can also target the arrow using
   * `::part(arrow)` in your stylesheet.
   */
  @property({ type: Boolean, reflect: true }) arrow = false;

  /**
   * The placement of the arrow. The default is `anchor`, which will align the arrow as close to the center of the
   * anchor as possible, considering available space and `arrow-padding`. A value of `start`, `end`, or `center` will
   * align the arrow to the start, end, or center of the popover instead.
   */
  @property({ attribute: 'arrow-placement' }) arrowPlacement: 'start' | 'end' | 'center' | 'anchor' = 'anchor';

  /**
   * The amount of padding between the arrow and the edges of the popup. If the popup has a border-radius, for example,
   * this will prevent it from overflowing the corners.
   */
  @property({ attribute: 'arrow-padding', type: Number }) arrowPadding = 10;

  /**
   * When set, placement of the popup will flip to the opposite site to keep it in view. You can use
   * `flipFallbackPlacements` to further configure how the fallback placement is determined.
   */
  @property({ type: Boolean, reflect: true }) flip = false;

  /**
   * If the preferred placement doesn't fit, popup will be tested in these fallback placements until one fits. Must be a
   * string of any number of placements separated by a space, e.g. "top bottom left". If no placement fits, the flip
   * fallback strategy will be used instead.
   * */
  @property({
    attribute: 'flip-fallback-placements',
    converter: {
      fromAttribute: (value: string) => {
        return value
          .split(' ')
          .map(p => p.trim())
          .filter(p => p !== '');
      },
      toAttribute: (value: []) => {
        return value.join(' ');
      },
    },
  })
  flipFallbackPlacements = '';

  /**
   * When neither the preferred placement nor the fallback placements fit, this value will be used to determine whether
   * the popup should be positioned as it was initially preferred or using the best available fit based on available
   * space.
   */
  @property({ attribute: 'flip-fallback-strategy' })
  flipFallbackStrategy: 'best-fit' | 'initial' = 'initial';

  /**
   * The flip boundary describes clipping element(s) that overflow will be checked relative to when flipping. By
   * default, the boundary includes overflow ancestors that will cause the element to be clipped. If needed, you can
   * change the boundary by passing a reference to one or more elements to this property.
   */
  @property({ type: Object })
  flipBoundary?: Element | Element[];

  /** The amount of padding, in pixels, to exceed before the flip behavior will occur. */
  @property({ attribute: 'flip-padding', type: Number })
  flipPadding = 0;

  /** Moves the popup along the axis to keep it in view when clipped. */
  @property({ type: Boolean, reflect: true })
  shift = false;

  /**
   * The shift boundary describes clipping element(s) that overflow will be checked relative to when shifting. By
   * default, the boundary includes overflow ancestors that will cause the element to be clipped. If needed, you can
   * change the boundary by passing a reference to one or more elements to this property.
   */
  @property({ type: Object })
  shiftBoundary?: Element | Element[];

  /** The amount of padding, in pixels, to exceed before the shift behavior will occur. */
  @property({ attribute: 'shift-padding', type: Number })
  shiftPadding = 0;

  /** When set, this will cause the popup to automatically resize itself to prevent it from overflowing. */
  @property({ attribute: 'auto-size' })
  autoSize?: 'horizontal' | 'vertical' | 'both';

  /** Syncs the popup's width or height to that of the anchor element. */
  @property()
  sync: 'width' | 'height' | 'both';

  /**
   * The auto-size boundary describes clipping element(s) that overflow will be checked relative to when resizing. By
   * default, the boundary includes overflow ancestors that will cause the element to be clipped. If needed, you can
   * change the boundary by passing a reference to one or more elements to this property.
   */
  @property({ type: Object })
  autoSizeBoundary?: Element | Element[];

  /** The amount of padding, in pixels, to exceed before the auto-size behavior will occur. */
  @property({ attribute: 'auto-size-padding', type: Number })
  autoSizePadding = 0;

  protected firstUpdated() {
    super.firstUpdated();
    this.start();
  }

  disconnectedCallback() {
    this.stop();
  }

  /** @internal watcher */
  @watch('active')
  async handleActiveChange() {
    if (this.active) {
      await this.reposition();
    }
  }

  private async handleAnchorChange() {
    await this.stop();
    this.setAnchorElement();
    this.start();
  }

  private setAnchorElement() {
    if (this.anchor && typeof this.anchor === 'string') {
      // Locate the anchor by id
      this.anchorEl = this.findRootNode(this).getElementById(this.anchor);
    } else if (this.anchor instanceof HTMLElement) {
      // Use the anchor's reference
      this.anchorEl = this.anchor;
    } else {
      // Look for a slotted anchor
      this.anchorEl = this.querySelector<HTMLElement>('[slot="anchor"]');
    }

    // If the anchor is a <slot>, we'll use the first assigned element as the target since slots use `display: contents`
    // and positioning can't be calculated on them
    if (this.anchorEl instanceof HTMLSlotElement) {
      this.anchorEl = this.anchorEl.assignedElements({ flatten: true })[0] as HTMLElement;
    }

    if (!this.anchorEl) {
      throw new Error(
        'Invalid anchor element: no anchor could be found using the anchor slot or the anchor attribute.'
      );
    }
  }

  private start() {
    // We can't start the positioner without an anchor
    if (!this.anchorEl || !this.popup) {
      return;
    }

    this.cleanup = autoUpdate(this.anchorEl, this.popup, async () => {
      await this.reposition();
    });
  }

  private async stop(): Promise<void> {
    return new Promise(resolve => {
      if (this.cleanup) {
        this.cleanup();
        this.cleanup = undefined;
        this.removeAttribute('data-current-placement');
        this.style.removeProperty('--auto-size-available-width');
        this.style.removeProperty('--auto-size-available-height');
        requestAnimationFrame(() => resolve());
      } else {
        resolve();
      }
    });
  }

  async updated(changedProps: Map<string, unknown>) {
    super.updated(changedProps);

    // Start or stop the positioner when active changes
    if (changedProps.has('active')) {
      if (this.active) {
        this.start();
      } else {
        this.stop();
      }
    }

    // Update the anchor when anchor changes
    if (changedProps.has('anchor')) {
      this.handleAnchorChange();
    }

    // All other properties will trigger a reposition when active
    await this.reposition();
  }

  /** Recalculate and repositions the popup. */
  public async reposition() {
    // Nothing to do if the popup is inactive or the anchor doesn't exist
    if (!this.active || !this.anchorEl || !this.popup) {
      return;
    }

    let floatingUiPlatform = platform;

    // Our polyfill for "offsetParent" only works for absolute positioning.
    if (this.strategy === 'absolute') {
      floatingUiPlatform = {
        ...platform,
        getOffsetParent: (element: HTMLElement) => element.offsetParent || offsetParent(element) || window,
      };
    }

    await this.updateComplete;
    const middleware = this.configureMiddleware();
    const position = await computePosition(this.anchorEl, this.popup, {
      placement: this.placement,
      middleware,
      strategy: this.strategy,
      platform: floatingUiPlatform,
    });

    this.setAttribute('data-current-placement', position.placement);
    this.setPopupPosition(position.x, position.y);
    this.setArrowPosition(position);
    this.emit('he-reposition', { detail: { placement: position.placement } });
  }

  private configureMiddleware(): Middleware[] {
    //
    // NOTE: Floating UI middleware is order dependent: https://floating-ui.com/docs/middleware
    //
    const middleware = [
      // The offset middleware goes first
      offset({ mainAxis: Number(this.distance), crossAxis: Number(this.skidding) }),
    ];

    this.configureSyncSize(middleware);
    this.configureFlip(middleware);
    this.configureShift(middleware);
    this.configureAutoSize(middleware);
    this.configureArrow(middleware);

    return middleware;
  }

  private configureSyncSize(middleware: Middleware[]) {
    if (this.sync) {
      middleware.push(
        size({
          apply: ({ rects }: { rects: ElementRects }) => {
            const syncWidth = this.sync === 'width' || this.sync === 'both';
            const syncHeight = this.sync === 'height' || this.sync === 'both';
            this.popup.style.width = syncWidth ? `${rects.reference.width}px` : '';
            this.popup.style.height = syncHeight ? `${rects.reference.height}px` : '';
          },
        })
      );
    } else {
      // Cleanup styles if we're not matching width/height
      this.popup.style.width = '';
      this.popup.style.height = '';
    }
  }

  private configureFlip(middleware: Middleware[]) {
    if (!this.flip) {
      return;
    }

    middleware.push(
      flip({
        boundary: this.flipBoundary,
        // @ts-expect-error - We're converting a string attribute to an array here
        fallbackPlacements: this.flipFallbackPlacements,
        fallbackStrategy: this.flipFallbackStrategy === 'best-fit' ? 'bestFit' : 'initialPlacement',
        padding: this.flipPadding,
      })
    );
  }

  private configureShift(middleware: Middleware[]) {
    if (!this.shift) {
      return;
    }

    middleware.push(
      shift({
        boundary: this.shiftBoundary,
        padding: this.shiftPadding,
        limiter: limitShift({
          offset: this.arrow ? this.arrowPadding * 2 : 0,
        }),
      })
    );
  }

  private configureAutoSize(middleware: Middleware[]) {
    if (this.autoSize) {
      middleware.push(
        size({
          boundary: this.autoSizeBoundary,
          padding: this.autoSizePadding,
          apply: ({ availableWidth, availableHeight }: { availableWidth: number; availableHeight: number }) => {
            if (this.autoSize === 'vertical' || this.autoSize === 'both') {
              this.style.setProperty('--auto-size-available-height', `${availableHeight}px`);
            } else {
              this.style.removeProperty('--auto-size-available-height');
            }

            if (this.autoSize === 'horizontal' || this.autoSize === 'both') {
              this.style.setProperty('--auto-size-available-width', `${availableWidth}px`);
            } else {
              this.style.removeProperty('--auto-size-available-width');
            }
          },
        })
      );
    } else {
      // Cleanup styles if we're no longer using auto-size
      this.style.removeProperty('--auto-size-available-width');
      this.style.removeProperty('--auto-size-available-height');
    }
  }

  private configureArrow(middleware: Middleware[]) {
    if (!this.arrow) {
      return;
    }

    middleware.push(
      arrow({
        element: this.arrowEl,
        padding: this.arrowPadding,
      })
    );
  }

  private setPopupPosition(x: number, y: number) {
    Object.assign(this.popup.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  }

  private setArrowPosition(position: ComputePositionReturn) {
    if (!this.arrow) {
      return;
    }

    const staticSide = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' }[
      position.placement.split('-')[0]
    ]!;

    const isRtl = this.dir === 'rtl';
    const arrowX = position.middlewareData.arrow!.x;
    const arrowY = position.middlewareData.arrow!.y;
    const shiftOffsetX = position.middlewareData.shift?.x || 0;
    const shiftOffsetY = position.middlewareData.shift?.y || 0;

    // values to use for 'start' and 'end' placements
    const rightLeftValue =
      typeof arrowX === 'number' ? `calc(${this.arrowPadding - shiftOffsetX}px - var(--arrow-padding-offset))` : '';
    const topBottomValue =
      typeof arrowY === 'number' ? `calc(${this.arrowPadding - shiftOffsetY}px - var(--arrow-padding-offset))` : '';

    let top = '';
    let right = '';
    let bottom = '';
    let left = '';

    if (this.arrowPlacement === 'start') {
      // Start
      top = topBottomValue;
      right = isRtl ? rightLeftValue : '';
      left = isRtl ? '' : rightLeftValue;
    } else if (this.arrowPlacement === 'end') {
      // End
      right = isRtl ? '' : rightLeftValue;
      left = isRtl ? rightLeftValue : '';
      bottom = topBottomValue;
    } else if (this.arrowPlacement === 'center') {
      // Center
      left = typeof arrowX === 'number' ? `calc(50% - var(--arrow-size-diagonal))` : '';
      top = typeof arrowY === 'number' ? `calc(50% - var(--arrow-size-diagonal))` : '';
    } else {
      // Anchor (default)
      left = typeof arrowX === 'number' ? `${arrowX}px` : '';
      top = typeof arrowY === 'number' ? `${arrowY}px` : '';
    }

    Object.assign(this.arrowEl.style, {
      top,
      right,
      bottom,
      left,
      [staticSide]: 'calc(var(--arrow-size-diagonal) * -1)',
    });
  }

  render() {
    return html`
      <slot name="anchor" @slotchange=${this.handleAnchorChange}></slot>

      <div
        part="popup"
        class=${classMap({
          popup: true,
          'popup--active': this.active,
          'popup--fixed': this.strategy === 'fixed',
          'popup--has-arrow': this.arrow,
        })}
      >
        <slot></slot>
        ${this.arrow ? html`<div part="arrow" class="popup__arrow" role="presentation"></div>` : ''}
      </div>
    `;
  }
}

export default Popup;
