import { css } from 'lit';
import { litElevation } from '../../internal/styles/elevation.js';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    --max-width: 20rem;
    --hide-delay: 0ms;
    --show-delay: 300ms;
    --he-elevation: 16;

    display: contents;
    position: relative;
  }

  .tooltip {
    --arrow-size: 5px;
    --arrow-color: var(--he-color-neutral-0);
  }

  .tooltip::part(arrow) {
    z-index: 1001;
    border: 0px var(--he-card-border-style) var(--he-color-card-divider);
  }

  .tooltip::part(popup) {
    ${litElevation};
    border-radius: var(--he-border-radius-small);
    z-index: 1000;
  }

  .tooltip[data-current-placement^='top']::part(popup) {
    transform-origin: bottom;
  }

  .tooltip[data-current-placement^='bottom']::part(popup) {
    transform-origin: top;
  }

  .tooltip[data-current-placement^='left']::part(popup) {
    transform-origin: right;
  }

  .tooltip[data-current-placement^='right']::part(popup) {
    transform-origin: left;
  }

  .tooltip[data-current-placement^='top']::part(arrow) {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
    border-width: 0px 1px 1px 0px;
  }

  .tooltip[data-current-placement^='bottom']::part(arrow) {
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.05);
    border-width: 1px 0px 0px 1px;
  }

  .tooltip[data-current-placement^='left']::part(arrow) {
    box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.06);
    border-width: 1px 1px 0px 0px;
  }

  .tooltip[data-current-placement^='right']::part(arrow) {
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.06);
    border-width: 0px 0px 1px 1px;
  }

  .tooltip__body {
    max-width: var(--max-width);
    background-color: var(--he-color-neutral-0);
    border-radius: var(--he-border-radius-small);
    border: 1px var(--he-card-border-style) var(--he-color-card-divider);
    font-family: var(var(--he-font-sans-serif));
    font-size: var(--he-font-size-medium);
    font-weight: normal;
    line-height: var(--he-line-height-normal);
    color: var(--he-color-neutral-1000);
    padding: var(--he-spacing-2x-small) var(--he-spacing-x-small);
    pointer-events: none;
  }

  @media screen and (forced-colors: active) {
    .tooltip::part(arrow) {
      border-style: solid;
    }

    .tooltip__body {
      background-color: ${SystemColors.Canvas};
      border: 1px solid ${SystemColors.CanvasText};
    }
  }
`;
