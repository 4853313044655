import HarmonyElement from '../base-components/base.js';

export function Dependencies(dependencies: (typeof HarmonyElement)[]) {
  return function (target: any) {
    target.dependencies = dependencies;
  };
}

/**
 * Adds static properties to a component class use for initialization.
 * @param baseName the base name of the component
 * @param dependencies an array of components that are used within this component
 * @returns
 */
export function Component(baseName: string, dependencies?: (typeof HarmonyElement)[]) {
  return function (target: any) {
    target.baseName = baseName;
    target.dependencies = dependencies || [];
  };
}
