import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    --radio-size: var(--he-form-control-toggle-size);

    display: block;
  }

  :host(:focus-visible) {
    outline: 0px;
  }

  .radio {
    display: inline-flex;
    align-items: top;
    font-family: var(--he-form-control-font-family);
    font-size: var(--he-form-control-font-size);
    font-weight: var(--he-font-weight-normal);
    color: var(--he-form-control-color);
    vertical-align: middle;
    cursor: pointer;
  }

  .radio__icon {
    display: inline-flex;
    width: var(--radio-size);
    height: var(--radio-size);
  }

  .radio__icon svg {
    width: 100%;
    height: 100%;
  }

  .radio__control {
    flex: 0 0 auto;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--radio-size);
    height: var(--radio-size);
    border: var(--he-form-control-border-width) solid var(--he-form-control-toggle-border-color);
    border-radius: 50%;
    background-color: var(--he-neutral-fill-stealth-rest);
    color: transparent;
  }

  .radio__input {
    position: absolute;
    opacity: 0;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }

  /* Hover */
  .radio:not(.radio--checked):not(.radio--disabled):hover .radio__control {
    background-color: var(--he-neutral-fill-stealth-hover);
  }

  /* Active */
  .radio:not(.radio--checked):not(.radio--disabled):active .radio__control {
    background-color: var(--he-neutral-fill-stealth-active);
  }

  /* Checked */
  .radio--checked .radio__control {
    color: var(--he-accent-fill-rest);
    border-color: var(--he-accent-fill-rest);
    background-color: var(--he-color-neutral-0);
  }

  /* Checked + hover */
  .radio.radio--checked:not(.radio--disabled):hover .radio__control {
    color: var(--he-accent-fill-hover);
    border-color: var(--he-accent-fill-hover);
    background-color: var(--he-color-neutral-0);
  }

  /* Checked + active */
  .radio.radio--checked:not(.radio--disabled):active .radio__control {
    color: var(--he-accent-fill-active);
    border-color: var(--he-accent-fill-active);
    background-color: var(--he-color-neutral-0);
  }

  /* Checked + focus */
  :host(:focus-visible) .radio {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 1px;
  }

  /* Disabled */
  .radio--disabled {
    cursor: not-allowed;
  }

  .radio--disabled .radio__label {
    color: var(--he-type-disabled);
  }

  .radio--disabled .radio__control {
    border-color: var(--he-form-control-disabled-background);
  }

  /* Checked + Disabled */
  .radio--checked.radio--disabled .radio__control {
    color: var(--he-form-control-disabled-background);
    border-color: var(--he-form-control-disabled-background);
  }

  /* When the control isn't checked, hide the circle for Windows High Contrast mode a11y */
  .radio:not(.radio--checked) svg circle {
    opacity: 0;
  }

  .radio__label {
    line-height: var(--radio-size);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .radio__control {
      border-color: ${SystemColors.ButtonText};
      background: ${SystemColors.ButtonFace};
    }

    /* Hover */
    .radio:not(.radio--checked):not(.radio--disabled):hover .radio__control,
    .radio:not(.radio--checked):not(.radio--disabled):active .radio__control {
      outline: ${SystemColors.ButtonText} solid 2px;
      outline-offset: -3px;
    }

    /* Checked */
    .radio--checked .radio__control {
      color: ${SystemColors.Highlight};
      border-color: ${SystemColors.Highlight};
      background-color: ${SystemColors.HighlightText};
    }

    /* Disabled */
    .radio--disabled {
      opacity: 1;
    }

    .radio--disabled .radio__label {
      color: ${SystemColors.GrayText};
    }

    .radio--disabled .radio__control {
      border-color: ${SystemColors.GrayText};
      background-color: ${SystemColors.Canvas};
    }

    /* Checked + Disabled */
    .radio--checked.radio--disabled .radio__control {
      color: ${SystemColors.GrayText};
      border-color: ${SystemColors.GrayText};
      background-color: ${SystemColors.Canvas};
    }
  }
`;
