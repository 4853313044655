import { property, query, queryAssignedElements, queryAssignedNodes, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, literal } from 'lit/static-html.js';
import { when } from 'lit/directives/when.js';
import { FormSubmitController } from '../../internal/form.js';
import { HasSlotController } from '../../internal/slot.js';
import { isIconOnly } from '../../utilities/slot.js';
import icon from '../icon/icon.js';
import HarmonyFocusableElement from '../../base-components/focusable.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './button.styles.js';
import type { LooseString } from '../../types.js';

/**
 * Button appearance options.
 */
export type ButtonAppearance =
  | 'primary' // blue button
  | 'secondary' // clear button with a border - default
  | 'command' // has a blue icon, mainly for use in command bar
  | 'stealth' // button background only visible on hover, often used for icon buttons
  | 'link'; // looks identical to an anchor link

/**
 *
 * Buttons are used to commit a change or complete steps in a task. They are typically found inside forms, dialogs, panels or pages.
 *
 * @tag he-button
 * @since 1.3
 * @status stable
 * @design approved
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=15%3A4805&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-icon
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-blur - Emitted when the button loses focus.
 * @event he-focus - Emitted when the button gains focus.
 *
 * @slot - The button's content.
 * @slot start - Used to prepend an icon or similar element to the button.
 * @slot end - Used to append an icon or similar element to the button.
 * @slot subtext - Used to add a secondary text content below the main text of the button.
 *
 * @csspart control - The component's internal wrapper.
 * @csspart start - The start slot's container.
 * @csspart content - The button's content.
 * @csspart subtext - The subtext slot's container.
 * @csspart end - The suffix slot's container.
 * @csspart icons - The container of of the caret and external link icons.
 * @csspart caret-icon - The button's caret icon.
 * @csspart external-icon - The button's external icon.
 */
@Component('button', [icon])
export class Button extends HarmonyFocusableElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeFocus: new CustomEvent('he-focus'),
    onHeBlur: new CustomEvent('he-blur'),
  };

  @query('.button')
  private button: HTMLButtonElement | HTMLLinkElement;

  @queryAssignedNodes({ flatten: true })
  private slottedNodes!: Array<Node>;

  @queryAssignedElements({ slot: 'start', flatten: true })
  private startElements!: Array<HTMLElement>;

  @queryAssignedElements({ slot: 'end', flatten: true })
  private endElements!: Array<HTMLElement>;

  private readonly formSubmitController = new FormSubmitController(this, {
    form: (input: HTMLButtonElement) => {
      // Buttons support a form attribute that points to an arbitrary form, so if this attribute it set we need to query
      // the form from the same root using its id
      if (this.form) {
        return this.findRootNode(this).getElementById(this.form) as HTMLFormElement;
      }

      // Fall back to the closest containing form
      return input.closest('form');
    },
  });

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'start', 'end', 'subtext');

  @state() protected caretIcon = html`<${this.scope.tag('icon')}
    class="icon caret-icon"
    part="caret-icon"
    name="ChevronDown"
  ></${this.scope.tag('icon')}>`;

  @state() protected externalIcon = html`<${this.scope.tag('icon')}
    class="icon external-icon"
    part="external-icon"
    name="OpenInNewWindow"
    label=${this.localize.term('open_in_new_window')}
  ></${this.scope.tag('icon')}>`;

  /** The button's appearance. */
  @property({ reflect: true }) appearance: ButtonAppearance = 'secondary';

  /** Draws the button with a caret for use with dropdowns, popovers, etc. */
  @property({ type: Boolean, reflect: true }) caret: boolean;

  /** Disables the button. */
  @property({ type: Boolean, reflect: true }) disabled: boolean;

  /** Whether link should show the 'OpenInNewWindow' icon. */
  @property({ type: Boolean, reflect: true }) external: boolean;

  /**
   * The type of button. When the type is `submit`, the button will submit the surrounding form. Note that the default
   * value is `button` instead of `submit`, which is opposite of how native `<button>` elements behave.
   */
  @property({ reflect: true }) type: 'button' | 'submit' | 'reset' = 'button';

  /** An optional name for the button. Ignored when `href` is set. */
  @property() name?: string;

  /** An optional value for the button. Ignored when `href` is set. */
  @property() value?: string;

  /** When set, the underlying button will be rendered as an `<a>` with this `href` instead of a `<button>`. */
  @property() href?: string;

  /** Tells the browser where to open the link. Only used when `href` is set. */
  @property() target?: LooseString<'_blank' | '_parent' | '_self' | '_top'>;

  /** Tells the browser to download the linked file as this filename. Only used when `href` is set. */
  @property() download?: string;

  /**
   * The "form owner" to associate the button with. If omitted, the closest containing form will be used instead. The
   * value of this attribute must be an id of a form in the same document or shadow root as the button.
   */
  @property() form: string;

  /** Used to override the form owner's `action` attribute. */
  @property({ attribute: 'formaction' }) formAction: string;

  /** Used to override the form owner's `method` attribute. */
  @property({ attribute: 'formmethod' }) formMethod: 'post' | 'get';

  /** Used to override the form owner's `novalidate` attribute. */
  @property({ attribute: 'formnovalidate', type: Boolean, reflect: true }) formNoValidate: boolean;

  /** Used to override the form owner's `target` attribute. */
  @property({ attribute: 'formtarget' }) formTarget: LooseString<'_self' | '_blank' | '_parent' | '_top'>;

  /** Defining which referrer is sent when fetching the resource. Only applies to links. */
  @property({ attribute: 'referrerpolicy' }) referrerPolicy: LooseString<
    | 'no-referrer'
    | 'no-referrer-when-downgrade'
    | 'origin'
    | 'origin-when-cross-origin'
    | 'same-origin'
    | 'strict-origin'
    | 'strict-origin-when-cross-origin'
    | 'unsafe-url'
  > = 'strict-origin-when-cross-origin';

  /** Sets "aria-expanded" on the internal button or link. */
  @property({ reflect: true }) expanded?: 'true' | 'false';

  /** Sets "aria-pressed" on the internal button or link. */
  @property({ reflect: true }) pressed?: 'true' | 'false';

  /** Sets "aria-label" on the internal button or link. */
  @property({ reflect: true }) label?: string;

  /** Sets "aria-haspopup" on the internal button or link. */
  @property({ reflect: true }) haspopup?: 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog' | 'true' | 'false';

  /** Sets "aria-current" on the internal button or link. */
  @property({ reflect: true }) current?: 'page' | 'step' | 'location' | 'date' | 'time' | 'true' | 'false';

  /** Simulates a click on the button. */
  click() {
    this.button?.click();
  }

  protected handleClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.type === 'submit') {
      this.formSubmitController.submit(this);
    }

    if (this.type === 'reset') {
      this.formSubmitController.reset(this);
    }
  }

  private handleSubtextContentChange() {
    this.toggleAttribute('compound', this.hasSlotController.test('subtext'));
    this.emit('he-button-compound-change'); // this is used by button group to update styles when subtext is added/removed
  }

  render() {
    const isLink = this.href ? true : false;
    const tag = isLink ? literal`a` : literal`button`;

    return html`
      <${tag}
        part="control"
        class=${classMap({
          button: true,
          control: true,
          [`button--${this.appearance}`]: true,
          'button--caret': this.caret,
          'button--disabled': this.disabled,
          'button--focused': this.hasFocus,
          'button--standard': true,
          'button--has-start': this.startElements.length > 0,
          'button--has-end': this.endElements.length > 0,
          'icon-only': isIconOnly(this.slottedNodes),
        })}
        type=${ifDefined(isLink ? undefined : this.type)}
        name=${ifDefined(isLink ? undefined : this.name)}
        value=${ifDefined(isLink ? undefined : this.value)}
        href=${ifDefined(isLink ? this.href : undefined)}
        target=${ifDefined(isLink ? this.target : undefined)}
        download=${ifDefined(isLink ? this.download : undefined)}
        rel=${ifDefined(isLink && this.target ? 'noreferrer noopener' : undefined)}
        referrerpolicy=${ifDefined(isLink ? this.referrerPolicy : undefined)}
        aria-label=${ifDefined(this.label)}
        aria-current=${ifDefined(this.current)}
        aria-expanded=${ifDefined(this.expanded ?? undefined)}
        aria-pressed=${ifDefined(this.pressed ?? undefined)}
        aria-haspopup=${ifDefined(this.haspopup)}
        tabindex=${isLink && this.disabled ? '-1' : '0'}
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        ?autofocus=${this.autofocus}
        @click=${this.handleClick}
      >
        <span part="start" class="start">
          <slot name="start"></slot>
        </span>
        <span part="content" class="content">
          <span class="main-text">
            <slot></slot>
            ${when(
              this.external,
              () => html` <span part="external-icon" class="external-icon"> ${this.externalIcon} </span> `
            )}
          </span>
          <span part="subtext" class="subtext">
            <slot name="subtext" @slotchange=${this.handleSubtextContentChange}></slot>
          </span>
        </span>
        <span part="end" class="end">
          <slot name="end"></slot>
        </span>
        ${when(this.caret, () => html` <span part="icon" class="internal-icons"> ${this.caretIcon} </span> `)}
      </${tag}>
    `;
  }
}

export default Button;
