import { html } from 'lit/static-html.js';
import { property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import Modal from '../../internal/modal.js';
import { animateTo, stopAnimations } from '../../internal/animations.js';
import button from '../button/button.js';
import icon from '../icon/icon.js';
import visuallyHidden from '../visually-hidden/visually-hidden.js';
import { PanelSize } from '../../types.js';
import HarmonyDismissibleElement from '../../base-components/dismissible.js';
import { HasSlotController } from '../../internal/slot.js';
import { lockBodyScrolling, unlockBodyScrolling } from '../../utilities/scroll.js';
import { watch } from '../../internal/watch.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './fly-in-panel.styles.js';

export type FlyInPanelCloseSource = 'close-button' | 'keyboard' | 'overlay';

export interface FlyInPanelCloseEvent {
  source: FlyInPanelCloseSource;
}

const KEY_FRAMES = {
  right: {
    open: [
      { opacity: 0, transform: 'translateX(100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(100%)' },
    ],
  },
  left: {
    open: [
      { opacity: 0, transform: 'translateX(-100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(-100%)' },
    ],
  },
  deny: [{ transform: 'scale(1)' }, { transform: 'scale(1.01)' }, { transform: 'scale(1)' }],
};

/**
 *
 * Fly-in panels, or task panels, are UI overlays that allow users to complete tasks while retaining their context within the app. They often enable a creation or management action from the user, such as adding a group item or editing a line item.
 *
 * @tag he-fly-in-panel
 * @since 1.5
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19299&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-button
 * @dependency he-icon
 * @dependency he-visually-hidden
 *
 * @slot - The fly-in-panel's content.
 * @slot heading - The fly-in-panel's header label. Alternatively, you can use the heading prop.
 * @slot actions - The fly-in-panel's header actions, usually a back button.
 * @slot footer - The fly-in-panel's footer, usually one or more buttons representing various options.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-fly-in-show - Emitted when the fly-in-panel opens.
 * @event he-fly-in-after-show - Emitted after the fly-in-panel opens and all transitions are complete.
 * @event he-fly-in-hide - Emitted when the fly-in-panel closes.
 * @event he-fly-in-after-hide - Emitted after the fly-in-panel closes and all transitions are complete.
 * @event he-fly-in-initial-focus - Emitted when the fly-in-panel opens and the panel gains focus. Calling
 *   `event.preventDefault()` will prevent focus and allow you to set it on a different element in the fly-in-panel,
 *   such as an input or button. You can also put `autofocus` attribute on the element instead to set initial focus.
 * @event {FlyInPanelCloseEvent} he-fly-in-request-close - Emitted when the user attempts to
 *   close the fly-in panel by clicking the close button, clicking the overlay, or pressing the escape key. Calling
 *   `event.preventDefault()` will prevent the panel from closing. You can check `event.detail.source` to determine how
 *   the request was initiated. Avoid using this unless closing the fly-in panel will result in destructive behavior
 *   such as data loss.
 * @event he-show - (@deprecated) Use `he-fly-in-show` instead.
 * @event he-after-show - (@deprecated) Use `he-fly-in-after-show `instead.
 * @event he-hide - (@deprecated) Use `he-fly-in-hide` instead.
 * @event he-after-hide - (@deprecated) Use `he-fly-in-after-hide` instead.
 * @event he-initial-focus - (@deprecated) Use `he-fly-in-initial-focus` instead.
 * @event {FlyInPanelCloseEvent} he-request-close - (@deprecated) Use `he-fly-in-request-close` instead.
 *
 * @csspart base - The component's base wrapper.
 * @csspart overlay - The overlay.
 * @csspart panel - The fly-in-panel panel (where the fly-in-panel and its content is rendered).
 * @csspart header - The fly-in-panel header.
 * @csspart title - The fly-in-panel title.
 * @csspart close-button - The close button.
 * @csspart body - The fly-in-panel body.
 * @csspart footer - The fly-in-panel footer.
 *
 * @cssproperty --size - The preferred size of the fly-in-panel. This will be applied to the fly-in-panel's width or height.
 *   depending on its `placement`. Note that the fly-in-panel will shrink to accommodate smaller screens.
 * @cssproperty --header-padding-x - The amount of horizontal padding to use for the header.
 * @cssproperty --header-padding-y - (@deprecated) Use `--header-padding-top` and `--header-padding-bottom` instead.
 * @cssproperty --header-padding-top - The amount of vertical padding to use at the top of the header.
 * @cssproperty --header-padding-bottom - The amount of vertical padding to use at the bottom of the header.
 * @cssproperty --body-padding-x - The amount of horizontal padding to use for the body.
 * @cssproperty --body-padding-y - The amount of vertical padding to use for the body.
 * @cssproperty --footer-padding-x - The amount of horizontal padding to use for the footer.
 * @cssproperty --footer-padding-y - The amount of vertical padding to use for the footer.
 * @cssproperty [--he-elevation=64] - Elevation of the card.
 */
@Component('fly-in-panel', [button, icon, visuallyHidden])
export class FlyInPanel extends HarmonyDismissibleElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeFlyInShow: new CustomEvent('he-fly-in-show'),
    onHeFlyInAfterShow: new CustomEvent('he-fly-in-after-show'),
    onHeFlyInHide: new CustomEvent('he-fly-in-hide'),
    onHeFlyInAfterHide: new CustomEvent('he-fly-in-after-hide'),
    onHeFlyInInitialFocus: new CustomEvent('he-fly-in-initial-focus'),
    onHeFlyInRequestClose: new CustomEvent<FlyInPanelCloseEvent>('he-fly-in-request-close'),
    /** @deprecated Use `onHeFlyInShow` instead. */
    onHeShow: new CustomEvent('he-show'),
    /** @deprecated Use `onHeFlyInAfterShow` instead. */
    onHeAfterShow: new CustomEvent('he-after-show'),
    /** @deprecated Use `onHeFlyInHide` instead. */
    onHeHide: new CustomEvent('he-hide'),
    /** @deprecated Use `onHeFlyInAfterHide` instead. */
    onHeAfterHide: new CustomEvent('he-after-hide'),
    /** @deprecated Use `onHeFlyInInitialFocus` instead. */
    onHeInitialFocus: new CustomEvent('he-initial-focus'),
    /** @deprecated Use `onHeFlyInRequestClose` instead. */
    onHeRequestClose: new CustomEvent<FlyInPanelCloseEvent>('he-request-close'),
  };

  private hasSlotController = new HasSlotController(this, '[default]', 'heading', 'footer', 'actions');
  protected scopedEventName = 'fly-in';

  /** Indicates whether or not the fly-in-panel is open. You can use this in lieu of the show/hide methods. */
  @property({ type: Boolean, reflect: true }) open = false;

  /** Indicates the transition speed for animations. */
  @property({ type: Number, attribute: 'transition-speed' }) transitionSpeed = 300;

  /** @deprecated use `--size` css property instead. */
  @property() size?: PanelSize;

  /**
   * The fly-in-panel's label as displayed in the header. You should always include a relevant label even when using
   * `no-header`, as it is required for proper accessibility.
   */
  @property() heading = '';

  /**
   * The direction the fly-in-panel will open from.
   *
   * Note: "right" and "left" will be deprecated in a future version. Please use the logical properties "end" or
   * "start", which are based on language direction.
   */
  @property() placement: 'end' | 'start' | 'right' | 'left' = 'end';

  // placement converted to right or left based on direction
  @state()
  private get _placement() {
    switch (this.placement) {
      case 'start':
        return this.dir === 'rtl' ? 'right' : 'left';
      case 'end':
        return this.dir === 'rtl' ? 'left' : 'right';
      default:
        return this.placement;
    }
  }

  /**
   * By default, the fly-in-panel slides out of its containing block (usually the viewport). To make the fly-in-panel slide out of
   * its parent element, set this prop and add `position: relative` to the parent.
   */
  @property({ type: Boolean, reflect: true }) contained = false;

  /**
   * Removes the header. This will also remove the default close button. If using prevent default on he-fly-in-request-close please provide a way for the user to close the fly-in-panel.
   */
  @property({ attribute: 'no-header', type: Boolean, reflect: true }) noHeader = false;

  @query('.fly-in__panel')
  private panel: HTMLElement;

  @query('.fly-in__header')
  private header: HTMLElement;

  @query('.fly-in__footer')
  private footer: HTMLElement;

  @query('.fly-in__overlay')
  private overlay: HTMLElement;

  @query('.fly-in')
  private wrapper: HTMLElement;

  @query('.fly-in__close')
  private closeButton: HTMLElement;

  @queryAssignedElements({ slot: 'actions', selector: '.he-button' })
  private actionButtons: HTMLElement[];

  private modal: Modal;
  private originalTrigger: HTMLElement | null;
  private hasPreventScroll: boolean = false;
  private resizeObserver = new ResizeObserver(() => this.handleResize());

  @state() private nonStickyHeader = false;

  @state()
  private get keyFrames() {
    switch (this._placement) {
      case 'left':
        return KEY_FRAMES.left;
      default:
        return KEY_FRAMES.right;
    }
  }

  constructor() {
    super();
    document.createElement('div').focus({
      get preventScroll() {
        this.hasPreventScroll = true;
        return false;
      },
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.modal = new Modal(this);
    this.setAttribute('tabindex', '-1'); // this is necessary for keydown event listeners
  }

  firstUpdated() {
    super.firstUpdated();
    this.wrapper.hidden = !this.open;
    this.resizeObserver.observe(this.panel);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.modal.deactivate();
    unlockBodyScrolling(this);
    this.resizeObserver.unobserve(this.panel);
    this.resizeObserver.disconnect();
  }

  /** @internal watcher */
  @watch('open')
  async openChange() {
    if (this.open) {
      // Show
      this.originalTrigger = this.findRootNode(this)?.activeElement as HTMLElement | null;
      this.modal.activate();

      if (!this.contained) {
        lockBodyScrolling(this);
      }

      this.addEventListener('keydown', this.handleKeyDown);

      await this.updateComplete;
      await stopAnimations(this.wrapper);
      this.wrapper.hidden = false;

      // Browsers that support el.focus({ preventScroll }) can set initial focus immediately
      if (this.hasPreventScroll) {
        this.initialFocus();
      }

      await animateTo(this.panel, this.keyFrames.open, { duration: this.transitionSpeed, easing: 'ease' });
      if (!this.hasPreventScroll) {
        this.initialFocus();
      }

      this.emitAfterShow();
    } else {
      if (!this.hasUpdated) return;

      // Hide
      this.modal.deactivate();

      await animateTo(this.panel, this.keyFrames.close, { duration: this.transitionSpeed, easing: 'ease' });
      this.wrapper.hidden = true;

      unlockBodyScrolling(this);
      this.removeEventListener('keydown', this.handleKeyDown);

      // Restore focus to the original trigger
      const trigger = this.originalTrigger;
      if (trigger && typeof trigger.focus === 'function') {
        setTimeout(() => trigger.focus());
      }

      this.emitAfterHide();
    }
  }

  private initialFocus() {
    const initialFocus = this.emitScopedEvent('initial-focus', { cancelable: true });
    /** @deprecated */
    const oldInitialFocus = this.emit('he-initial-focus', { cancelable: true });

    if (initialFocus.defaultPrevented || oldInitialFocus.defaultPrevented) return;

    this.getAutofocus()?.focus({ preventScroll: true });
  }

  private requestClose(source: 'close-button' | 'keyboard' | 'overlay') {
    this.emitRequestClose(source, this.panel, KEY_FRAMES.deny, { duration: this.transitionSpeed, easing: 'ease' });
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.requestClose('keyboard');
    }
    return true;
  };

  /**
   * Returns the first element with 'autofocus' attribute inside fly-in panel, if there is none it but there are buttons
   * in the actions slot it returns the first one, or if none, it returns the close button.
   */
  private getAutofocus(): HTMLElement {
    const autofocus: HTMLElement | null = this.querySelector('[autofocus]');
    return autofocus || this.actionButtons[0] || this.closeButton;
  }

  /**
   * The header will not be sticky only if the header and footer both present and the total height of them is
   * more than 60% of the fly-in panel's height.
   */
  private handleResize() {
    if (!this.footer || !this.header) return;
    const totalHeight = (this.footer.clientHeight || 0) + (this.header.clientHeight || 0);
    this.nonStickyHeader = totalHeight > this.panel.clientHeight * 0.6;
  }

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          'fly-in': true,
          'fly-in--open': this.open,
          'fly-in--non-sticky-header': this.nonStickyHeader,
          [`fly-in--${this._placement}`]: true,
          'fly-in--contained': this.contained,
          'fly-in--fixed': !this.contained,
          'fly-in--has-footer': this.hasSlotController.test('footer'),
          'fly-in--has-actions': this.hasSlotController.test('actions'),
        })}
      >
        <div part="overlay" class="fly-in__overlay" @click=${() => this.requestClose('overlay')} tabindex="-1"></div>
        <div
          part="panel"
          class="fly-in__panel"
          role="dialog"
          aria-modal="true"
          aria-hidden=${this.open ? 'false' : 'true'}
          aria-label=${this.noHeader ? this.heading : ''}
          aria-labelledby=${!this.noHeader ? `${this.id}-title` : ''}
        >
          ${!this.noHeader
            ? html`
                  <header part="header" class="fly-in__header">
                    <span class="fly-in__header__slots">
                      <span class="fly-in__actions">
                        <slot name="actions"></slot>
                      </span>
                      <h2 part="title" class="fly-in__title" id=${`${this.id}-title`}>
                        <slot name="heading"> ${this.heading || String.fromCharCode(65279)} </slot>
                      </h2>
                    </span>
                    <${this.scope.tag('button')} class="fly-in__close" appearance="stealth" @click=${() =>
                this.requestClose('close-button')}>
                      <${this.scope.tag('icon')} name="cancel" label="${this.localize.term(
                'close'
              )}"></${this.scope.tag('icon')}>
                    </${this.scope.tag('button')}>
                  </header>
                `
            : html`<${this.scope.tag('visually-hidden')} class="fly-in__hidden-close">
              <${this.scope.tag('button')} class="fly-in__close" appearance="secondary" @click=${() =>
                this.requestClose('close-button')}>
                <${this.scope.tag('icon')} name="cancel" label="${this.localize.term('close')}"></${this.scope.tag(
                'icon'
              )}>
              </${this.scope.tag('button')}>
            </${this.scope.tag('visually-hidden')}>`}

          <div part="body" class="fly-in__body">
            <slot></slot>
          </div>
          <footer part="footer" class="fly-in__footer">
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    `;
  }
}

export default FlyInPanel;
