import { property } from 'lit/decorators.js';
import { waitForEvent } from '../utilities/wait-for-event.js';
import { watch } from '../internal/watch.js';
import { animateTo } from '../internal/animations.js';
import { LooseString } from '../types.js';
import HarmonyElement from './base.js';

/**
 * Base class for components that can be shown or hidden. They have `show`, `hide`, and `toggle` methods, an `open`
 * and `he-{component}-show` and `he-{component}-hide` events. `afterShow()` and `afterHide()` methods should be called
 * after animations are complete.
 */
export default abstract class HarmonyDismissibleElement extends HarmonyElement {
  /** @internal Name to use for scoped events. */
  protected abstract scopedEventName: string;

  /**
   * Indicates whether or not the component is open. Can be used in lieu of show/hide methods.
   */
  @property({ type: Boolean, reflect: true }) open = false;

  /** @internal This should be called on show after animations are complete */
  protected async emitAfterShow() {
    this.emitScopedEvent('after-show');
    /** @deprecated */
    this.emit('he-after-show');
  }

  /** @internal This should be called on hide after animations are complete */
  protected async emitAfterHide() {
    this.emitScopedEvent('after-hide');
    /** @deprecated */
    this.emit('he-after-hide');
  }

  /** @internal Called when `open` changes to true. */
  protected emitShow() {
    this.emitScopedEvent('show');
    /** @deprecated */
    this.emit('he-show');
  }

  /** @internal Called when `open` changes to false. */
  protected emitHide() {
    this.emitScopedEvent('hide');
    /** @deprecated */
    this.emit('he-hide');
  }

  /**
   * @internal Emits an `he-{scopedEventName}-request-close` event that allows prevent default to cancel the close,
   * which shows a "deny" animation. Returns false if default was prevented, otherwise hides the popup and returns true.
   */
  protected emitRequestClose(
    source: LooseString<string>,
    element: HTMLElement,
    frames: Keyframe[] = [{ transform: 'scale(1)' }, { transform: 'scale(1.02)' }, { transform: 'scale(1)' }],
    options: KeyframeAnimationOptions = { duration: 300 }
  ) {
    const requestClose = this.emitScopedEvent('request-close', { cancelable: true, detail: { source } });
    /** @deprecated */
    const oldRequestClose = this.emit('he-request-close', { cancelable: true, detail: { source } });

    if (requestClose.defaultPrevented || oldRequestClose.defaultPrevented) {
      if (element) animateTo(element, frames, options);
      return false;
    }

    this.hide();

    return true;
  }

  /** @internal Emits event `he-${this.scopedEventName}-${name}`. */
  protected emitScopedEvent(name: string, detail?: any) {
    return this.emit(`he-${this.scopedEventName}-${name}`, detail);
  }

  /** @internal watcher, emits `he-{scopedEventName}-show` and `he-{scopedEventName}-hide` events when `open` changes */
  @watch('open', { waitUntilFirstUpdate: true })
  handleOpenChange(prev?: boolean) {
    if (this.open) {
      this.emitShow();
    } else if (prev !== undefined) {
      this.emitHide();
    }
  }

  firstUpdated() {
    super.firstUpdated();
    if (this.open) this.emitShow();
  }

  /**
   * Shows the popup. Returns a promise that resolves when the popup is visible and transition is complete.
   *
   * @example
   * element.show().then(...);
   * // or
   * await element.show();
   */
  public async show(): Promise<void> {
    if (this.open) return;

    this.open = true;
    return waitForEvent(this, `he-${this.scopedEventName}-after-show`);
  }

  /**
   * Hides the popup. Returns a promise that resolves when the popup is no longer visible and transition is complete.
   *
   * @example
   * element.hide().then(...);
   * // or
   * await element.hide();
   */
  public async hide(): Promise<void> {
    if (!this.open) return;

    this.open = false;
    return waitForEvent(this, `he-${this.scopedEventName}-after-hide`);
  }

  /**
   * Shows or hides the popup depending on if it is currently visible or not, returns promise that resolves when transitions are complete.
   */
  public async toggle(): Promise<void> {
    return this.open ? this.hide() : this.show();
  }
}
