import { getPortalTypeIdentifier, PortalEnvironment } from '../../services/context/environment-helper';
import { httpRequestRetryStore } from '../../services/http-request-store';
import { HttpFunctions } from '../../services/http-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { ConnectorPromiseStore } from '../../services/types/window-connectors';
import { environmentConnector } from '../environment-connector/environment-connector';
import { trackError, trackEvent } from '../../services/telemetry-service/library-telemetry-service';
import { HarmonyConnectorError } from '../connector-error';
import { VersionPicker } from '../../utilities/version-picker';
import { CustomEvents } from '../../helpers/constant';
import { WorkspaceList } from './types/workspace-list';
import { IMasterWorkspaceListConnector } from './i-master-workspace-list-connector';

let preFetchPromise: Promise<WorkspaceList | undefined> | undefined = undefined;
let versionPicker: VersionPicker;
const FailureMasterWorkspaceList = 'Failure while trying to retrieve Workspaces';
const ErrorSource = 'MasterWorkspaceListConnector';
const ErrorIdentifier = 'master-workspace-list-failure';

/**
 * @description It is the master workspace list connector that is responsible for providing the list of workspaces on the client side.
 *
 * @since 0.0.0
 * @status experimental
 */
const CONFIG_ID = 'V1';

export const masterWorkspaceListConnector: IMasterWorkspaceListConnector = async (forceNew: boolean = false): Promise<WorkspaceList | undefined> => {
  // version picker
  if (!versionPicker) {
    versionPicker = VersionPicker.getInstance();
  }

  const versionPickerEventLog = {
    connector: 'masterWorkspaceListConnector',
    scope: window.location.origin,
    href: window.location.href,
  };

  const library = await versionPicker?.loadShellLibrary();
  if (library?.masterWorkspaceListConnectorCore) {
    trackEvent(CustomEvents.VersionPickerUsed, versionPickerEventLog);
    return library.masterWorkspaceListConnectorCore(forceNew);
  } else {
    trackEvent(CustomEvents.VersionPickerFallback, versionPickerEventLog);
  }

  return masterWorkspaceListConnectorCore();
};

export const masterWorkspaceListConnectorCore: IMasterWorkspaceListConnector = async (forceNew: boolean = false): Promise<WorkspaceList | undefined> => {
  if (preFetchPromise && !forceNew) {
    return preFetchPromise;
  }

  const baseUrl = 'https://partner-portalplatform.microsoft{0}.com/portal/{portalName}/MasterlistWorkspace.json';

  const environment = environmentConnector();
  const portalIdentifier = await getPortalTypeIdentifier();
  if (!portalIdentifier) {
    throw new Error('Unable to determine the portal identifier');
  }

  const url = baseUrl.replace('{0}', environment === PortalEnvironment.prod ? '' : '-tst').replace('{portalName}', portalIdentifier);

  const connectorPromiseStoreConfig: ConnectorPromiseStore = {
    connectorType: 'masterWorkspaceList',
    uniqueId: url + CONFIG_ID,
  };
  try {
    const preFetchPromiseResponse = httpRequestRetryStore<WorkspaceList>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin');
    if (preFetchPromiseResponse) {
      preFetchPromise = preFetchPromiseResponse as Promise<WorkspaceList | undefined>;
    }
  } catch (error: any) {
    const message: string = error?.message ?? FailureMasterWorkspaceList;

    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: ErrorSource,
      statusCode: '500',
      errorObject: JSON.stringify(error) ?? '',
    };
    trackError(ErrorIdentifier, errorInformation);
    throw new HarmonyConnectorError(ErrorSource, FailureMasterWorkspaceList);
  }

  return preFetchPromise;
};
