import HarmonyElement from '../base-components/base.js';
import HeTelemetry, { HeTelemetryType } from '../features/telemetry/telemetry.js';

/**
 * Track Usage decorator
 *
 * Usage: Can be used on a component when you call "scope.registerComponent"
 * Telemetry will be emitted as an event of type "he-telemetry-internal" and always logged if app insights is connected.
 * Currently for internal use only.
 */
export function trackComponent(tagName: string, constructor: typeof HarmonyElement) {
  if (typeof window.requestIdleCallback === 'function') {
    window.requestIdleCallback(() => {
      emitTelemetryEvent(tagName, constructor);
    });
  } else {
    // Is 400ms enough? Maybe...page loads shouldn't be longer than 300ms.
    // This only fires in Safari. requestIdleCallback is supported in all other browsers.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#browser_compatibility
    window.setTimeout(() => {
      emitTelemetryEvent(tagName, constructor);
    }, 400);
  }
}

/**
 * Perhaps in the future this should allow emitting an array of enablers to make only 1 call for all components?
 */
function emitTelemetryEvent(tagName: string, constructor: typeof HarmonyElement) {
  if (window.HarmonyEnablers.telemetry) {
    HeTelemetry.getInstance();
  }

  //Emit telemetry event
  document.dispatchEvent(
    new CustomEvent('he-telemetry-internal', {
      bubbles: true,
      composed: true,
      detail: {
        type: HeTelemetryType.enablerUsage,
        enabler: `he-${constructor.baseName}`,
        metricName: 'componentUsage',
        message: `${tagName} has been registered.`,
        source: constructor.name,
      },
    })
  );
}
