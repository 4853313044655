/** This is in a separate file so users not using React do not need to use the React namespace. */

import { createComponent } from '@lit-labs/react';
import React from 'react';
import HarmonyElement from '../base-components/base.js';
import { HarmonyEnablerScope } from '../utilities/scope.js';

import type { ScopeOptions } from '../utilities/scope.js';

/**
 * Creates a custom Harmony Enabler scope, optionally applying themes and registering components. Once a scope is
 * created, it cannot be destroyed without a page reload because custom elements cannot be unregistered.
 *
 * @example
 * import { theme } from '@harmony/enablers/themes/partner-center';
 * import cssUtilities from '@harmony/enablers/utilities/css';
 * import { button } from '@harmony/enablers/components/button/button';
 * import { card } from '@harmony/enablers/components/card/card';
 *
 * const scope = createScope(
 *  rootElement: myElement,
 *  suffix: 'support',
 *  styles: [theme, cssUtilities],
 *  components: [button, card]
 * );
 */
export function createScope(options: ScopeOptions) {
  scope = new HarmonyScopeWithReact(options);
  return scope;
}

/**
 * A "monkeypatch" to allow scopes to register React components without needing @types/react for non-React projects.
 * @see https://microsoft.visualstudio.com/OSGS/_boards/board/t/Enablers/Deliverables%20and%20Task%20Groups/?workitem=40240424
 */
export class HarmonyScopeWithReact extends HarmonyEnablerScope {
  forReact<T extends typeof HarmonyElement>(component: T) {
    this.reactInstance = React;
    this.reactInstanceMustBeSet();

    // Register it for them if they haven't already done so. This is sugar for React users, and if it's already
    // registered nothing will happen.
    this.registerComponent(component);

    const baseName = component.baseName;
    const tagName = this.tagName(baseName);

    const events: Record<string, string> = {};
    Object.entries(component.reactEvents).forEach(([k, v]) => (events[k] = v.type));

    return createComponent(this.reactInstance, tagName, component, events) as unknown as ReactWebComponent<
      InstanceType<T>,
      T['reactEvents']
    >;
  }
}

/** https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors */
export interface CSSProperties extends React.CSSProperties {
  // Allow any custom properties.
  [index: string | number]: string | number | undefined | null;
}

/**
 * The props used by the ReactWrapper.
 */
type ReactProps<I, E> = Omit<React.HTMLAttributes<I>, keyof E>;
type ElementWithoutPropsOrEventListeners<I, E> = Omit<I, keyof E | keyof ReactProps<I, E>>;

type WebComponentProps<I extends HTMLElement, E extends Record<string, Event>> = Partial<
  ReactProps<I, E> &
    ElementWithoutPropsOrEventListeners<I, E> & {
      // This is where the event magic happens.
      [Property in keyof E]: (event: E[Property]) => void;
    } & { style: CSSProperties }
>;

export type ReactWebComponent<I extends HTMLElement, E extends Record<string, Event>> = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<WebComponentProps<I, E>> & React.RefAttributes<I>
>;

export let scope = new HarmonyScopeWithReact();
