import { css } from 'lit';

export default css`
  .he-theme-light {
    color-scheme: light;

    /**
     * Color primitives
     */

    --he-color-red-50: hsl(0 85.7% 97.3%);
    --he-color-red-100: hsl(354.5, 84.6%, 94.9%); /* Custom variation from design. Original: hsl(0 93.3% 94.1%); */
    --he-color-red-200: hsl(0 96.3% 89.4%);
    --he-color-red-300: hsl(0 93.5% 81.8%);
    --he-color-red-400: hsl(0 90.6% 70.8%);
    --he-color-red-500: hsl(0 84.2% 60.2%);
    --he-color-red-600: hsl(0 72.2% 50.6%);
    --he-color-red-700: hsl(0 73.7% 41.8%);
    --he-color-red-800: hsl(0, 100%, 32.9%); /* Custom variation from design. Original: hsl(0 70% 35.3%); */
    --he-color-red-900: hsl(0 62.8% 30.6%);
    --he-color-red-950: hsl(0 60% 19.6%);

    --he-color-orange-50: hsl(33.3 100% 96.5%);
    --he-color-orange-100: hsl(34.3 100% 91.8%);
    --he-color-orange-200: hsl(32.1 97.7% 83.1%);
    --he-color-orange-300: hsl(30.7 97.2% 72.4%);
    --he-color-orange-400: hsl(27 96% 61%);
    --he-color-orange-500: hsl(24.6 95% 53.1%);
    --he-color-orange-600: hsl(20.5 90.2% 48.2%);
    --he-color-orange-700: hsl(17.5 88.3% 40.4%);
    --he-color-orange-800: hsl(15 79.1% 33.7%);
    --he-color-orange-900: hsl(15.3 74.6% 27.8%);
    --he-color-orange-950: hsl(15.2 69.1% 19%);

    --he-color-yellow-50: hsl(54.5 91.7% 95.3%);
    --he-color-yellow-100: hsl(46.5, 100%, 90.4%); /* original: hsl(54.9 96.7% 88%); */
    --he-color-yellow-200: hsl(52.8 98.3% 76.9%);
    --he-color-yellow-300: hsl(50.4 97.8% 63.5%);
    --he-color-yellow-400: hsl(47.9 95.8% 53.1%);
    --he-color-yellow-500: hsl(45.4 93.4% 47.5%);
    --he-color-yellow-600: hsl(40.6 96.1% 40.4%);
    --he-color-yellow-700: hsl(35.5 91.7% 32.9%);
    --he-color-yellow-800: hsl(31.8 81% 28.8%);
    --he-color-yellow-900: hsl(28.4 72.5% 25.7%);
    --he-color-yellow-950: hsl(33.1 69% 13.9%);

    --he-color-green-50: hsl(138.5 76.5% 96.7%);
    --he-color-green-100: hsl(115.2, 58.1%, 91.6%); /* original: hsl(140.6 84.2% 92.5%); */
    --he-color-green-200: hsl(141 78.9% 85.1%);
    --he-color-green-300: hsl(141.7 76.6% 73.1%);
    --he-color-green-400: hsl(141.9 69.2% 58%);
    --he-color-green-500: hsl(142.1 70.6% 45.3%);
    --he-color-green-600: hsl(142.1 76.2% 36.3%);
    --he-color-green-700: hsl(120, 77.1%, 27.5%); /* original: hsl(142.4 71.8% 29.2%); */
    --he-color-green-800: hsl(142.8 64.2% 24.1%);
    --he-color-green-900: hsl(143.8 61.2% 20.2%);
    --he-color-green-950: hsl(144.3 60.7% 12%);

    --he-color-teal-50: hsl(166.2 76.5% 96.7%);
    --he-color-teal-100: hsl(167.2 85.5% 89.2%);
    --he-color-teal-200: hsl(168.4 83.8% 78.2%);
    --he-color-teal-300: hsl(170.6 76.9% 64.3%);
    --he-color-teal-400: hsl(172.5 66% 50.4%);
    --he-color-teal-500: hsl(173.4 80.4% 40%);
    --he-color-teal-600: hsl(174.7 83.9% 31.6%);
    --he-color-teal-700: hsl(175.3 77.4% 26.1%);
    --he-color-teal-800: hsl(176.1 69.4% 21.8%);
    --he-color-teal-900: hsl(175.9 60.8% 19%);
    --he-color-teal-950: hsl(176.5 58.6% 11.4%);

    --he-color-blue-50: hsl(207.7 68.4% 96.3%);
    --he-color-blue-100: hsl(208.9 69.2% 92.4%);
    --he-color-blue-200: hsl(206.7 67.2% 86.9%);
    --he-color-blue-300: hsl(204.1 95.5% 73.9%);
    --he-color-blue-400: hsl(201.6 93.2% 59.6%);
    --he-color-blue-500: hsl(201.4 88.7% 48.4%);
    --he-color-blue-600: hsl(206 100% 41.6%);
    --he-color-blue-700: hsl(207.6 84.5% 40.4%);
    --he-color-blue-800: hsl(205.8 100% 31%);
    --he-color-blue-900: hsl(205.5 100% 23.5%);
    --he-color-blue-950: hsl(216 100% 15.7%);

    --he-color-magenta-50: hsl(289.1 100% 97.8%);
    --he-color-magenta-100: hsl(287 100% 95.5%);
    --he-color-magenta-200: hsl(288.3 95.8% 90.6%);
    --he-color-magenta-300: hsl(291.1 93.1% 82.9%);
    --he-color-magenta-400: hsl(292 91.4% 72.5%);
    --he-color-magenta-500: hsl(292.2 84.1% 60.6%);
    --he-color-magenta-600: hsl(293.4 69.5% 48.8%);
    --he-color-magenta-700: hsl(294.7 72.4% 39.8%);
    --he-color-magenta-800: hsl(295.4 70.2% 32.9%);
    --he-color-magenta-900: hsl(296.7 63.6% 28%);
    --he-color-magenta-950: hsl(297.1 56.8% 14.5%);

    --he-color-purple-50: hsl(250 100% 97.6%);
    --he-color-purple-100: hsl(251.4 91.3% 95.5%);
    --he-color-purple-200: hsl(250.5 95.2% 91.8%);
    --he-color-purple-300: hsl(252.5 94.7% 85.1%);
    --he-color-purple-400: hsl(255.1 91.7% 76.3%);
    --he-color-purple-500: hsl(258.3 89.5% 66.3%);
    --he-color-purple-600: hsl(262.1 83.3% 57.8%);
    --he-color-purple-700: hsl(263.4 70% 50.4%);
    --he-color-purple-800: hsl(263.4 69.3% 42.2%);
    --he-color-purple-900: hsl(263.5 67.4% 34.9%);
    --he-color-purple-950: hsl(265.1 61.5% 21.4%);

    --he-color-gray-50: hsl(30 16.7% 97.6%);
    --he-color-gray-100: hsl(30 7.7% 94.9%);
    --he-color-gray-150: hsl(30, 10%, 92.2%);
    --he-color-gray-200: hsl(40 4.6% 87.3%);
    --he-color-gray-250: hsl(30 4.3% 81.6%);
    --he-color-gray-300: hsl(30 3.5% 77.6%);
    --he-color-gray-350: hsl(36 3.6% 73.1%);
    --he-color-gray-400: hsl(36 3.1% 68.8%);
    --he-color-gray-450: hsl(30 2.1% 62.4%);
    --he-color-gray-500: hsl(30 1.9% 58.2%);
    --he-color-gray-550: hsl(30, 1.7%, 53.3%);
    --he-color-gray-600: hsl(30, 1.7%, 46.7%);
    --he-color-gray-650: hsl(30, 2.1%, 36.9%);
    --he-color-gray-700: hsl(30, 2.9%, 27.5%);
    --he-color-gray-750: hsl(30, 1.7%, 22.7%);
    --he-color-gray-800: hsl(30, 2%, 19.2%);
    --he-color-gray-850: hsl(30, 2.5%, 15.7%);
    --he-color-gray-900: hsl(30 3.2% 12.2%);
    --he-color-gray-950: hsl(30 3.8% 10.2%);

    --he-color-black: hsl(0 0% 0%);
    --he-color-white: hsl(255 100% 100%);

    /**
     * Theme colors
     */

    --he-color-primary-50: var(--he-color-blue-50);
    --he-color-primary-100: var(--he-color-blue-100);
    --he-color-primary-200: var(--he-color-blue-200);
    --he-color-primary-300: var(--he-color-blue-300);
    --he-color-primary-400: var(--he-color-blue-400);
    --he-color-primary-500: var(--he-color-blue-500);
    --he-color-primary-600: var(--he-color-blue-600);
    --he-color-primary-700: var(--he-color-blue-700);
    --he-color-primary-800: var(--he-color-blue-800);
    --he-color-primary-900: var(--he-color-blue-900);
    --he-color-primary-950: var(--he-color-blue-950);

    --he-color-success-50: var(--he-color-green-50);
    --he-color-success-100: var(--he-color-green-100);
    --he-color-success-200: var(--he-color-green-200);
    --he-color-success-300: var(--he-color-green-300);
    --he-color-success-400: var(--he-color-green-400);
    --he-color-success-500: var(--he-color-green-500);
    --he-color-success-600: var(--he-color-green-600);
    --he-color-success-700: var(--he-color-green-700);
    --he-color-success-800: var(--he-color-green-800);
    --he-color-success-900: var(--he-color-green-900);
    --he-color-success-950: var(--he-color-green-950);

    --he-color-warning-50: var(--he-color-yellow-50);
    --he-color-warning-100: var(--he-color-yellow-100);
    --he-color-warning-200: var(--he-color-yellow-200);
    --he-color-warning-300: var(--he-color-yellow-300);
    --he-color-warning-400: var(--he-color-yellow-400);
    --he-color-warning-500: var(--he-color-yellow-500);
    --he-color-warning-600: var(--he-color-yellow-600);
    --he-color-warning-700: var(--he-color-yellow-700);
    --he-color-warning-800: var(--he-color-yellow-800);
    --he-color-warning-900: var(--he-color-yellow-900);
    --he-color-warning-950: var(--he-color-yellow-950);

    --he-color-caution-50: hsl(15, 100%, 94.5%);
    --he-color-caution-100: hsl(15.6, 96.2%, 89.8%);
    --he-color-caution-200: hsl(16, 86.2%, 82.9%);
    --he-color-caution-300: hsl(15.6, 81.4%, 76.9%);
    --he-color-caution-400: hsl(15.9, 78.5%, 70.8%);
    --he-color-caution-500: hsl(15.9, 76.1%, 63.9%);
    --he-color-caution-600: hsl(16.4, 75.3%, 57.1%);
    --he-color-caution-700: hsl(16.1, 74.8%, 50.2%);
    --he-color-caution-800: hsl(16.2, 99.1%, 42.5%);
    --he-color-caution-900: hsl(16.5, 98.8%, 32.5%);
    --he-color-caution-950: hsl(16.2, 98.3%, 22.9%);

    --he-color-danger-50: var(--he-color-red-50);
    --he-color-danger-100: var(--he-color-red-100);
    --he-color-danger-200: var(--he-color-red-200);
    --he-color-danger-300: var(--he-color-red-300);
    --he-color-danger-400: var(--he-color-red-400);
    --he-color-danger-500: var(--he-color-red-500);
    --he-color-danger-600: var(--he-color-red-600);
    --he-color-danger-700: var(--he-color-red-700);
    --he-color-danger-800: var(--he-color-red-800);
    --he-color-danger-900: var(--he-color-red-900);
    --he-color-danger-950: var(--he-color-red-950);

    --he-color-neutral-50: var(--he-color-gray-50);
    --he-color-neutral-100: var(--he-color-gray-100);
    --he-color-neutral-150: var(--he-color-gray-150);
    --he-color-neutral-200: var(--he-color-gray-200);
    --he-color-neutral-250: var(--he-color-gray-250);
    --he-color-neutral-300: var(--he-color-gray-300);
    --he-color-neutral-350: var(--he-color-gray-350);
    --he-color-neutral-400: var(--he-color-gray-400);
    --he-color-neutral-450: var(--he-color-gray-450);
    --he-color-neutral-500: var(--he-color-gray-500);
    --he-color-neutral-550: var(--he-color-gray-550);
    --he-color-neutral-600: var(--he-color-gray-600);
    --he-color-neutral-650: var(--he-color-gray-650);
    --he-color-neutral-700: var(--he-color-gray-700);
    --he-color-neutral-750: var(--he-color-gray-750);
    --he-color-neutral-800: var(--he-color-gray-800);
    --he-color-neutral-850: var(--he-color-gray-850);
    --he-color-neutral-900: var(--he-color-gray-900);
    --he-color-neutral-950: var(--he-color-gray-950);

    --he-color-neutral-0: hsl(0 0% 100%);
    --he-color-neutral-1000: hsl(0 0% 0%);

    /**
     * Neutral palette
     */

    --he-neutral-foreground: var(--he-type-primary);

    --he-neutral-fill-rest: var(--he-color-neutral-100);
    --he-neutral-fill-hover: var(--he-color-neutral-200);
    --he-neutral-fill-active: var(--he-color-neutral-300);

    --he-neutral-fill-stealth-rest: var(--he-color-body-background);
    --he-neutral-fill-stealth-hover: var(--he-color-neutral-100);
    --he-neutral-fill-stealth-active: var(--he-color-neutral-150);

    /**
     * Accent palette
     */

    /* accent text colors */
    --he-accent-foreground-rest: var(--he-color-primary-800);
    --he-accent-foreground-hover: var(--he-color-primary-900);
    --he-accent-foreground-active: var(--he-color-primary-950);

    /* accent background colors */
    --he-accent-fill-rest: var(--he-color-primary-600);
    --he-accent-fill-hover: var(--he-color-primary-700);
    --he-accent-fill-active: var(--he-color-primary-800);

    /**
     * Body
     */

    --he-color-body-background: var(--he-color-neutral-0);

    /**
     * Surfaces
     */
    --he-color-surface-background: var(--he-color-neutral-0);
    --he-copilot-response-background: var(--he-color-neutral-0);

    /**
     * Dividers, borders, tooltips
     */

    --he-color-body-divider: var(--he-color-neutral-150);
    --he-color-card-divider: var(--he-color-neutral-200);
    --he-color-card-active-border: transparent;

    /* This variable are specific only to the outer border of a card. */
    --he-card-border-style: none;

    /**
     * Overlays
     */

    --he-overlay-background-color: hsl(240 5.9% 10% / 40%);

    /**
     * Typography
     */

    --he-type-primary: var(--he-color-neutral-850);
    --he-type-secondary: var(--he-color-neutral-700);
    --he-type-disabled: var(--he-color-neutral-450);
    --he-type-disabled-on-surfaces: var(--he-color-neutral-450);
    --he-type-on-accent: var(--he-color-neutral-0);
  }
`;
