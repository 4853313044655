import { state } from 'lit/decorators/state.js';
import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import HarmonyElement from './base.js';

/**
 * Base class for focusable components where the true focused element is inside the shadow root. This class delegates
 * focus to the first focusable element in the shadowroot, add the `he-focusable` class to the component and emit
 * `he-blur` and `he-focus` events when the component gains or loses focus. It also provides a `hasFocus` property that
 * can be used to style the component when it has focus.
 *
 * Make sure to document the `he-focus` and `he-blur` events in the component's JSDoc with `@event`.
 */
export default class HarmonyFocusableElement extends HarmonyElement {
  static shadowRootOptions = { ...LitElement.shadowRootOptions, delegatesFocus: true };
  // TODO: set these to not bubble by default in v7?
  protected focusableEventOptions: CustomEventInit = {};
  protected useBlurHandler = true;
  protected useFocusHandler = true;

  @state() protected hasFocus = false;
  @state() protected hadFocus = false;

  /** Automatically focuses on element on page load. */
  @property({ type: Boolean, reflect: true })
  public autofocus: boolean;

  connectedCallback() {
    super.connectedCallback();
    this.classList.add('he-focusable');

    if (this.useBlurHandler) this.addEventListener('blur', this.handleBlur);
    if (this.useFocusHandler) this.addEventListener('focus', this.handleFocus);
  }

  protected handleBlur(e?: Event) {
    if (this.hasFocus) {
      this.hadFocus = true;
      this.hasFocus = false;
      this.emit('he-blur', this.focusableEventOptions);
    }
  }

  protected handleFocus(e?: Event) {
    if (!this.hasFocus) {
      this.hasFocus = true;
      this.emit('he-focus', this.focusableEventOptions);
    }
  }
}
