import { keys } from '../utilities/key-map.js';
import { getTabbableBoundary } from '../utilities/tabbable.js';

let activeModals: HTMLElement[] = [];

export default class Modal {
  element: HTMLElement;
  tabDirection: 'forward' | 'backward' = 'forward';
  skip?: string;

  constructor(element: HTMLElement, skipSelector?: string) {
    this.element = element;
    this.skip = skipSelector;
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  activate() {
    activeModals.push(this.element);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  deactivate() {
    activeModals = activeModals.filter(modal => modal !== this.element);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  isActive() {
    // The "active" modal is always the most recent one shown
    return activeModals[activeModals.length - 1] === this.element;
  }

  handleKeyDown(event: KeyboardEvent) {
    if (!this.isActive() || event.key !== keys.Tab) return;

    const { start, end } = getTabbableBoundary(this.element, this.skip);
    if (!start || !end) return;

    const path = event.composedPath();

    // if shift tab is pushed on start, go to end, and if tab is pushed on end, go to start
    if (event.shiftKey && path.includes(start)) {
      end.focus();
      event.preventDefault();
    } else if (!event.shiftKey && path.includes(end)) {
      start.focus();
      event.preventDefault();
    }
  }
}
