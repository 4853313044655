import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    display: inline-block;
    height: var(--he-form-control-height-small);
    user-select: none;
  }

  .button {
    --button-background-color: var(--he-neutral-fill-rest);
    --button-background-color-hover: var(--he-neutral-fill-hover);
    --button-background-color-active: var(--he-neutral-fill-active);
    --button-foreground-color: var(--he-type-primary);
    --foreground-color-hover: var(--button-foreground-color);
    --foreground-color-active: var(--button-foreground-color);
    --button-border-color: transparent;
    --padding-x: var(--he-spacing-x-small);
    height: 100%;
    border-radius: var(--he-border-radius-pill);
  }

  :host([disabled]) .button {
    --button-foreground-color: var(--he-type-disabled);
  }

  :host([appearance='accent']) .button {
    --button-background-color: var(--he-accent-fill-rest);
    --button-background-color-hover: var(--he-accent-fill-hover);
    --button-background-color-active: var(--he-accent-fill-active);
    --button-foreground-color: var(--he-type-on-accent);
  }

  :host([appearance='error']) .button {
    --button-background-color: var(--he-color-danger-800);
    --button-background-color-hover: var(--he-color-danger-700);
    --button-background-color-active: var(--he-color-danger-800);
    --button-foreground-color: var(--he-type-on-accent);
  }

  .button::part(control):focus-visible {
    box-shadow: inset var(--he-focus-ring);
    border-radius: 0;
  }

  .button:not(.icon-only)::part(control) {
    padding-inline-start: var(--he-spacing-2x-small);
    padding-inline-end: var(--he-spacing-2x-small);
  }

  .button.icon-only {
    --padding-x: 0;
  }

  .button.icon-only::part(content) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .values,
  .more {
    font-weight: var(--he-font-weight-semibold);
  }

  .clear,
  ::slotted(.he-icon) {
    font-size: var(--he-chevron-size);
  }

  .button:not(.icon-only) .clear {
    margin-inline-start: var(--he-spacing-2x-small);
    margin-top: -2px;
  }

  :host([appearance='neutral']) ::slotted(.he-icon[slot='start']) {
    color: var(--he-accent-fill-rest);
  }

  :host([appearance='neutral']) ::slotted(svg[slot='start']) {
    fill: var(--he-accent-fill-rest);
  }

  ::slotted(.he-filter-value--hidden) {
    display: none;
  }

  ::slotted([slot='value']:not(.he-filter-value--last))::after {
    content: var(--value-delimiter);
  }

  /** Button group styles */
  :host(.he-button-group__button--first) .button,
  :host(.he-button-group__button--inner) .button {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  :host(.he-button-group__button--last) .button,
  :host(.he-button-group__button--inner) .button {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  :host(.he-button-group__button--first) .button::part(control),
  :host(.he-button-group__button--inner) .button::part(control) {
    border-inline-end-width: 0;
    padding-inline-end: 0;
  }

  :host(.he-button-group__button--last) .button::part(control),
  :host(.he-button-group__button--inner) .button::part(control) {
    border-inline-start-width: 0;
    padding-inline-start: 0;
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .button {
      --button-border-color: ${SystemColors.ButtonText};
    }

    .button,
    .button:hover,
    .button:active {
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.ButtonText};
    }

    :host([appearance='accent']) .button {
      --button-border-color: ${SystemColors.HighlightText};
    }

    :host([appearance='accent']) .button,
    :host([appearance='accent']) .button:hover,
    :host([appearance='accent']) .button:active {
      background-color: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }

    :host([disabled]) .button,
    :host([disabled][appearance]) .button {
      --button-border-color: ${SystemColors.GrayText};
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.GrayText};
    }

    .button::part(control):focus-visible {
      box-shadow: none;
      border-radius: inherit;
    }

    :host(.he-button-group__button--last) .button::part(control),
    :host(.he-button-group__button--inner) .button::part(control) {
      border-inline-start-width: 1px;
    }
  }
`;
