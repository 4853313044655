//
// Waits for a specific event to be emitted from an element. Ignores events that bubble up from child elements.
//
export function waitForEvent(el: HTMLElement, eventName: string) {
  return new Promise<void>(resolve => {
    function done(event: Event) {
      if (event.target === el) {
        el.removeEventListener(eventName, done);
        resolve();
      }
    }

    el.addEventListener(eventName, done);
  });
}

/*
 * Waits for a specific event to be emitted from an element. Does not ignore events bubbled from children.
 * @example
 *   await waitForAnyEvent(element, "he-after-show", () => element.click())
 */
export function waitForAnyEvent(el: HTMLElement, eventName: string, callback: () => void) {
  return new Promise<void>(resolve => {
    function done() {
      resolve();
    }
    el.addEventListener(eventName, done);

    // We have to wait for next loop to trigger the callback in webkit, otherwise we make things happen too fast.
    setTimeout(() => {
      callback();
    }, 100);
  });
}
