import { css } from 'lit';

export default css`
  .he-table {
    --border-color: var(--he-color-gray-150);
    --hover-background-color: var(--he-color-neutral-100);
    --selected-background-color: var(--he-color-neutral-150);
    --selected-hover-background-color: var(--he-color-neutral-200);
    --table-color: var(--he-color-neutral-800);
    --table-header-color: var(--he-color-neutral-850);
    --row-header-color: var(--he-color-neutral-850);

    border-collapse: separate !important;
    border-spacing: 0;
    min-width: 100%;
    table-layout: fixed;
    font-family: var(--he-body-font);
  }

  .he-table tbody {
    background-color: var(--he-color-body-background);
    color: var(--table-color);
  }

  .he-table tr th,
  .he-table tr td {
    vertical-align: top;
    background-color: inherit;
    padding: var(--he-spacing-small);
    border: none;
    border-bottom: 1px solid var(--border-color);
  }

  .he-table tr th {
    font-weight: var(--he-font-weight-normal);
    text-align: start;
  }

  .he-table thead tr,
  .he-table tbody tr {
    background-color: var(--he-color-body-background) !important;
  }

  .he-table tbody tr {
    color: var(--he-color-neutral-650);
    font-size: var(--he-font-size-medium);
  }

  .he-table tbody tr:focus-within {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: -1px;
  }

  .he-table tbody tr[aria-selected='true'] {
    background-color: var(--selected-background-color) !important;
  }

  .he-table thead tr th,
  .he-table thead tr th button {
    color: var(--table-header-color);
    font-family: var(--he-body-font);
    font-size: var(--he-font-size-medium);
    font-weight: var(--he-font-weight-semibold);
  }

  .he-table thead tr th {
    vertical-align: top;
  }

  .he-table thead tr th button {
    vertical-align: middle;
  }

  .he-table thead tr th button:focus-visible {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 2px;
  }

  .he-table tbody tr th {
    color: var(--row-header-color);
  }

  /* Hover Rows */
  .he-table.he-table--hover tbody tr:hover {
    color: var(--he-color-neutral-800);
    background-color: var(--hover-background-color) !important;
  }

  .he-table.he-table--hover tbody tr[aria-selected='true']:hover {
    background-color: var(--selected-hover-background-color) !important;
  }

  /* Fixed Heading */
  .he-table.he-table--fixed-heading thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .he-table.he-table--fixed-heading thead tr > :first-child {
    top: 0;
    z-index: 3;
  }

  /* Fixed Column */
  .he-table.he-table--fixed-column tr > :first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  html[dir='rtl'] .he-table.he-table--fixed-column:not([dir='ltr']) tr > :first-child,
  :host([dir='rtl']) .he-table.he-table--fixed-column:not([dir='ltr']) tr > :first-child,
  .he-table.he-table--fixed-column[dir='rtl'] tr > :first-child {
    left: unset;
    right: 0;
  }

  /*
    Fix border not showing up when using position: sticky
    https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists
  */
  .he-table.he-table--fixed-heading tr,
  .he-table.he-table--fixed-column tr {
    background-clip: padding-box;
  }

  /* Responsive Table */
  .he-responsive-table {
    width: 100%;
    overflow-x: auto;
  }
`;
