import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    display: block;
    overflow: hidden;
    position: relative;
    --color: var(--he-neutral-fill-hover);
    --shimmer-color: var(--he-neutral-fill-rest);
  }

  .skeleton {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 1rem;
    background: var(--color);
  }

  .skeleton.skeleton--shimmer {
    background: linear-gradient(270deg, var(--shimmer-color), var(--color), var(--color), var(--shimmer-color));
    background-size: 400% 100%;
    background-size: 400% 100%;
    animation: shimmer 7s ease-in-out infinite;
  }

  .skeleton--rect {
    border-radius: var(--he-border-radius-small);
  }

  .skeleton--circle {
    border-radius: 100%;
    overflow: hidden;
  }

  object {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 2;
  }

  object img {
    width: 100%;
    height: auto;
  }

  ::slotted(svg) {
    z-index: 2;
  }

  ::slotted(.pattern) {
    width: 100%;
    height: 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    to {
      background-position: -200% 0;
    }
  }

  @media screen and (forced-colors: active) {
    .skeleton,
    .skeleton.skeleton--shimmer {
      background: ${SystemColors.GrayText};
    }
  }
`;
