import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    display: block;
  }

  :host {
    --current-page-border-color: var(--he-accent-foreground-rest);
    --button-hover-background-color: var(--he-neutral-fill-stealth-hover);
  }

  .pagination {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .pagination__list {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .pagination__item--hidden {
    display: none;
  }

  .pagination__button {
    background-color: transparent;
    color: var(--he-type-secondary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--he-font-size-medium);
    min-width: 2rem;
    line-height: 1;
    padding: var(--he-spacing-x-small) var(--he-spacing-2x-small);
    margin: 0 var(--he-spacing-x-small);
    border-radius: var(--he-border-radius-small);
    border-bottom: solid 2px transparent;
  }

  .pagination__button:hover {
    background-color: var(--button-hover-background-color);
  }

  .pagination__button:focus-visible {
    box-shadow: var(--he-focus-ring);
    outline: none;
  }

  .pagination__button::-moz-focus-inner {
    border: 0;
  }

  .pagination__button--current {
    border-bottom-color: var(--current-page-border-color);
    font-weight: var(--he-font-weight-semibold);
  }

  .pagination__button--disabled {
    cursor: not-allowed;
    color: var(--he-type-disabled);
  }

  .pagination__button--disabled:hover {
    background-color: transparent;
  }

  .pagination__previous-page {
    padding-inline-start: var(--he-spacing-x-small);
    padding-inline-end: var(--he-spacing-x-small);
  }

  .pagination__next-page {
    padding-inline-start: var(--he-spacing-x-small);
    padding-inline-end: var(--he-spacing-x-small);
  }

  .pagination__icon {
    font-size: var(--he-font-size-medium);
  }

  .pagination--rtl .pagination__icon {
    transform: rotate(180deg);
  }

  .pagination__prev-icon {
    margin-inline-end: var(--he-spacing-2x-small);
    margin-inline-start: calc(var(--he-spacing-2x-small) * -1);
  }

  .pagination__next-icon {
    margin-inline-start: var(--he-spacing-2x-small);
    margin-inline-end: calc(var(--he-spacing-2x-small) * -1);
  }

  .pagination__first-icon {
    margin-inline-end: var(--he-spacing-2x-small);
  }

  .pagination__last-icon {
    margin-inline-start: var(--he-spacing-2x-small);
  }

  .pagination__first-icon,
  .pagination--rtl .pagination__last-icon {
    transform: rotate(-90deg);
  }

  .pagination__last-icon,
  .pagination--rtl .pagination__first-icon {
    transform: rotate(90deg);
  }

  .pagination__spacer {
    margin: 0 var(--he-spacing-2x-small);
  }

  .pagination__dropdown__menu {
    min-width: 2rem;
    text-align: center;
  }

  @media screen and (forced-colors: active) {
    .pagination__button {
      forced-color-adjust: none;
      border: var(--he-form-control-border-width) solid ${SystemColors.ButtonText};
    }

    .pagination__button,
    .pagination__button:hover:not(.pagination__button--disabled),
    .pagination__button:active:not(.pagination__button--disabled),
    .pagination__button:focus-visible {
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.ButtonText};
    }

    .pagination__button--current,
    .pagination__button--current:hover:not(.pagination__button--disabled),
    .pagination__button--current:active:not(.pagination__button--disabled),
    .pagination__button--current:focus-visible {
      background-color: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }

    .pagination__button--disabled {
      color: ${SystemColors.GrayText};
      border-color: ${SystemColors.GrayText};
    }

    .pagination__button:hover:not(.pagination__button--disabled),
    .pagination__button:active:not(.pagination__button--disabled),
    .pagination__button:focus-visible {
      box-shadow: none;
      outline: ${SystemColors.ButtonText} solid 2px;
      outline-offset: -3px;
    }

    [class*='icon'] {
      fill: ${SystemColors.ButtonText};
    }
  }
`;
