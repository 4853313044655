import { css } from 'lit';

export default css`
  :host {
    display: block;
  }

  [hidden] {
    display: none;
  }

  ::slotted(.he-quick-nav) {
    margin: 0 calc(var(--he-spacing-x-small) * -1);
    --button-font-size: var(--he-font-size-3x-large);
    --button-line-height: var(--he-line-height-3x-large);
  }
`;
