import { css } from 'lit';

export default css`
  :host {
    display: block;
  }

  .search-box {
    flex: 1 1 auto;
    display: inline-flex;
    align-items: stretch;
    justify-content: start;
    position: relative;
    width: 100%;
    height: var(--he-form-control-height);
    font-family: var(--he-form-control-font-family);
    font-size: var(--he-form-control-font-size);
    line-height: var(--he-line-height-normal);
    vertical-align: middle;
    background-color: var(--he-color-neutral-0);
    border-radius: var(--he-form-control-border-radius);
    border: solid var(--he-form-control-border-width) var(--he-form-control-border-color);
    overflow: hidden;
    cursor: text;
  }

  .search-box--focused {
    border-color: var(--he-form-control-focus-ring-color);
    outline: solid var(--he-form-control-border-width) var(--he-form-control-focus-ring-color);
    outline-offset: -2px;
  }

  .search-box--disabled {
    opacity: var(--he-form-control-disabled-opacity);
    cursor: not-allowed;
  }

  .search-box--disabled .search-box__control::placeholder {
    color: var(--he-form-control-placeholder-color);
  }

  .search-box__control {
    flex: 1 1 auto;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    min-width: 0;
    color: var(--he-form-control-color);
    border: none;
    background: none;
    box-shadow: none;
    margin: 0;
    cursor: inherit;
    -webkit-appearance: none;
    height: calc(var(--he-form-control-height) - var(--he-form-control-border-width) * 2);
    padding: 0 var(--he-spacing-x-small);
  }

  .search-box__control::-webkit-search-decoration,
  .search-box__control::-webkit-search-cancel-button,
  .search-box__control::-webkit-search-results-button,
  .search-box__control::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .search-box__control:-webkit-autofill,
  .search-box__control:-webkit-autofill:hover,
  .search-box__control:-webkit-autofill:focus,
  .search-box__control:-webkit-autofill:active {
    box-shadow: 0 0 0 var(--he-form-control-height) var(--he-color-neutral-0) inset !important;
    -webkit-text-fill-color: var(--he-color-primary-500);
    caret-color: var(--he-input-color);
  }

  .search-box__control::placeholder {
    color: var(--he-form-control-placeholder-color);
    user-select: none;
  }

  .search-box__control:focus {
    outline: none;
  }

  .search-box__search-icon {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    color: var(--he-color-primary-600);
    padding-inline-start: var(--he-spacing-x-small);
    padding-inline-end: var(--he-spacing-2x-small);
    cursor: default;
  }

  /*
   * Clearable
   */
  .search-box__clear {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    color: var(--he-form-control-color);
    border: none;
    background: none;
    padding: 0;
    padding-inline-end: var(--he-spacing-x-small);
    cursor: pointer;
  }

  .search-box__clear:hover {
    color: var(--he-form-control-color);
  }

  .search-box__clear:focus {
    outline: none;
  }

  .search-box--empty .search-box__clear {
    visibility: hidden;
  }

  .search-box__clear-icon {
    font-size: var(--he-font-size-medium);
  }

  .form-control--has-interaction.form-control--has-error .search-box {
    border-color: var(--he-form-control-error-color) !important;
    outline-color: var(--he-form-control-error-color) !important;
  }
`;
