import { html } from 'lit/static-html.js';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { animateTo, parseDuration, stopAnimations } from '../../internal/animations.js';
import { waitForEvent } from '../../internal/event.js';
import { watch } from '../../internal/watch.js';
import HarmonyElement from '../../base-components/base.js';
import componentStyles from '../../internal/styles/component.styles.js';
import popup from '../popup/popup.js';
import { Component } from '../../utilities/decorators.js';
import styles from './tooltip.styles.js';
import type { PopupPlacement } from '../popup/popup.js';

import type { Popup } from '../popup/popup.js';
import type { CSSResultGroup } from 'lit';

export type TooltipPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'start'
  | 'start-top'
  | 'start-bottom'
  | 'end'
  | 'end-top'
  | 'end-bottom'
  /* deprecated placements: */
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

/**
 *
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 *
 * @tag he-tooltip
 * @since 2.0
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19312&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-popup
 *
 * @slot - The tooltip's content.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-show - Emitted when the tooltip begins to show.
 * @event he-after-show - Emitted after the tooltip has shown and all animations are complete.
 * @event he-hide - Emitted when the tooltip begins to hide.
 * @event he-after-hide - Emitted after the tooltip has hidden and all animations are complete.
 *
 * @csspart base - The component's base wrapper, an `<he-popup>` element.
 * @csspart base__popup - The popup's `popup` part. Use this to target the tooltip's popup container.
 * @csspart base__arrow - The popup's `arrow` part. Use this to target the tooltip's arrow.
 * @csspart body - The tooltip's body.
 *
 * @cssproperty [--max-width=20rem] - The maximum width of the tooltip.
 * @cssproperty [--hide-delay=300ms] - The amount of time to wait before hiding the tooltip when hovering.
 * @cssproperty [--show-delay=0] - The amount of time to wait before showing the tooltip when hovering.
 * @cssproperty [--he-elevation=16] - Elevation of the tooltip.
 */
@Component('tooltip', [popup])
export class Tooltip extends HarmonyElement {
  static styles: CSSResultGroup = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeShow: new CustomEvent('he-show'),
    onHeAfterShow: new CustomEvent('he-after-show'),
    onHeHide: new CustomEvent('he-hide'),
    onHeAfterHide: new CustomEvent('he-after-hide'),
  };

  @query('.tooltip__body')
  private body: HTMLElement;

  @query('.tooltip')
  private popup: Popup;

  private anchorEl: HTMLElement;
  private hoverTimeout: number;

  /** When the anchor element is separate from the popup, provide its ID or a reference to the anchor element. */
  @property()
  anchor?: string | Element;

  /**
   * The preferred placement of the tooltip. Note that the actual placement may vary as needed to keep the tooltip
   * inside of the viewport.
   *
   * Note: "left" and "right" placements will be deprecated in a future version. Please use the logical properties
   * "start" and "end", which are based on language direction.
   */
  @property()
  placement: TooltipPlacement = 'bottom';

  /** Disables the tooltip so it won't show when triggered. */
  @property({ type: Boolean, reflect: true })
  disabled = false;

  /** The distance in pixels from which to offset the tooltip away from its target. */
  @property({ type: Number })
  distance = 12;

  /** Indicates whether or not the tooltip is open. You can use this in lieu of the show/hide methods. */
  @property({ type: Boolean, reflect: true })
  open = false;

  /** The distance in pixels from which to offset the tooltip along its target. */
  @property({ type: Number })
  skidding = 0;

  /**
   * Controls how the tooltip is activated. Possible options include `click`, `hover`, `focus`, and `manual`. Multiple
   * options can be passed by separating them with a space. When manual is used, the tooltip must be activated
   * programmatically.
   */
  @property()
  trigger = 'hover focus';

  /**
   * Enable this option to prevent the tooltip from being clipped when the component is placed inside a container with
   * `overflow: auto|hidden|scroll`.
   */
  @property({ attribute: 'fixed-placement', type: Boolean, reflect: true })
  fixedPlacement = false;

  connectedCallback() {
    super.connectedCallback();
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);

    this.updateComplete.then(() => this.attachListeners());
  }

  firstUpdated() {
    super.firstUpdated();

    this.body.hidden = !this.open;

    // If the tooltip is visible on init, update its position
    if (this.open) {
      this.popup.active = true;
      this.popup.reposition();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeListeners();
  }

  /** Shows the tooltip. */
  async show() {
    if (this.open) {
      return undefined;
    }

    this.open = true;
    return waitForEvent(this, 'he-after-show');
  }

  /** Hides the tooltip. */
  async hide() {
    if (!this.open) {
      return undefined;
    }

    this.open = false;
    return waitForEvent(this, 'he-after-hide');
  }

  private attachListeners() {
    if (this.anchorEl) {
      this.anchorEl.addEventListener('blur', this.handleBlur, true);
      this.anchorEl.addEventListener('focus', this.handleFocus, true);
      this.anchorEl.addEventListener('click', this.handleClick);
      this.anchorEl.addEventListener('keydown', this.handleKeyDown);
      this.anchorEl.addEventListener('mouseover', this.handleMouseOver);
      this.anchorEl.addEventListener('mouseout', this.handleMouseOut);
    }
  }

  private removeListeners() {
    if (this.anchorEl) {
      this.anchorEl.removeEventListener('blur', this.handleBlur, true);
      this.anchorEl.removeEventListener('focus', this.handleFocus, true);
      this.anchorEl.removeEventListener('click', this.handleClick);
      this.anchorEl.removeEventListener('keydown', this.handleKeyDown);
      this.anchorEl.removeEventListener('mouseover', this.handleMouseOver);
      this.anchorEl.removeEventListener('mouseout', this.handleMouseOut);
    }
  }

  /** @internal watcher */
  @watch('anchor')
  handleAnchorChange() {
    let target: HTMLElement | null = null;

    if (this.anchor instanceof HTMLElement) {
      // Anchor was passed as a reference
      target = this.anchor;
    } else if (typeof this.anchor === 'string' && this.anchor.length > 0) {
      // Anchor was passed as an id
      target = this.findRootNode(this)?.getElementById(this.anchor);
    }

    if (target) {
      this.removeListeners();
      this.anchorEl = target;
      this.attachListeners();
    }
  }

  private handleBlur() {
    if (this.hasTrigger('focus')) {
      this.hide();
    }
  }

  private handleClick() {
    if (this.hasTrigger('click')) {
      if (this.open) {
        this.hide();
      } else {
        this.show();
      }
    }
  }

  private handleFocus() {
    if (this.hasTrigger('focus')) {
      this.show();
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    // Pressing escape when the target element has focus should dismiss the tooltip
    if (this.open && event.key === 'Escape') {
      event.stopPropagation();
      this.hide();
    }
  }

  private handleMouseOver() {
    if (this.hasTrigger('hover')) {
      const delay = parseDuration(getComputedStyle(this).getPropertyValue('--show-delay'));
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = window.setTimeout(() => this.show(), delay);
    }
  }

  private handleMouseOut() {
    if (this.hasTrigger('hover')) {
      const delay = parseDuration(getComputedStyle(this).getPropertyValue('--hide-delay'));
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = window.setTimeout(() => this.hide(), delay);
    }
  }

  /** @internal watcher */
  @watch('open', { waitUntilFirstUpdate: true })
  async handleOpenChange() {
    if (this.open) {
      if (this.disabled) {
        return;
      }

      // Show
      this.emit('he-show');

      this.body.hidden = false;
      this.popup.active = true;

      await stopAnimations(this.popup.popup);
      await animateTo(this.popup.popup, [{ opacity: 0 }, { opacity: 1 }], { duration: 150 });
      await this.updateComplete;
      this.emit('he-after-show');
    } else {
      // Hide
      this.emit('he-hide');

      await stopAnimations(this.popup.popup);
      await animateTo(this.popup.popup, [{ opacity: 1 }, { opacity: 0 }], { duration: 50 });

      this.popup.active = false;
      this.body.hidden = true;

      await this.updateComplete;
      this.emit('he-after-hide');
    }
  }

  /** @internal watcher */
  @watch('content')
  @watch('distance')
  @watch('fixedPlacement')
  @watch('placement')
  @watch('skidding')
  async handleOptionsChange() {
    if (this.hasUpdated) {
      await this.updateComplete;
      this.popup.reposition();
    }
  }

  /** @internal watcher */
  @watch('disabled')
  handleDisabledChange() {
    if (this.disabled && this.open) {
      this.hide();
    }
  }

  private hasTrigger(triggerType: string) {
    const triggers = this.trigger.split(' ');
    return triggers.includes(triggerType);
  }

  /**
   * Converts TooltipPlacement to PopupPlacement
   * Popup doesn't have "start" or "end" as it's first position (before the -) only "left" and "right", so convert
   * tooltips's start/end at the first position into left/right according to language direction.
   * To avoid confusion, tooltip uses "top" and "bottom" as the second position when the first is "start" or "end", so
   * change those to start/end for popup.
   */
  private popupPlacement(position: TooltipPlacement) {
    if (!position) return;

    const splitPosition = position.split('-');
    const dir = this.dir;

    const replaceObj = {
      start: dir === 'ltr' ? 'left' : 'right',
      end: dir === 'ltr' ? 'right' : 'left',
    };
    const re = new RegExp(Object.keys(replaceObj).join('|'), 'gi');

    const firstPlacement = splitPosition[0].replace(re, matched => replaceObj[matched as keyof typeof replaceObj]);
    const secondPlacement = splitPosition[1]?.replace('top', 'start').replace('bottom', 'end');

    const popupPlacement = firstPlacement + (secondPlacement ? `-${secondPlacement}` : '');

    return popupPlacement as PopupPlacement;
  }

  render() {
    return html`
      <${this.scope.tag('popup')}
        part="base"
        exportparts="
          popup:base__popup,
          arrow:base__arrow
        "
        class=${classMap({
          tooltip: true,
          'tooltip--open': this.open,
        })}
        .anchor=${this.anchorEl}
        placement=${this.popupPlacement(this.placement)}
        distance=${this.distance}
        skidding=${this.skidding}
        strategy=${this.fixedPlacement ? 'fixed' : 'absolute'}
        flip
        shift
        arrow
      >
        <div part="body" id="tooltip" class="tooltip__body">
          <slot></slot>
        </div>
      </${this.scope.tag('popup')}>
      <span class="visually-hidden" aria-live="polite" aria-relevant="additions">${
        this.open ? this.textContent : ''
      }</span>
    `;
  }
}

export default Tooltip;
