import { css, CSSResult } from 'lit';

export const scrollbarStyles: CSSResult = css`
  ::-webkit-scrollbar {
    width: var(--he-scrollbar-width, 6px);
    height: var(--he-scrollbar-width, 6px);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--he-color-neutral-100);
    border-radius: var(--he-scrollbar-radius, 6px);
  }

  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 40px;
    border-radius: var(--he-scrollbar-radius, 6px);
    background-color: var(--he-color-neutral-300);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--he-color-neutral-400);
  }
`;
