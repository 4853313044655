import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';
import { visuallyHiddenStyles } from './visually-hidden.js';

export default css`
  :host,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  :host {
    font-family: var(--he-body-font);
    forced-color-adjust: auto;
  }

  [hidden] {
    display: none !important;
  }

  :host([hidden]) {
    display: none !important;
  }

  input,
  button,
  select,
  textarea,
  fieldset,
  legend {
    margin: 0;
    padding: 0;
    border: none;
    letter-spacing: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  .visually-hidden {
    ${visuallyHiddenStyles};
  }

  ::-webkit-scrollbar {
    width: var(--he-scrollbar-width);
    height: var(--he-scrollbar-width);
  }
  ::-webkit-scrollbar-track {
    background-color: var(--he-color-neutral-100);
    border-radius: var(--he-scrollbar-radius);
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 40px;
    border-radius: var(--he-scrollbar-radius);
    background-color: var(--he-color-neutral-300);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--he-color-neutral-400);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    ::-webkit-scrollbar-track {
      background-color: ${SystemColors.ButtonFace};
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${SystemColors.ButtonText};
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${SystemColors.ButtonText};
    }
  }
`;
