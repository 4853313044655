import { property, state } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { defaultValue } from '../internal/default-value.js';
import HarmonyFocusableElement from './focusable.js';

/**
 * Base class for input components.
 */
export default class HarmonyInputElement extends HarmonyFocusableElement {
  protected input?: HTMLInputElement | HTMLTextAreaElement;
  protected _errorMessage: string;
  protected _value: string | number = '';
  protected _disabled: boolean = false;
  protected _readonly: boolean = false;
  protected timeOut: NodeJS.Timeout | null = null;

  get value(): string | number {
    return this._value;
  }

  /** The input's value attribute. */
  @property({ reflect: true })
  set value(val: string | number) {
    this._value = val ? val.toString() : '';
    this.requestUpdate('value');
  }

  /** @internal Gets or sets the default value used to reset this element. The initial value corresponds to the one originally specified in the HTML that created this element. */
  @defaultValue()
  defaultValue: string | number | Array<string | number> = '';

  /** The delay of the input's `he-input` event and `he-change` event in milliseconds. */
  @property({ type: Number, reflect: true }) debounce = 0;

  /** The input's name attribute. */
  @property({ reflect: true }) name?: string;

  /** The input's label. If you need to display HTML, you can use the `label` slot instead. */
  @property({ reflect: true }) label? = '';

  /** The input's help text. Alternatively, you can use the help-text slot. */
  @property({ attribute: 'help-text' }) helpText?: string;

  /** The input's placeholder text. */
  @property({ reflect: true }) placeholder?: string;

  /** Makes the input a required field. */
  @property({ type: Boolean, reflect: true }) required = false;

  /** Focus on the input on page load. */
  @property({ type: Boolean, reflect: true }) autofocus: boolean;

  /** This will be true when the control is in an invalid state. Validity is determined by the `required` prop. */
  @property({ type: Boolean, reflect: true })
  invalid = false;

  /** Hides the input label and help text. */
  @property({ type: Boolean, attribute: 'hide-label', reflect: true })
  hideLabel = false;

  /** Disables the input. */
  @property({ type: Boolean, reflect: true })
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(val: boolean) {
    this._disabled = val;
    this.requestUpdate('disabled');
    this.updateValidity();
  }

  /** Makes the input readonly. */
  @property({ type: Boolean, reflect: true })
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(val: boolean) {
    this._readonly = val;
    this.requestUpdate('readonly');
    this.updateValidity();
  }

  /** The error message of the input. */
  @property({ attribute: 'error-message', reflect: true })
  get errorMessage(): string {
    return this.customErrorMessage || this._errorMessage;
  }

  set errorMessage(message: string) {
    this.setCustomValidity(message);
  }

  @state()
  protected customErrorMessage: string = '';

  connectedCallback() {
    super.connectedCallback();
  }

  /** Gets the current validation message, if one exists. */
  get validationMessage() {
    this.checkValidity();
    return this.errorMessage;
  }

  /** Gets the validity state of the input. */
  get validity(): ValidityState | object {
    return this.input?.validity || {};
  }

  /** Checks for validity but doesn't report a validation message when invalid. */
  checkValidity() {
    if (!this.hasFocus && this.input?.validationMessage) {
      this._errorMessage = this.input?.validationMessage;
    }
    return this.input?.checkValidity();
  }

  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  public reportValidity() {
    this.hadFocus = true;
    if (this.customErrorMessage) {
      this.invalid = true;
    } else {
      this.invalid = !this.input?.checkValidity();
      if (this.input?.validationMessage) {
        this._errorMessage = this.input?.validationMessage;
      }
    }

    return !this.invalid;
  }

  /** Sets a custom validation message. If `message` is not empty, the field will be considered invalid. */
  public setCustomValidity(message: string = '') {
    this.hadFocus = true;
    this.customErrorMessage = message;
    this.invalid = message !== '';
    this.input?.setCustomValidity(message);
  }

  protected updateValidity() {
    this.updateComplete.then(() => {
      // Disabled and readonly form controls are always valid, so we need to recheck validity when the state changes
      this.invalid = this.disabled || this.readonly ? false : !this.checkValidity();
    });
  }

  protected delay(fn: () => void, debounce: number) {
    if (this.timeOut) clearTimeout(this.timeOut);
    this.timeOut = setTimeout(fn, debounce);
  }

  protected emitInput(options?: CustomEventInit) {
    const emitInputFunction = () => {
      this.emit('he-input', options);
      this.emit('input');
    };
    this.debounce === 0 ? emitInputFunction() : this.delay(emitInputFunction, this.debounce);
  }

  protected emitChange(options?: CustomEventInit) {
    const emitChangeFunction = () => {
      this.emit('he-change', options);
      this.emit('change');
    };
    this.debounce === 0 ? emitChangeFunction() : this.delay(emitChangeFunction, this.debounce);
  }

  protected requiredTemplate(announceVisuallyHiddenRequired: boolean = false) {
    const requiredTemplate = html`<span class="form-control__star" aria-hidden="true">*</span>
      ${announceVisuallyHiddenRequired
        ? html` <span class="visually-hidden">${this.localize.term('required')}</span> `
        : ''} `;
    return this.required ? requiredTemplate : '';
  }

  protected errorTextTemplate(formController: boolean = true) {
    const errorText = this.customErrorMessage || this.errorMessage;
    const message = formController
      ? html` <span part="form-control-error-text-message" class="form-control__error-text-message"></span> `
      : html`
          <span part="form-control-error-text-message" class="form-control__error-text-message">${errorText}</span>
        `;
    return html`
      <div
        part="form-control-error-text error-message"
        id="error-text"
        class="form-control__error-text"
        aria-live="assertive"
        role="alert"
      >
       <${this.scope.tag('icon')}
          part="form-control-error-text-icon"
          class="form-control__error-text-icon"
          name="criticalerrorsolid"
          label=${ifDefined(errorText ? this.localize.term('error') : undefined)}
        ></${this.scope.tag('icon')}>
        ${message}
      </div>
    `;
  }
}
