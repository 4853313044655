import { PortalEnvironment } from '../context/environment-helper';
import { TelemetryConfig } from './types/telemetry-config';

export const LibraryTelemetryConfig: Map<PortalEnvironment, TelemetryConfig> = new Map([
  [
    PortalEnvironment.prod,
    <TelemetryConfig>{
      instrumentationKey: 'da6c1a8d-8061-4e46-9924-f3ff1dfafa4c',
    },
  ],
  [
    PortalEnvironment.int,
    <TelemetryConfig>{
      instrumentationKey: '629c2654-c78d-46b1-a3bf-c01d3f768f20',
    },
  ],
  [
    PortalEnvironment.dev,
    <TelemetryConfig>{
      instrumentationKey: '1137f359-0353-4091-a6ff-881348ce4bd4',
    },
  ],
  [
    PortalEnvironment.tst,
    <TelemetryConfig>{
      instrumentationKey: '1137f359-0353-4091-a6ff-881348ce4bd4',
    },
  ],
  [
    PortalEnvironment.ppe,
    <TelemetryConfig>{
      instrumentationKey: '1137f359-0353-4091-a6ff-881348ce4bd4',
    },
  ],
]);
