import { css } from 'lit';

import shared from './shared.styles.js';
import light from './light.styles.js';
import dark from './dark.styles.js';

export default css`
  ${light}
  ${dark}
  ${shared}
`;
