import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    --ring-size: var(--he-spacing-2x-large);
    --track-width: 3px;
    --track-color: var(--he-color-primary-200);
    --indicator-color: var(--he-color-primary-600);
    --label-font-size: var(--he-font-size-small);
    --label-line-height: var(--he-line-height-medium);
    --label-color: var(--he-color-primary-600);
  }

  .progress-ring {
    display: inline-flex;
    gap: var(--he-spacing-x-small);
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .progress-ring--start {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .progress-ring--end {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .progress-ring--top {
    flex-direction: column-reverse;
  }

  .progress-ring__image {
    width: var(--ring-size);
    height: var(--ring-size);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .progress-ring__container {
    line-height: 1px;
  }

  .progress-ring__track,
  .progress-ring__indicator {
    --radius: calc(var(--ring-size) / 2 - var(--track-width) * 0.5);
    --circumference: calc(var(--radius) * 2 * 3.141592654);
    --indeterminate-chunk: calc(var(--radius) * 1.5);
    fill: none;
    stroke-width: var(--track-width);
    r: var(--radius);
    cx: calc(var(--ring-size) / 2);
    cy: calc(var(--ring-size) / 2);
  }

  .progress-ring__track {
    stroke: var(--track-color);
  }

  .progress-ring__indicator {
    stroke: var(--indicator-color);
    stroke-linecap: round;
    transition: 0.35s stroke-dashoffset;
    stroke-dasharray: var(--circumference) var(--circumference);
    stroke-dashoffset: calc(var(--circumference) - var(--percentage) * var(--circumference));
  }

  .progress-ring--indeterminate .progress-ring__indicator {
    stroke: var(--indicator-color);
    fill: none;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    animation: spin-infinite 1s linear infinite;
    stroke-dasharray: var(--circumference) var(--circumference);
    stroke-dashoffset: calc(var(--circumference) - 0.2 * var(--circumference));
  }

  @keyframes spin-infinite {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .progress-ring__indicator_indeterminate {
    stroke: var(--indicator-color);
    fill: none;
    stroke-width: var(--track-width);
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    transition: all 0.2s ease-in-out;
    animation: spin-infinite 2s linear infinite;
  }

  .progress-ring__label {
    width: 100%;
    height: 100%;
    text-align: center;
    user-select: none;
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    color: var(--label-color);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .progress-ring__track {
      stroke: ${SystemColors.HighlightText};
    }

    .progress-ring--indeterminate .progress-ring__indicator,
    .progress-ring__indicator {
      stroke: ${SystemColors.Highlight};
    }
  }
`;
