import { property, queryAssignedElements } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import HarmonyElement from '../../base-components/base.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './button-group.styles.js';

const BUTTON_CHILDREN = ['button', 'filter-pill'];

/**
 *
 * Button groups can be used to group related buttons into sections.
 *
 * @tag he-button-group
 * @since 5.5
 * @status stable
 * @design pending
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=15%3A4805&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @slot - One or more `<he-button>`, `<he-filter-pill>`, or `<he-dropdown>` elements to display in the button group.
 *
 * @csspart base - The component's base wrapper.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('button-group')
export class ButtonGroup extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** A label to use for the button group's aria-label attribute. */
  @property() label?: string;

  connectedCallback() {
    super.connectedCallback();
  }

  @queryAssignedElements({ flatten: true })
  private slottedElements!: Array<HTMLElement>;

  private setClasses() {
    this.slottedElements.forEach(el => {
      const index = this.slottedElements.indexOf(el);
      const button = this.findButton(el);
      const isCompound = !!this.querySelector('[slot="subtext"]');

      if (button !== null) {
        button.classList.add('he-button-group__button');
        button.classList.toggle('he-button-group__button--first', index === 0);
        button.classList.toggle('he-button-group__button--inner', index > 0 && index < this.slottedElements.length - 1);
        button.classList.toggle('he-button-group__button--last', index === this.slottedElements.length - 1);

        // if any button has "subtext" slot, this class will make sure all heights are the same
        button.classList.toggle('he-button-group__button--compound', isCompound);
      }
    });
  }

  private handleButtonCompoundChange(e: CustomEvent) {
    e.stopPropagation();
    this.setClasses();
  }

  private findButton(el: HTMLElement) {
    const buttonChildren = BUTTON_CHILDREN.map(tag => this.scope.tagName(tag));
    return buttonChildren.includes(el.tagName.toLowerCase()) ? el : el.querySelector(buttonChildren.join(','));
  }

  protected render() {
    return html`
      <div part="base" class="button-group" role="group" aria-label=${ifDefined(this.label)}>
        <slot @slotchange=${this.setClasses} @he-button-compound-change=${this.handleButtonCompoundChange}></slot>
      </div>
    `;
  }
}

export default ButtonGroup;
