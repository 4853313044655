import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    display: block;
  }

  :host,
  .data-grid,
  .data-grid__responsive-table {
    height: 100%;
  }

  .data-grid__responsive-table {
    overflow: auto;
  }

  .data-grid--no-scroll .data-grid__responsive-table {
    overflow: visible;
  }

  .data-grid__controls {
    margin-bottom: var(--he-spacing-small);
  }

  .data-grid__table {
    min-width: 100%;
  }

  .data-grid__table tbody tr:focus-within {
    outline: none;
  }

  .data-grid__table thead tr:focus-visible,
  .data-grid__table tbody tr:focus-visible,
  .data-grid__table th:focus-visible,
  .data-grid__table td:focus-visible {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: -1px;
  }

  /* when expand icons are present, we want focus ring on that cell to draw only around the content area, as
   the expanders are focused as if they treated like they are in a separate column. */
  .data-grid--tree .data-grid__table .data-grid__row-header:focus-visible,
  .data-grid--tree .data-grid__table .data-grid__table-header:focus-visible {
    outline: none;
  }
  .data-grid--tree .data-grid__table .data-grid__row-header:focus-visible .data-grid__cell-content,
  .data-grid--tree .data-grid__table .data-grid__table-header:focus-visible .data-grid__cell-content {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: calc(var(--he-spacing-small) - 1px);
  }

  .data-grid__loading {
    border-radius: 4px;
    height: 16px;
    width: 100%;
  }

  .data-grid__selector {
    margin: 0px;
  }

  .data-grid__sort {
    background-color: transparent;
    color: var(--he-type-primary);
    outline-offset: var(--he-spacing-2x-small);
    cursor: pointer;
    display: flex;
    align-items: baseline;
    text-align: start;
  }

  .data-grid__sort:hover .data-grid__sort-icon,
  .data-grid__sort:focus-visible .data-grid__sort-icon {
    visibility: visible;
  }

  .data-grid__sort-icon {
    font-size: var(--he-font-size-small);
    margin-inline-start: var(--he-spacing-x-small);
    visibility: hidden;
  }

  .data-grid__sort-icon--sorted {
    visibility: visible;
  }

  .data-grid__table-header__content {
    display: flex;
    align-items: center;
    position: relative;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }

  .data-grid__column__content {
    font-weight: var(--he-font-weight-semibold);
  }

  .data-grid__cell-content-wrapper {
    height: 100%;
    display: flex;
    align-items: stretch;
  }

  .data-grid__cell-content-wrapper .data-grid__cell-content {
    flex: 1;
  }

  .data-grid__table-header__content .data-grid__cell-content {
    display: flex;
  }

  .data-grid__table-header {
    font-weight: unset !important;
  }

  .data-grid__table-header--selector,
  .data-grid__table-header--selector .data-grid__table-header__content {
    width: var(--he-form-control-toggle-size);
  }

  .data-grid__table-header--selector.data-grid__table-header--bulk-options,
  .data-grid__table-header--selector.data-grid__table-header--bulk-options .data-grid__table-header__content {
    width: calc(var(--he-form-control-toggle-size) + var(--he-font-size-medium));
  }

  .data-grid__table-header__slot::slotted(*) {
    margin-top: -0.4em;
    margin-inline-start: var(--he-spacing-2x-small);
  }

  .data-grid__line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .data-grid__no-records__wrapper {
    min-height: 150px;
    padding: var(--he-spacing-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .data-grid__bulk-select {
    margin-inline-start: var(--he-spacing-3x-small);
  }

  .data-grid__bulk-select__trigger {
    background-color: transparent;
    color: var(--he-type-primary);
  }

  .data-grid__bulk-select__icon {
    padding: var(--he-spacing-2x-small);
    font-size: var(--he-font-size-medium);
  }

  .data-grid__bulk-select__trigger:focus-visible {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 2px;
  }

  .data-grid__bulk-select__options::part(content) {
    font-weight: var(--he-font-weight-normal);
  }

  .data-grid__expander {
    color: var(--he-color-neutral-650);
    width: var(--he-form-control-height);
    height: 100%;
    position: relative;
  }

  .data-grid__expander__button {
    transition: transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--he-form-control-height);
    height: var(--he-form-control-height);
    margin: -6px;
    background: none;
    cursor: pointer;
  }

  .data-grid__expander__button:focus-visible {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
  }

  .data-grid__expander__button:not(.data-grid__expander__button--expanded) {
    transform: rotate(-90deg);
  }

  .data-grid__expander__progress {
    --ring-size: var(--he-icon-size);
    height: var(--he-icon-size);
    position: absolute;
    top: 2px;
  }

  .data-grid__expander__progress,
  .data-grid__row[aria-busy='true'] .data-grid__expander__button .he-icon,
  .data-grid__row--hidden:not(.data-grid__row--animating) {
    display: none;
  }

  .data-grid__row[aria-busy='true'] .data-grid__expander__progress {
    display: block;
  }

  .data-grid__row--animating .data-grid__cell-content-wrapper,
  .data-grid__row--animating .data-grid__expander {
    overflow: hidden;
  }

  .data-grid--tree .data-grid__row-header,
  .data-grid--tree .data-grid__cell {
    transition: padding 200ms ease-in-out;
  }

  .data-grid__row--hidden .data-grid__row-header,
  .data-grid__row--hidden .data-grid__cell {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .data-grid__sort,
    .data-grid__bulk-select__trigger {
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.ButtonText};
    }

    [class*='icon'] {
      fill: ${SystemColors.ButtonText};
    }
  }
`;
