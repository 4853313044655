import { property } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';
import HarmonyElement from '../../base-components/base.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './skeleton.styles.js';

/**
 *
 * Skeleton is a component that is used to indicate that content is loading.
 *
 * @tag he-skeleton
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=294%3A3517&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @slot - The default slot can be used to optionally include inline SVG's rather than a pattern.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('skeleton')
export class Skeleton extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** Indicates that the component has an activated shimmer effect. */
  @property({ type: Boolean, reflect: true }) shimmer: boolean = false;

  /** The shape of the Skeleton. */
  @property() shape: 'rect' | 'circle' = 'rect';

  render() {
    return html`
      <div
        class=${classMap({
          skeleton: true,
          'skeleton--circle': this.shape === 'circle',
          'skeleton--rect': this.shape === 'rect',
          'skeleton--shimmer': this.shimmer,
        })}
        aria-busy="true"
        aria-live="polite"
      >
        <slot></slot>
      </div>
    `;
  }
}

export default Skeleton;
