import { offsetParent } from 'composed-offset-position';

/** Determines if the specified element is tabbable using heuristics inspired by https://github.com/focus-trap/tabbable */
export function isTabbable(el: HTMLElement, isRoot = false) {
  const tag = el.tagName.toLowerCase();

  // Elements with a -1 tab index are not tabbable
  if (el.getAttribute('tabindex') === '-1') {
    return false;
  }

  // Elements with a disabled attribute are not tabbable
  if (el.hasAttribute('disabled')) {
    return false;
  }

  // Elements with aria-disabled are not tabbable
  if (el.hasAttribute('aria-disabled') && el.getAttribute('aria-disabled') !== 'false') {
    return false;
  }

  // Radios without a checked attribute are not tabbable
  if (tag === 'input' && el.getAttribute('type') === 'radio' && !el.hasAttribute('checked')) {
    return false;
  }

  // Elements that are hidden have no offsetParent and are not tabbable
  if (el.offsetParent == null && offsetParent(el) == null) {
    return false;
  }

  // Elements without visibility are not tabbable
  if (window.getComputedStyle(el).visibility === 'hidden') {
    return false;
  }

  // Audio and video elements with the controls attribute are tabbable
  if ((tag === 'audio' || tag === 'video') && el.hasAttribute('controls')) {
    return true;
  }

  // Elements with a tabindex other than -1 are tabbable
  if (el.hasAttribute('tabindex')) {
    return true;
  }

  // Elements with a contenteditable attribute are tabbable
  if (el.hasAttribute('contenteditable') && el.getAttribute('contenteditable') !== 'false') {
    return true;
  }

  // Element with 'he-focusable' class are tabbable
  if (!isRoot && el.classList.contains('he-focusable')) {
    return true;
  }

  // At this point, the following elements are considered tabbable
  return ['button', 'input', 'select', 'textarea', 'a', 'audio', 'video', 'summary'].includes(tag);
}

/**
 * Returns the first and last bounding elements that are tabbable. This is more performant than checking every single
 * element because it short-circuits after finding the first and last ones.
 */
export function getTabbableBoundary(root: HTMLElement | ShadowRoot, skip?: string) {
  const allElements: Set<HTMLElement> = new Set();

  function walk(el: HTMLElement | ShadowRoot) {
    if (el instanceof HTMLElement) {
      if (allElements.has(el)) return;

      // look for slotted items
      if (el.tagName.toLowerCase() === 'slot') {
        const slot = el as HTMLSlotElement;
        slot.assignedElements().forEach(assigned => walk(assigned as HTMLElement));
      }

      // if element has 'he-focusable' class, don't walk its children
      if (root !== el && el.classList.contains('he-focusable')) {
        allElements.add(el);
        return;
      }

      // if there is a shadowroot, we want to get the slotted items from the shadow dom order - not the light dom order,
      // so we skip adding the light dom children, but walk the shadowroot to get both the slotted items and shadow dom children
      if (el.shadowRoot && el.shadowRoot.mode === 'open') {
        walk(el.shadowRoot);
      } else {
        allElements.add(el);
      }
    }

    const selector = skip ? `*:not(${skip})` : '*';

    [...el.querySelectorAll(selector)].map((e: HTMLElement) => walk(e));
  }

  // Collect all elements including the root
  walk(root);

  // Find the first and last tabbable elements
  const elementsArr = [...allElements];
  const start = elementsArr.find(el => isTabbable(el, el === root)) || null;
  const end = elementsArr.reverse().find(el => isTabbable(el, el === root)) || null;

  return { start, end };
}
