import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';
import { litElevation } from '../../internal/styles/elevation.js';

export default css`
  :host {
    --max-menu-height: 360px;
    --he-elevation: 8;
    display: inline-block;
    position: relative;
  }

  .dropdown {
    height: 100%;
  }

  .menu-wrapper {
    ${litElevation};
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background: var(--he-color-surface-background);
    border-radius: var(--he-menu-control-border-radius);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    max-height: min(var(--auto-size-available-height), var(--max-menu-height));
    max-width: min(var(--auto-size-available-width), calc(max-content - 4px));
    opacity: 0;
    transition: opacity 0.33s ease;
  }

  .dropdown:not(.dropdown--open) .popup::part(popup) {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .popup::part(popup) {
    z-index: 1000;
  }

  .dropdown--open .menu-wrapper {
    opacity: 1;
  }

  ::slotted(.he-menu) {
    box-shadow: none;
    background: transparent;
    max-height: auto;
  }

  .dropdown--open ::slotted(.he-button[slot='trigger'][appearance='command']),
  .dropdown--open ::slotted(.he-button[slot='trigger'][appearance='stealth']) {
    --button-background-color: var(--button-background-color-hover);
  }

  ::slotted(.he-button-group__button--compound) {
    height: 100%;
  }

  @media screen and (forced-colors: active) {
    .menu-wrapper {
      border: 1px solid ${SystemColors.CanvasText};
    }

    ::slotted(.he-menu) {
      border: none;
    }

    .dropdown--open ::slotted(.he-button[slot='trigger'][appearance='command']),
    .dropdown--open ::slotted(.he-button[slot='trigger'][appearance='stealth']) {
      outline: ${SystemColors.ButtonText} solid 3px;
      outline-offset: -3px;
    }
  }
`;
