import { css } from 'lit';

export default css`
  .he-theme-dark {
    color-scheme: dark;

    /**
     * Color primitives
     */

    --he-color-red-50: hsl(0 56% 23.9%);
    --he-color-red-100: hsl(0.6 60% 33.9%);
    --he-color-red-200: hsl(0.9 67.2% 37.1%);
    --he-color-red-300: hsl(1.1 71.3% 43.7%);
    --he-color-red-400: hsl(1 76% 52.5%);
    --he-color-red-500: hsl(0.7 89.6% 57.2%);
    --he-color-red-600: hsl(0 98.6% 67.9%);
    --he-color-red-700: hsl(0 100% 72.3%);
    --he-color-red-800: hsl(0 100% 85.6%);
    --he-color-red-900: hsl(0 100% 90.3%);
    --he-color-red-950: hsl(0 100% 95.9%);

    --he-color-orange-50: hsl(15 64.2% 23.3%);
    --he-color-orange-100: hsl(15.1 70.9% 31.1%);
    --he-color-orange-200: hsl(15.3 75.7% 35.5%);
    --he-color-orange-300: hsl(17.1 83.5% 42.7%);
    --he-color-orange-400: hsl(20.1 88% 50.8%);
    --he-color-orange-500: hsl(24.3 100% 50.5%);
    --he-color-orange-600: hsl(27.2 100% 57.7%);
    --he-color-orange-700: hsl(31.3 100% 68.7%);
    --he-color-orange-800: hsl(33.8 100% 79.3%);
    --he-color-orange-900: hsl(38.9 100% 87.7%);
    --he-color-orange-950: hsl(46.2 100% 95%);

    --he-color-yellow-50: hsl(32.5 60% 18.2%);
    --he-color-yellow-100: hsl(28.1 68.6% 29%);
    --he-color-yellow-200: hsl(31.3 75.8% 30.8%);
    --he-color-yellow-300: hsl(34.7 84.4% 35.3%);
    --he-color-yellow-400: hsl(40.1 87.3% 43.3%);
    --he-color-yellow-500: hsl(44.7 88% 46%);
    --he-color-yellow-600: hsl(47.7 100% 50.9%);
    --he-color-yellow-700: hsl(51.3 100% 59.9%);
    --he-color-yellow-800: hsl(54.6 100% 73%);
    --he-color-yellow-900: hsl(58.9 100% 84.2%);
    --he-color-yellow-950: hsl(60 100% 94%);

    --he-color-green-50: hsl(144.3 53.6% 16%);
    --he-color-green-100: hsl(143.2 55.4% 23.5%);
    --he-color-green-200: hsl(141.5 58.2% 26.3%);
    --he-color-green-300: hsl(140.8 64.2% 31.8%);
    --he-color-green-400: hsl(140.3 68% 39.2%);
    --he-color-green-500: hsl(141.1 64.9% 43%);
    --he-color-green-600: hsl(141.6 72.4% 55.2%);
    --he-color-green-700: hsl(141.7 82.7% 70.1%);
    --he-color-green-800: hsl(141 90.9% 82.1%);
    --he-color-green-900: hsl(142 100% 89.1%);
    --he-color-green-950: hsl(144 100% 95.5%);

    --he-color-teal-50: hsl(176.5 51.5% 15.4%);
    --he-color-teal-100: hsl(175.9 54.7% 22.3%);
    --he-color-teal-200: hsl(175.9 60.7% 23.9%);
    --he-color-teal-300: hsl(174.5 67.3% 28.8%);
    --he-color-teal-400: hsl(174.4 71.9% 34.9%);
    --he-color-teal-500: hsl(173.1 71% 38.3%);
    --he-color-teal-600: hsl(172.3 68.2% 48.1%);
    --he-color-teal-700: hsl(170.5 81.3% 61.5%);
    --he-color-teal-800: hsl(168.4 92.1% 75.2%);
    --he-color-teal-900: hsl(168.3 100% 86%);
    --he-color-teal-950: hsl(180 100% 95.5%);

    --he-color-blue-50: hsl(213 63.8% 18.4%);
    --he-color-blue-100: hsl(209.7 70.4% 26.5%);
    --he-color-blue-200: hsl(207.7 75.8% 30.8%);
    --he-color-blue-300: hsl(207.6 84.5% 36.1%);
    --he-color-blue-400: hsl(206 100% 41.6%);
    --he-color-blue-500: hsl(202.6 85.9% 52.7%);
    --he-color-blue-600: hsl(201.9 97.9% 62.2%);
    --he-color-blue-700: hsl(203.5 100% 75.5%);
    --he-color-blue-800: hsl(208.1 100% 87.5%);
    --he-color-blue-900: hsl(198.5 100% 94.9%);
    --he-color-blue-950: hsl(186 100% 98%);

    --he-color-magenta-50: hsl(297.1 51.2% 18.6%);
    --he-color-magenta-100: hsl(296.7 59.5% 31.5%);
    --he-color-magenta-200: hsl(295.4 65.4% 35.1%);
    --he-color-magenta-300: hsl(294.6 67.4% 42.2%);
    --he-color-magenta-400: hsl(293.3 68.7% 51.2%);
    --he-color-magenta-500: hsl(292.1 88.4% 57.7%);
    --he-color-magenta-600: hsl(292 98.5% 59.5%);
    --he-color-magenta-700: hsl(292.4 100% 79.5%);
    --he-color-magenta-800: hsl(292.9 100% 86.8%);
    --he-color-magenta-900: hsl(300 100% 91.5%);
    --he-color-magenta-950: hsl(300 100% 96.3%);

    --he-color-purple-50: hsl(276 54.3% 20.5%);
    --he-color-purple-100: hsl(273.6 61.8% 35.4%);
    --he-color-purple-200: hsl(272.9 64% 41.4%);
    --he-color-purple-300: hsl(271.9 68.1% 49.2%);
    --he-color-purple-400: hsl(271.5 85.1% 57.8%);
    --he-color-purple-500: hsl(270.7 96.4% 62.1%);
    --he-color-purple-600: hsl(270.5 100% 71.9%);
    --he-color-purple-700: hsl(270.9 100% 81.3%);
    --he-color-purple-800: hsl(272.4 100% 87.7%);
    --he-color-purple-900: hsl(276.7 100% 91.5%);
    --he-color-purple-950: hsl(300 100% 96.5%);

    --he-color-gray-50: hsl(30 3.2% 12.2%);
    --he-color-gray-100: hsl(30 2.8% 14.1%);
    --he-color-gray-150: hsl(30 2.5% 15.7%);
    --he-color-gray-200: hsl(30 2% 19.2%);
    --he-color-gray-250: hsl(30 1.7% 22.7%);
    --he-color-gray-300: hsl(30 2.9% 27.5%);
    --he-color-gray-350: hsl(30 2.1% 36.9%);
    --he-color-gray-400: hsl(30 1.7% 46.7%);
    --he-color-gray-450: hsl(30 1.7% 53.3%);
    --he-color-gray-500: hsl(30 1.9% 58.4%);
    --he-color-gray-550: hsl(30 2.1% 62.4%);
    --he-color-gray-600: hsl(30 3.8% 69%);
    --he-color-gray-650: hsl(30 4.4% 73.3%);
    --he-color-gray-700: hsl(30 3.5% 77.6%);
    --he-color-gray-750: hsl(30 4.3% 81.6%);
    --he-color-gray-800: hsl(30 6.2% 87.5%);
    --he-color-gray-850: hsl(30 10% 92.2%);
    --he-color-gray-900: hsl(30 7.7% 94.9%);
    --he-color-gray-950: hsl(30 16.7% 97.6%);

    --he-color-black: hsl(0 0% 0%);
    --he-color-white: hsl(255 100% 100%);

    /**
     * Theme colors
     */

    --he-color-primary-50: var(--he-color-blue-50);
    --he-color-primary-100: var(--he-color-blue-100);
    --he-color-primary-200: var(--he-color-blue-200);
    --he-color-primary-300: var(--he-color-blue-300);
    --he-color-primary-400: var(--he-color-blue-400);
    --he-color-primary-500: var(--he-color-blue-500);
    --he-color-primary-600: var(--he-color-blue-600);
    --he-color-primary-700: var(--he-color-blue-700);
    --he-color-primary-800: var(--he-color-blue-800);
    --he-color-primary-900: var(--he-color-blue-900);
    --he-color-primary-950: var(--he-color-blue-950);

    --he-color-success-50: var(--he-color-green-50);
    --he-color-success-100: var(--he-color-green-100);
    --he-color-success-200: var(--he-color-green-200);
    --he-color-success-300: var(--he-color-green-300);
    --he-color-success-400: var(--he-color-green-400);
    --he-color-success-500: var(--he-color-green-500);
    --he-color-success-600: var(--he-color-green-600);
    --he-color-success-700: var(--he-color-green-700);
    --he-color-success-800: var(--he-color-green-800);
    --he-color-success-900: var(--he-color-green-900);
    --he-color-success-950: var(--he-color-green-950);

    --he-color-warning-50: var(--he-color-yellow-50);
    --he-color-warning-100: var(--he-color-yellow-100);
    --he-color-warning-200: var(--he-color-yellow-200);
    --he-color-warning-300: var(--he-color-yellow-300);
    --he-color-warning-400: var(--he-color-yellow-400);
    --he-color-warning-500: var(--he-color-yellow-500);
    --he-color-warning-600: var(--he-color-yellow-600);
    --he-color-warning-700: var(--he-color-yellow-700);
    --he-color-warning-800: var(--he-color-yellow-800);
    --he-color-warning-900: var(--he-color-yellow-900);
    --he-color-warning-950: var(--he-color-yellow-950);

    --he-color-caution-50: hsl(16.2, 98.3%, 22.9%);
    --he-color-caution-100: hsl(16.5, 98.8%, 32.5%);
    --he-color-caution-200: hsl(16.2, 99.1%, 42.5%);
    --he-color-caution-300: hsl(16.1, 74.8%, 50.2%);
    --he-color-caution-400: hsl(16.4, 75.3%, 57.1%);
    --he-color-caution-500: hsl(15.9, 76.1%, 63.9%);
    --he-color-caution-600: hsl(15.9, 78.5%, 70.8%);
    --he-color-caution-700: hsl(15.6, 81.4%, 76.9%);
    --he-color-caution-800: hsl(16, 86.2%, 82.9%);
    --he-color-caution-900: hsl(15.6, 96.2%, 89.8%);
    --he-color-caution-950: hsl(15, 100%, 94.5%);

    --he-color-danger-50: var(--he-color-red-50);
    --he-color-danger-100: var(--he-color-red-100);
    --he-color-danger-200: var(--he-color-red-200);
    --he-color-danger-300: var(--he-color-red-300);
    --he-color-danger-400: var(--he-color-red-400);
    --he-color-danger-500: var(--he-color-red-500);
    --he-color-danger-600: var(--he-color-red-600);
    --he-color-danger-700: var(--he-color-red-700);
    --he-color-danger-800: var(--he-color-red-800);
    --he-color-danger-900: var(--he-color-red-900);
    --he-color-danger-950: var(--he-color-red-950);

    --he-color-neutral-50: var(--he-color-gray-50);
    --he-color-neutral-100: var(--he-color-gray-100);
    --he-color-neutral-150: var(--he-color-gray-150);
    --he-color-neutral-200: var(--he-color-gray-200);
    --he-color-neutral-250: var(--he-color-gray-250);
    --he-color-neutral-300: var(--he-color-gray-300);
    --he-color-neutral-350: var(--he-color-gray-350);
    --he-color-neutral-400: var(--he-color-gray-400);
    --he-color-neutral-450: var(--he-color-gray-450);
    --he-color-neutral-500: var(--he-color-gray-500);
    --he-color-neutral-550: var(--he-color-gray-550);
    --he-color-neutral-600: var(--he-color-gray-600);
    --he-color-neutral-650: var(--he-color-gray-650);
    --he-color-neutral-700: var(--he-color-gray-700);
    --he-color-neutral-750: var(--he-color-gray-750);
    --he-color-neutral-800: var(--he-color-gray-800);
    --he-color-neutral-850: var(--he-color-gray-850);
    --he-color-neutral-900: var(--he-color-gray-900);
    --he-color-neutral-950: var(--he-color-gray-950);

    --he-color-neutral-0: hsl(40, 6.4%, 9.2%);
    --he-color-neutral-1000: hsl(0, 0%, 100%);

    /**
     * Neutral palette
     */

    --he-neutral-foreground: var(--he-type-primary);

    --he-neutral-fill-rest: var(--he-color-neutral-200);
    --he-neutral-fill-hover: var(--he-color-neutral-300);
    --he-neutral-fill-active: var(--he-color-neutral-400);

    --he-neutral-fill-stealth-rest: var(--he-color-body-background);
    --he-neutral-fill-stealth-hover: var(--he-color-neutral-100);
    --he-neutral-fill-stealth-active: var(--he-color-neutral-150);

    /**
     * Accent palette
     */

    /* accent text colors */
    --he-accent-foreground-rest: var(--he-color-primary-600);
    --he-accent-foreground-hover: var(--he-color-primary-700);
    --he-accent-foreground-active: var(--he-color-primary-800);

    /* accent background colors */
    --he-accent-fill-rest: var(--he-color-primary-400);
    --he-accent-fill-hover: var(--he-color-primary-300);
    --he-accent-fill-active: var(--he-color-primary-200);

    /**
     * Body
     */

    --he-color-body-background: var(--he-color-neutral-0);

    /**
     * Surfaces
     */
    --he-color-surface-background: var(--he-color-neutral-200);
    --he-copilot-response-background: #2a2a2a;

    /**
     * Dividers, borders, tooltips
     */

    --he-color-body-divider: var(--he-color-neutral-300);
    --he-color-card-divider: var(--he-color-neutral-300);
    --he-color-card-active-border: var(--he-color-primary-700);

    /* This variable is specific only to the outer border of a card. */
    --he-card-border-style: solid;

    /**
     * Overlays
     */

    --he-overlay-background-color: rgb(0 0 0 / 60%);

    /**
     * Typography
     */

    --he-type-primary: var(--he-color-neutral-900);
    --he-type-secondary: var(--he-color-neutral-700);
    --he-type-disabled: var(--he-color-neutral-450);
    --he-type-disabled-on-surfaces: var(--he-color-neutral-350);
    --he-type-on-accent: var(--he-color-neutral-1000);
  }
`;
