import { css } from 'lit';

export default css`
  .form-control {
    display: flex;
    flex-direction: column;
  }

  /* Label */
  .form-control__label {
    display: none;
    order: 1;
    max-width: fit-content;
    color: var(--he-type-primary);
    cursor: pointer;
    font-size: var(--he-form-control-label-font-size);
    line-height: var(--he-line-height-normal);
    font-weight: var(--he-font-weight-semibold);
    margin-bottom: var(--he-spacing-2x-small);
    padding: 0;
  }

  .form-control--has-label .form-control__label {
    display: inline-block;
  }

  .form-control--medium .form-control__label {
    font-size: var(--he-form-control-font-size);
  }

  :host([required]) .form-control__star {
    margin-inline-start: var(--he-form-control-required-content-offset);
    color: var(--he-form-control-error-color);
  }

  /* Help text */
  .form-control__help-text {
    display: none;
    order: 2;
    color: var(--he-type-primary);
    font-size: var(--he-form-control-help-text-font-size);
    font-weight: var(--he-font-weight-normal);
    line-height: var(--he-line-height-normal);
    margin-bottom: var(--he-spacing-x-small);
  }

  .form-control--has-help-text .form-control__help-text {
    display: block;
  }

  .form-control__help-text ::slotted(*) {
    margin-top: var(--he-spacing-3x-small);
  }

  /* Input */
  .form-control-input {
    order: 3;
    font-size: var(--he-form-control-font-size);
    font-weight: var(--he-font-weight-normal);
    line-height: var(--he-line-height-normal);
  }

  /* Error text */
  .form-control__error-text {
    display: none;
    order: 4;
    color: var(--he-form-control-error-color);
    font-size: var(--he-form-control-help-text-font-size);
    font-weight: var(--he-font-weight-normal);
    line-height: var(--he-line-height-normal);
    margin-top: var(--he-spacing-2x-small);
  }

  .form-control--has-interaction.form-control--has-error .form-control__error-text {
    display: flex;
    align-items: center;
  }

  .form-control__error-text-icon {
    margin-inline-end: var(--he-spacing-2x-small);
    font-size: var(--he-icon-size);
  }

  /* Groups */
  fieldset.form-control--group {
    border: none;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  .form-control--group .form-control__label {
    cursor: default;
    margin-bottom: var(--he-spacing-small);
  }

  .form-control--group.form-control--has-label.form-control--has-help-text .form-control__label {
    margin-bottom: var(--he-spacing-2x-small);
  }

  .form-control--group .form-control__help-text {
    margin-bottom: var(--he-spacing-small);
  }

  .form-control--group .form-control__error-text {
    margin-top: var(--he-spacing-small);
  }

  /* Group items */
  .form-control--group-item__label {
    display: none;
    color: var(--he-form-control-color);
    margin-inline-start: 0.5em;
    user-select: none;
  }

  .form-control--has-label .form-control--group-item__label {
    display: block;
  }

  :host([required]) .form-control--group-item__label .form-control__star {
    margin-inline-start: var(--he-spacing-3x-small);
  }
`;
