import { property } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import HarmonyElement from '../../base-components/base.js';
import { HasSlotController } from '../../internal/slot.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import { titleStyles } from '../../internal/styles/title.styles.js';
import styles from './page-title.styles.js';

/**
 *
 * The Page Title is meant to provide the proper sizing and spacing for header text at the top of the page.
 *
 * @tag he-page-title
 * @since 1.5
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=86%3A19257&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @slot - Workspace title.
 * @slot active-title - Active page title.
 *
 * @csspart base - The <h1> component wrapping both slots.
 * @csspart workspace-title - Used to style the wrapper around the default slot.
 * @csspart active-title - Used to style the wrapper around the active-title slot.
 * @csspart divider - Used to style the line between the primary and secondary title.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('page-title')
export class PageTitle extends HarmonyElement {
  static styles = [componentStyles, styles, titleStyles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** @deprecated */
  @property({ attribute: 'active-title' }) activeTitle: Node[];

  private hasSlotController = new HasSlotController(this, '[default]', 'active-title');

  render() {
    return html`
      <h1 class="title" part="base">
        <span class="workspace-title" part="workspace-title">
          <slot></slot>
          <span class="divider" part="divider" ?hidden=${!this.hasSlotController.test('active-title')}>|</span>
        </span>
        <span
          class="title__normal active-title"
          part="active-title"
          ?hidden=${!this.hasSlotController.test('active-title')}
        >
          <slot name="active-title"></slot>
        </span>
      </h1>
    `;
  }
}

export default PageTitle;
