import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host([appearance='primary']) {
    --button-background-color: var(--he-accent-fill-rest);
    --button-background-color-hover: var(--he-accent-fill-hover);
    --button-background-color-active: var(--he-accent-fill-active);
    --button-border-color: transparent;
    --button-foreground-color: var(--he-type-on-accent);
    --divider-color: var(--he-color-neutral-0);
  }

  :host([appearance='primary']) .control:focus-visible {
    --he-focus-ring-color: var(--he-type-on-accent);
  }

  :host([appearance='stealth']) {
    --button-background-color: transparent;
    --button-border-color: transparent;
    --padding-x: var(--he-spacing-x-small);
    border-radius: 0;
  }

  :host([appearance='stealth']) .control:focus-visible {
    box-shadow: inset var(--he-focus-ring);
  }

  :host([appearance='link']) {
    --button-background-color: transparent;
    --button-background-color-hover: transparent;
    --button-background-color-active: transparent;
    --button-border-color: transparent;
    --button-foreground-color: var(--he-accent-foreground-rest);
    --button-foreground-color-hover: var(--he-accent-foreground-hover);
    --button-foreground-color-active: var(--he-accent-foreground-active);
    --icon-spacing: var(--he-spacing-2x-small);
    --padding-x: 0;
    /* don't stretch label area full width if button is wider - aligns end slot icon right after text */
    --content-column-width: auto;
    height: auto;
    min-width: 0;
    border-radius: 0;
    line-height: var(--button-line-height, inherit);
    text-align: inherit;
    vertical-align: bottom;
  }

  :host([appearance='link']) .control {
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    border: 0;
    white-space: normal;
    line-height: inherit;
    text-align: inherit;
  }

  :host([appearance='link']) .control:focus-visible {
    box-shadow: var(--he-focus-ring);
  }

  :host([appearance='link']) .icon-only.control:focus-visible {
    box-shadow: none;
  }

  :host([appearance='link']) .icon-only.control:focus-visible .content {
    outline: solid var(--he-focus-ring-width) var(--he-focus-ring-color);
    outline-offset: 1px;
  }

  :host([appearance='link']) .content {
    display: inline;
    text-decoration: underline;
  }

  :host([appearance='link']) .main-text::after {
    content: '';
  }

  :host([appearance='command']) {
    --button-background-color: transparent;
    --button-border-color: transparent;
    --padding-x: var(--he-spacing-x-small);
    border-radius: 0;
  }

  :host([appearance='command']) .control:focus-visible {
    box-shadow: inset var(--he-focus-ring);
  }

  :host([appearance='command']:not([disabled])) .start,
  :host([appearance='command']:not([disabled])) .icon-only,
  :host([appearance='command']:not([disabled]).he-icon-only) .control {
    color: var(--he-accent-foreground-rest);
  }

  :host {
    display: inline-flex;
    --button-background-color: var(--he-neutral-fill-stealth-rest);
    --button-background-color-hover: var(--he-neutral-fill-stealth-hover);
    --button-background-color-active: var(--he-neutral-fill-stealth-active);
    --button-border-color: var(--he-color-neutral-500);
    --button-foreground-color: var(--he-type-primary);
    --button-foreground-color-hover: var(--button-foreground-color);
    --button-foreground-color-active: var(--button-foreground-color);
    --divider-color: var(--he-color-neutral-300); /* used in split buttons */
    --icon-spacing: var(--he-spacing-x-small);
    --padding-x: var(--he-spacing-large);
    --padding-y: 0;
    --content-column-width: 1fr; /* the width of the main column with the text */
    --chevron-position: 0deg;
    outline: transparent;
    font-size: var(--button-font-size, var(--he-form-control-font-size));
    font-weight: var(--he-font-weight-normal);
    line-height: var(--button-line-height, var(--he-line-height-normal));
    height: var(--he-form-control-height);
    min-width: var(--he-form-control-height);
    background-color: var(--button-background-color);
    color: var(--button-foreground-color);
    border-radius: var(--he-border-radius-small);
    fill: currentColor;
    cursor: pointer;
    vertical-align: middle;
    align-items: center;
    text-align: center;
  }

  :host(:hover:not([disabled])) {
    background-color: var(--button-background-color-hover);
    color: var(--button-foreground-color-hover);
  }

  :host(:active:not([disabled])) {
    background-color: var(--button-background-color-active);
    color: var(--button-foreground-color-active);
  }

  :host([disabled]) {
    --button-border-color: transparent;
    --button-foreground-color: var(--he-type-disabled);
    cursor: not-allowed;
  }

  :host([appearance='primary'][disabled]),
  :host([appearance='secondary'][disabled]) {
    --button-background-color: var(--he-color-neutral-150);
  }

  :host([disabled]) .start,
  :host([disabled]) .end {
    color: var(--he-type-disabled);
  }

  .control {
    background: transparent;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    display: inline-grid;
    grid-template-columns:
      [padding-start] var(--padding-x) [start] auto [content] var(--content-column-width) [end] auto
      [internal-icons] auto [padding-end] var(--padding-x) [divider] auto;
    justify-content: center;
    align-items: center;
    padding: var(--padding-y) 0; /* padding-x is set as part of the grid so it won't be outside the divider */
    white-space: nowrap;
    outline: transparent;
    text-decoration: none;
    border: var(--he-form-control-border-width) solid var(--button-border-color);
    color: inherit;
    border-radius: inherit;
    fill: inherit;
    cursor: inherit;
    font-family: inherit;
    text-align: inherit;
  }

  .control,
  .end,
  .start {
    font: inherit;
  }

  /* remove padding on icon-only buttons, unless both 'external and 'caret' are on at the same time */
  :host(:not([external][caret])) .control.icon-only,
  :host(.he-icon-only:not([external][caret])) .control {
    --padding-x: 0;
    line-height: 0;
  }

  :host(:not([external][caret])) .control.icon-only .icon,
  :host(.he-icon-only:not([external][caret])) .icon {
    margin-inline-start: 0;
  }

  .control:focus-visible {
    box-shadow: inset 0 0 0 2px var(--button-background-color), inset 0 0 0 3px var(--he-focus-ring-color);
  }

  .control::-moz-focus-inner {
    border: 0;
  }

  .content {
    grid-column: content;
    grid-row: 1;
  }

  .start,
  .end {
    grid-row: 1;
    display: flex;
    align-items: center;
    gap: var(--icon-spacing);
    pointer-events: none;
  }

  .start {
    grid-column: start;
  }

  .end {
    grid-column: end;
  }

  .button--has-start .start,
  .control.icon-only .end,
  :host(.he-icon-only) .end {
    margin-inline-end: var(--icon-spacing);
  }

  .button--has-end .end,
  .control.icon-only .start,
  :host(.he-icon-only) .start {
    margin-inline-start: var(--icon-spacing);
  }

  ::slotted(svg) {
    width: var(--he-icon-size);
    height: var(--he-icon-size);
  }

  ::slotted(svg),
  ::slotted(.he-icon) {
    pointer-events: none;
  }

  .internal-icons {
    grid-column: internal-icons;
    grid-row: 1;
    display: inline-grid;
    align-items: center;
    justify-content: center;
  }

  .control.icon-only .internal-icons,
  :host(.he-icon-only) .internal-icons {
    grid-column: content;
  }

  :host([external][caret]) .control.icon-only .internal-icons,
  :host([external][caret].he-icon-only) .internal-icons {
    gap: var(--icon-spacing);
  }

  .internal-icons .icon {
    grid-row: 1;
    margin-inline-start: var(--icon-spacing);
  }

  .external-icon {
    margin-top: -2px;
    margin-inline-start: 1px;
  }

  .caret-icon {
    grid-column: 2;
    font-size: var(--he-chevron-size);
    transform: rotate(var(--chevron-position));
    transition: transform 0.2s ease;
  }

  /*
  * Button groups support a variety of button types (e.g. buttons with callouts, buttons as dropdown triggers, etc.).
  * This means buttons aren't always direct descendants of the button group, thus we can't target them with the
  * ::slotted selector. To work around this, the button group component does some magic to add these special classes to
  * buttons and we style them here instead.
  */
  :host(.he-button-group__button--first),
  :host(.he-button-group__button--inner) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  :host(.he-button-group__button--last),
  :host(.he-button-group__button--inner) {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  :host(.he-button-group__button--first) .control,
  :host(.he-button-group__button--inner) .control {
    border-inline-end-width: 0;
  }

  :host(.he-button-group__button--last) .control,
  :host(.he-button-group__button--inner) .control {
    border-inline-start-width: 0;
  }

  :host(.he-button-group__button) .control::after {
    width: 1px;
    height: calc(50% + (var(--he-form-control-border-width)));
    background: var(--divider-color);
    grid-column: divider;
  }

  :host(.he-button-group__button:not(.he-button-group__button--last)) .control::after {
    content: '';
  }

  :host([appearance='stealth'].he-button-group__button) .control:focus-visible {
    box-shadow: none;
    outline: solid var(--he-focus-ring-width) var(--he-focus-ring-color);
    outline-offset: -3px;
  }

  :host([appearance='link'].he-button-group__button) .control::after {
    display: none;
  }

  :host([appearance='link'].he-button-group__button:not(.he-button-group__button--last))::after {
    content: '';
    width: 1px;
    height: 80%;
    background: var(--divider-color);
    margin-inline-start: var(--he-spacing-x-small);
    margin-inline-end: var(--he-spacing-x-small);
  }

  :host([appearance='command'].he-button-group__button) .control:focus-visible {
    box-shadow: none;
    outline: solid var(--he-focus-ring-width) var(--he-focus-ring-color);
    outline-offset: -3px;
  }

  /* Compound buttons */

  :host([compound]) {
    height: auto;
    text-align: start;
  }

  :host([compound]:not([appearance='link'])) {
    --padding-x: var(--he-spacing-small);
    --padding-y: var(--he-spacing-x-small);
  }

  :host([compound]:not([appearance='link'])) .control {
    padding-top: calc(var(--padding-y) - 2px);
  }

  :host([compound]) .control {
    white-space: normal;
    align-items: start;
  }

  :host([compound]) .content {
    display: flex;
    flex-direction: column;
    font-weight: var(--he-font-weight-semibold);
  }

  .subtext {
    font-weight: var(--he-font-weight-normal);
    font-size: var(--he-font-size-small);
  }

  :host(:not([appearance='primary']):not([appearance='link'])) .subtext {
    color: var(--he-type-secondary);
  }

  :host([compound].he-button-group__button) .control::after {
    height: 100%;
  }

  :host([compound]) ::slotted(svg) {
    width: var(--he-font-size-2x-large);
    height: var(--he-font-size-2x-large);
  }

  :host([compound]) ::slotted([icon]) {
    font-size: var(--he-font-size-2x-large);
  }

  :host([compound]:not([appearance='command'])) ::slotted([icon]),
  :host([compound]:not([appearance='command'])) ::slotted(svg) {
    color: var(--he-color-neutral-550);
    margin-inline-start: -2px;
  }

  /** Nav header stuff */
  :host([appearance='stealth'].he-nav-header-action) {
    height: var(--nav-header-height);
    width: var(--nav-header-button-width);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    :host,
    :host(:hover:not([disabled])),
    :host(:active:not([disabled])) {
      forced-color-adjust: none;
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.ButtonText};
      --button-border-color: ${SystemColors.ButtonText};
      --divider-color: ${SystemColors.ButtonText};
    }

    :host([appearance='primary']),
    :host([appearance='primary']:hover:not([disabled])),
    :host([appearance='primary']:active:not([disabled])) {
      background-color: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
      --button-border-color: ${SystemColors.HighlightText};
      --divider-color: ${SystemColors.HighlightText};
    }

    :host([appearance='link']),
    :host([appearance='link']:hover:not([disabled])),
    :host([appearance='link']:active:not([disabled])) {
      background-color: ${SystemColors.Canvas};
      color: ${SystemColors.LinkText};
      --button-border-color: ${SystemColors.LinkText};
    }

    :host([disabled]),
    :host([disabled][appearance]) {
      opacity: 1;
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.GrayText};
      --button-border-color: ${SystemColors.GrayText};
    }

    :host([disabled][appearance='stealth']) {
      --button-border-color: transparent;
    }

    :host(:hover:not([disabled])) .control,
    :host(:active:not([disabled])) .control,
    .control:focus-visible,
    :host([appearance='stealth']) .control:focus-visible,
    :host([appearance='link']) .control:focus-visible,
    :host([appearance='command'].he-button-group__button) .control:focus-visible,
    :host([appearance='stealth'].he-button-group__button) .control:focus-visible {
      box-shadow: none;
      outline: ${SystemColors.ButtonText} solid 2px;
      outline-offset: -3px;
    }

    :host([appearance='link']:hover:not([disabled])) .control,
    :host([appearance='link']:active:not([disabled])) .control,
    :host([appearance='link']) .control:focus-visible {
      outline-offset: 0px;
    }

    .start,
    .end,
    .icon-only,
    :host(.he-icon-only) .control {
      color: inherit;
      fill: currentColor;
    }

    .subtext {
      color: inherit;
    }
  }
`;
