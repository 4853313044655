import { property } from 'lit/decorators.js';
import HarmonyElement from '../../base-components/base.js';
import { watch } from '../../internal/watch.js';
import componentStyles from '../../internal/styles/component.styles.js';
import { Component } from '../../utilities/decorators.js';
import styles from './divider.styles.js';

/**
 *
 * Divider is a horizontal or vertical rule that separates content.
 *
 * @tag he-divider
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=741%3A156035&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
@Component('divider')
export class Divider extends HarmonyElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** Set to true to render the divider as a presentational element instead of a separator.*/
  @property({ type: Boolean, reflect: true }) presentation = false;

  /** The divider's orientation. */
  @property({ reflect: true }) orientation: 'horizontal' | 'vertical' = 'horizontal';

  /** @internal watcher */
  @watch('orientation')
  @watch('presentation')
  syncAttributes() {
    if (this.presentation) {
      this.setAttribute('role', 'presentation');
      this.removeAttribute('aria-orientation');
    } else {
      this.setAttribute('role', 'separator');
      this.setAttribute('aria-orientation', this.orientation);
    }
  }
}

export default Divider;
