import { css } from 'lit';
import { SystemColors } from '../../utilities/system-colors.js';

export default css`
  :host {
    contain: content;
    position: relative;
    line-height: 0;
    --task-item-nested-width: 0;
    --task-item-height: var(--he-menu-control-height);
    --task-item-padding-x: var(--he-spacing-medium);
  }

  .positioning-region {
    position: relative;
    display: flex;
    min-height: var(--task-item-height);
    background-color: var(--task-item-positioning-region-bg-color);
  }

  .positioning-region--has-children {
    margin-top: var(--he-spacing-x-small);
  }

  .button {
    flex: 1;
    width: 100%;
    height: 100%;
    font-size: var(--he-font-size-medium);
    line-height: var(--he-line-height-medium);
    font-weight: var(--he-font-weight-normal);
    text-align: start;
    min-height: var(--task-item-height);
    --button-foreground-color: var(--he-type-primary);
    --button-foreground-color-hover: var(--he-accent-foreground-rest);
    --padding-x: var(--task-item-padding-x);
  }

  .button::part(control) {
    padding-inline-start: var(--task-item-nested-width);
    white-space: normal;
    min-height: var(--task-item-height);
  }

  :host([selected]) .button {
    --button-background-color: var(--he-neutral-fill-stealth-active);
    --button-background-color-hover: var(--he-neutral-fill-stealth-active);
  }

  :host([selected]) .positioning-region::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 4px;
    min-height: var(--task-item-height);
    height: 100%;
    background: var(--he-accent-foreground-rest);
  }

  :host([selected]) .positioning-region--ltr::after {
    left: 0;
  }

  :host([selected]) .positioning-region--rtl::after {
    right: 0;
  }

  .heading {
    display: inline-grid;
    grid-template-columns: [start] auto [content] 1fr [end] auto;
    align-items: center;
    width: calc(100% - (var(--task-item-padding-x) * 2));
    height: 100%;
    border-bottom: var(--he-form-control-border-width) solid var(--he-color-body-divider);
    margin-inline-start: var(--task-item-padding-x);
    margin-inline-end: var(--task-item-padding-x);
    cursor: auto;
    min-height: var(--task-item-height);
  }

  .heading .content {
    font-size: var(--he-font-size-small);
    line-height: var(--he-line-height-small);
    font-weight: var(--he-font-weight-semibold);
    grid-column: content;
    grid-row: 1;
  }

  .items {
    font-size: var(--he-spacing-medium);
    margin-bottom: var(--he-spacing-x-small);
  }

  .start,
  .end {
    grid-row: 1;
    display: flex;
    align-items: center;
    gap: var(--he-spacing-x-small);
    fill: currentColor;
  }

  .start {
    grid-column: start;
  }

  .end {
    grid-column: end;
  }

  .positioning-region--has-start .start {
    margin-inline-start: 1px;
    margin-inline-end: var(--he-spacing-x-small);
  }

  .positioning-region--has-end .end {
    margin-inline-start: var(--he-spacing-x-small);
    margin-inline-end: 1px;
  }

  ::slotted(svg) {
    width: var(--he-icon-size);
    height: var(--he-icon-size);
  }

  ::slotted(.he-task-item) {
    --task-item-nested-width: var(--he-spacing-medium);
  }

  @media screen and (forced-colors: active) {
    :host([selected]) .positioning-region::after {
      background: ${SystemColors.Highlight};
    }

    .start,
    .end {
      fill: ${SystemColors.ButtonText};
      color: ${SystemColors.ButtonText};
    }

    .heading {
      border-color: ${SystemColors.ButtonText};
    }
  }
`;
