import { property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';
import { watch } from '../../internal/watch.js';
import { HasSlotController } from '../../internal/slot.js';
import button from '../button/button.js';
import componentStyles from '../../internal/styles/component.styles.js';
import HarmonyFocusableElement from '../../base-components/focusable.js';
import { Component } from '../../utilities/decorators.js';
import styles from './task-item.styles.js';

import type { Button } from '../button/button.js';
import type { Target } from '../../types.js';
import type { TaskMenuItemType } from '../task-menu/task-menu.js';

export type { LooseString } from '../../types.js';

/**
 *
 * Task items are used to display a list of actions or links. They can be nested to create a hierarchy of actions.
 *
 * @tag he-task-item
 * @since 1.0
 * @status stable
 * @figma https://www.figma.com/file/UvgzWQM5R18Lrs4VHs2UPd/Partner-Center-extended-toolkit?type=design&node-id=199%3A22065&mode=design&t=FrLbCdXM439ktBGm-1
 *
 * @dependency he-button
 *
 * @slot - The visual label of the task item. Will be styled as a header if there are task item children.
 * @slot item - Named slot for child node(s). (Child he-task-item elements will be automatically placed in this slot.)
 * @slot start - Named slot to optionally include content (often a glyph) before the label.
 * @slot end - Named slot to optionally include content (often a glyph) after the label.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-selected-change - Emitted when an item has been selected.
 * @event he-blur - Emitted when the button loses focus.
 * @event he-focus - Emitted when the button gains focus.
 *
 * @csspart positioning-region - The container of the task item content and start/end slots used for positioning. Does not contain child task items.
 * @csspart items - The container for the item slot, contains child task items.
 * @csspart heading - The label of a task item styled as a header. Only present when task items are nested inside this item.
 * @csspart control - The <a> or <button> control element. Will be an anchor if href is present and not disabled, otherwise it will be a button.
 * @csspart content - The wrapper of the label text.
 * @csspart start - The start slot's container.
 * @csspart end - The end slot's container.
 *
 */
@Component('task-item', [button])
export class TaskItem extends HarmonyFocusableElement {
  static styles = [componentStyles, styles];
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeSelectedChange: new CustomEvent('he-selected-change'),
    onHeBlur: new CustomEvent('he-blur'),
    onHeFocus: new CustomEvent('he-focus'),
  };

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'item', 'start', 'end');

  @query('.button')
  private button: Button;

  /** The URL the anchor points to. All other properties (except disabled and selected) depend on this being set. */
  @property() href?: string;

  /** The browsing context for the href when clicked. */
  @property() target?: Target;

  /** When true, the control will appear selected.*/
  @property({ reflect: true, type: Boolean }) public selected: boolean = false;

  /** When true, the control will be immutable by user interaction. */
  @property({ reflect: true, type: Boolean }) public disabled: boolean = false;

  /** @internal set by task menu */
  @property({ attribute: false }) public itemType: TaskMenuItemType;

  @queryAssignedElements({ slot: 'item', selector: '.he-task-item', flatten: true })
  @state()
  private childTaskItems: TaskItem[] = [];

  connectedCallback(): void {
    super.connectedCallback();

    if (this.selected && this.childTaskItems?.length) {
      this.updateComplete.then(() => {
        this.selected = false;
      });
    }

    this.setAttribute('role', 'listitem');
    this.setSlotForNestedItem();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  private get isNestedItem() {
    return Boolean(this.parentElement?.closest('.he-task-item'));
  }

  private setSlotForNestedItem() {
    if (this.isNestedItem) {
      this.setAttribute('slot', 'item');
      return;
    }

    this.removeAttribute('slot');
  }

  private handleSelected(clickEvent: MouseEvent): void {
    if (this.childTaskItems?.length === 0 && !this.selected) {
      this.selected = true;

      const selectedChange = this.emit('he-selected-change', { cancelable: true });
      if (selectedChange.defaultPrevented) {
        this.selected = false;
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        return;
      }
    }
  }

  private setAriaCurrentAttribute() {
    if (!this.selected) {
      return undefined;
    } else {
      return !this.itemType || this.itemType === 'none' ? 'true' : this.itemType;
    }
  }

  /** @internal watcher */
  @watch('disabled')
  @watch('childTaskItems')
  handleDisabled() {
    this.disabled = this.disabled && (!this.childTaskItems || this.childTaskItems.length === 0);
    if (this.disabled) this.setAttribute('aria-disabled', 'true');
  }

  /** @internal watcher */
  @watch('selected')
  selectedChanged() {
    if (this.selected && this.childTaskItems?.length) {
      this.updateComplete.then(() => {
        this.selected = false;
      });
    }
  }

  /** Simulates a click on the button. */
  click() {
    this.button?.click();
  }

  protected handleBlur(event: CustomEvent) {
    event.stopPropagation();
    this.emit('he-blur', { bubbles: false });
  }

  protected handleFocus(event: CustomEvent) {
    event.stopPropagation();
    this.emit('he-focus', { bubbles: false });
  }

  private handleClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.handleSelected(event);
  }

  render() {
    this.setSlotForNestedItem();

    return html`
      <div
        part="positioning-region"
        class=${classMap({
          'positioning-region': true,
          [`positioning-region--${this.dir}`]: true,
          'positioning-region--has-children': this.childTaskItems?.length > 0,
          'positioning-region--has-content': this.hasSlotController.test('[default]'),
          'positioning-region--has-start': this.hasSlotController.test('start'),
          'positioning-region--has-end': this.hasSlotController.test('end'),
        })}
      >
        ${when(
          !this.childTaskItems?.length,
          () => html`
            <${this.scope.tag('button')}
              appearance="stealth"
              class="button"
              part="button"
              exportparts="
                button:button,
                control:control,
                content:content,
                start:start,
                end:end
              "
              current=${ifDefined(this.setAriaCurrentAttribute())}
              href=${ifDefined(this.href)}
              target=${ifDefined(this.target)}
              ?disabled=${this.disabled}
              @blur=${this.handleBlur}
              @focus=${this.handleFocus}
              @click=${this.handleClick}
            >
              <slot name="start" slot="start"></slot>
              <slot></slot>
              <slot name="end" slot="end"></slot>
            </${this.scope.tag('button')}>
          `,
          () => html`
            <div class="heading" part="heading">
              <span class="start" part="start">
                <slot name="start"></slot>
              </span>
              <span class="content" part="content">
                <slot></slot>
              </span>
              <span class="end" part="end">
                <slot name="end"></slot>
              </span>
            </div>
          `
        )}
      </div>
      <div ?hidden=${this.childTaskItems?.length <= 0} role="list" class="items" part="items">
        <slot name="item"></slot>
      </div>
    `;
  }
}

export default TaskItem;
